const header = {
    you: "Deg"
}

const profile = {
    createUser: "Opprett bruker",
    name: "Navn",
    lastName: "Etternavn",
    email: "E-post",
    phone: "Mobil",
    logout: "Logg ut"
}

const formErrors = {
    required: "Dette feltet er påkrevd",
    lengthReq: (num: number) => `Må bestå av minst ${num} tegn lange`,
    wrongLength: "Feil tegnmengde"
}


export default {
    header,
    profile,
    formErrors
}
