export const AvailableIcons = [
  "sun",
  "palm-tree",
  "chat-bubble",
  "lightbulb"
] as const;
export type Icon = typeof AvailableIcons[number];

export const Icons: Record<Icon,string> = {
  sun: "/assets/icons/sun.svg",
  "palm-tree": "/assets/icons/palm-tree.svg",
  "chat-bubble": "/assets/icons/chat-bubble.svg",
  lightbulb: "/assets/icons/lightbulb.svg"
}
