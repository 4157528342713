import { Article, ArticlePreview } from '../../models/article';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment";
import {Frontpage} from "../../models/pages/frontpage";
import {StrapiContent, StrapiData} from "../../models/strapi/StrapiContent";

@Injectable({
  providedIn: 'root',
})
export class CmsService {

    constructor(private http: HttpClient) {
    }

    getArticles(): Observable<Array<Article>> {
        return this.http.get<Array<Article>>(`${environment.cmsEndpoint}/api/articles`, {
            responseType: 'json',
            headers: {
                "Authorization": `Bearer ${environment.cmsApiKey}`
            }
        });
    }

    getArticlePreviews(): Observable<Array<ArticlePreview>> {
        const queryParameters = "fields=title&populate[0]=mainImage&populate[1]=author";
        return this.http.get<Array<ArticlePreview>>(`${environment.cmsEndpoint}/api/articles?${queryParameters}`, {
            responseType: 'json',
            headers: {
                "Authorization": `Bearer ${environment.cmsApiKey}`
            }
        });
    }

    getArticle(id: string): Observable<StrapiContent<Article>> {
        const queryParameters = "populate[0]=paragraphs&populate[paragraphs][populate][1]=image&populate[1]=mainImage&populate[mainImage]=*";
        return this.http.get<StrapiContent<Article>>(`${environment.cmsEndpoint}/api/articles/${id}?${queryParameters}`, {
            responseType: 'json',
            headers: {
                "Authorization": `Bearer ${environment.cmsApiKey}`
            }
        });
    }

    getFrontpage(): Observable<StrapiContent<Frontpage>> {
        const queryParameters = "populate[0]=featuredArticles&populate[featuredArticles][populate][1]=mainImage"; //Query string to also fetch featured articles
        return this.http.get<StrapiContent<Frontpage>>(`${environment.cmsEndpoint}/api/frontpage?${queryParameters}`, {
            headers: {
                "Authorization": `Bearer ${environment.cmsApiKey}`
            }
        });
    }
}
