
import { Injectable } from '@angular/core';
import {
    AbstractControl,
    ValidationErrors,
    Validators
} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
  
export class FormValidators {
    public required = Validators.required;

    public firstName = [
        Validators.required,
        Validators.minLength(2)
    ]

    public lastName = [
        Validators.required,
        Validators.minLength(2)
    ]

    public phone = [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12)
    ]

    public email = [
        Validators.required,
        Validators.email
    ]

     public birthDate = [
        Validators.required,
    ]

    public password = [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(48),
        passwordStrengthValidator
    ]

    public passwordConfirmation = [
        passwordConfirmationCheck
    ]
}

const passwordStrengthValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }

    const hasUpperCase = /[A-Z]+/.test(control.value);
    const hasLowerCase = /[a-z]+/.test(control.value);
    const hasNumeric = /[0-9]+/.test(control.value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
    return !passwordValid ? {passwordStrength: true} : null;
}

const passwordConfirmationCheck = (control: AbstractControl): ValidationErrors | null => {
    const password = control.parent?.get('password')?.value;
    const passwordConfirmation = control.parent?.get('passwordConfirmation')?.value;

    return password === passwordConfirmation ? null : {passwordMismatch: true}
}