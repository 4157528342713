import {createReducer, on} from '@ngrx/store';
import {Company} from 'src/app/models/company';
import {Article} from "../../../models/article";
import {loadArticleError, loadArticleSuccess} from "../actions/articles.actions";
import {StrapiContent, StrapiData} from "../../../models/strapi/StrapiContent";
import {DataFetchState} from "../../../models/fetch-state";

export type ArticleList = { [articleId: string]: StrapiContent<Article> };

export interface ArticlesState {
  articles: DataFetchState<ArticleList>;
}

export const initialState: ArticlesState = {
  articles: {
    data: undefined,
    isLoading: false,
    error: null
  }
};

export const articlesReducer = createReducer<ArticlesState>(initialState,
  on(loadArticleSuccess, (state, {article}) => ({
    ...state,
    articles: {
      ...state.articles,
      [article.data.id]: article
    }
  })),
  on(loadArticleError, (state, {error}) => ({
    ...state,
    articles: {
      ...state.articles,
      error: error
    }
  }))
);
