import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/common/store';
import { AuthState } from '../common/store/reducers/auth.reducers';
import {
  authError,
  authTokenReceived,
  logInSuccess,
  logOut,
} from '../common/store/actions/auth.actions';
import { AuthService } from '../services/auth/auth.service';
import { UserClaims } from '../models/userClaims';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade implements OnDestroy {
  authState$: Observable<AuthState>;
  userClaims$: Observable<UserClaims | null>;
  isLoggedIn$: Observable<boolean>;
  isLoggingIn$: Observable<boolean>;
  authError$: Observable<any>;
  subscriptions: Subscription;

  constructor(
    private _store: Store<AppState>,
    private _authService: AuthService
  ) {
    this.authState$ = this._store.select((x) => x.auth);
    this.isLoggedIn$ = this._store.select((x) => x.auth.isAuthenticated);
    this.isLoggingIn$ = this._store.select((x) => x.auth.isLoading);
    this.authError$ = this._store.select((x) => x.auth.error);
    this.userClaims$ = this._store.select((x) => x.auth.userClaims);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logIn() {
    this._authService.logIn();
  }
  async logOut() {
    await this._authService.logOut();
    this._store.dispatch(logOut());
  }

  initializeAuthStateChangeListener() {
    // Subscribe to auth events in order to update the store
    this.subscriptions = this._authService.authEvents$.subscribe((evt) => {
      if (evt.type === 'token_received') {
        // This is used to trigger an internal redirect from the login callback
        this._authService.tokenReceived.next();
        this.loadUserClaims();
      }
      if (evt.type === 'code_error' || evt.type === 'token_error') {
        console.error('%c[AuthFacade]: Auth error event', 'color:orange', evt);
        this._store.dispatch(authError({ error: evt }));
      } else if (
        evt.type === 'session_terminated' ||
        evt.type === 'session_error'
      ) {
        console.error(
          '%c[AuthFacade]: Session error event',
          'color:orange',
          evt
        );
        this._store.dispatch(authError({ error: evt }));
      } else {
        if (environment.envName === 'Development')
          console.debug('[AuthFacade]: Auth event', evt);
      }
    });
  }
  loadUserClaims() {
    this._store.dispatch(authTokenReceived());
  }
  tryRestoreLogin() {
    //This should log the user back in if the token is still valid
    //Currently this is not working
    //TODO: Debug how the login session is restored but the user is not logged in
    this._authService
      .restoreLoginStateFromStorage()
      .then((_) => {
        const isLoggedIn = this._authService.isTokenValid();
        if (isLoggedIn) {
          this._store.dispatch(authTokenReceived());
        } else {
          if (environment.envName === 'Development')
            console.debug('[AuthFacade]: Could not restore login state from storage');
        }
      })
      .catch((e) => {
        console.error('[AuthFacade]: Error during local session restore', e);
      });
  }

  //debug
  throwAuthError() {
    this._store.dispatch(authError({ error: 'Auth error' }));
  }
}
