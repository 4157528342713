import {Injectable} from '@angular/core';
import {
  Challenge,
  ChallengeCompleteResult,
  StrapiChallengeContent,
  StrapiChallengePreview,
} from '../../models/challenge';
import {Observable} from 'rxjs';
import {HttpService} from '../http/http.service';
import {environment} from 'src/environments/environment';
import {Guid} from 'src/app/models/types';
import {Playlist} from "../../models/playlist";

interface StrapiChallengeWithContent extends StrapiChallengePreview {
  content: StrapiChallengeContent;
}

@Injectable({
  providedIn: 'root',
})
export class ChallengesService {
  private endpoint = `${environment.coachApiUrl}`;

  constructor(private http: HttpService) {}

  completeChallenge(challengeResult: ChallengeCompleteResult) {
    return this.http.put<Challenge>(
      `${this.endpoint}/profiles/me/challenges/${challengeResult.challengeId}`,
      challengeResult,
      { withCredentials: true }
    );
  }

  startChallenge(challengeId: Guid): Observable<Challenge> {
    return this.http.post<Challenge>(
      `${this.endpoint}/profiles/me/challenges/${challengeId}`,
      null,
      { withCredentials: true }
    );
  }

  getChallengeProgressions(): Observable<Challenge[]> {
    return this.http
      .get<Challenge[]>(
        `${this.endpoint}/profiles/me/challenges/progressions`, //?active=true
        { withCredentials: true }
      );
  }

  getCurrentPlaylist(): Observable<Playlist> {
    return this.http.get<Playlist>(
      `${this.endpoint}/trainingprograms/me/playlist`,
      { withCredentials: true }
    );
  }
}
