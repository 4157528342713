import {Component, Input} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {ButtonDirective} from "../../directives/button.directive";
import {DcCardCompactActionsComponent} from "../../extended-components/dc-card-compact/ui/dc-card-compact-actions.component";
import {
	DcCardCompactComponent,
	DcCardCompactContentComponent,
	DcCardCompactHeaderComponent, DcCardCompactTitleComponent
} from "../../extended-components/dc-card-compact/dc-card-compact.component";
import {Article, ArticlePreview} from "../../models/article";
import {Router} from "@angular/router";
import {StrapiDataArray} from "../../models/strapi/StrapiContent";

@Component({
  selector: 'dc-articles-list',
  standalone: true,
	imports: [
		AsyncPipe,
		ButtonDirective,
		DcCardCompactActionsComponent,
		DcCardCompactComponent,
		DcCardCompactContentComponent,
		DcCardCompactHeaderComponent,
		DcCardCompactTitleComponent,
		NgForOf,
		NgIf
	],
  templateUrl: './articles-list.component.html',
  styleUrl: './articles-list.component.scss'
})
export class ArticlesListComponent {
	@Input()
    articles: StrapiDataArray<ArticlePreview>;

	constructor(
		private router: Router
	) {
	}
	articleClicked(id: string | number) {
		return this.router.navigate(['/', 'articles', id]);
	}


}
