import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileFacade } from '../facades/profile.facade';
import { AuthService } from '../services/auth/auth.service';
import {Subscription} from "rxjs";

@Component({
    template: ``,
    standalone: true,
})

export class LoginCallbackComponent implements  OnInit,OnDestroy{
    subscriptions: Subscription;
    constructor(private _profileFacade: ProfileFacade, private _auth: AuthService, private _route: Router) {}
    ngOnInit(): void {
        console.log("[LoginCallbackComponent] LoginCallback triggered");
        console.log({stateAfterLogin:this._route.routerState.snapshot})
       this.subscriptions = this._auth.tokenReceived.subscribe(() => {
            console.log("[LoginCallbackComponent] tokenreceived event triggered");
            this._profileFacade.isFinishingRegistrationRequired$.subscribe(goToSignUp => {
                console.log("[LoginCallbackComponent] isfinishingRegistraionRequired event triggered");
                const isLoggedIn = this._auth.isTokenValid();
                console.log(`[LoginCallbackComponent] gotosignup ${goToSignUp}, isLoggedIn ${isLoggedIn}`);
                if(isLoggedIn && goToSignUp){
                    this._route.navigate(["/signUp"])
                } else {
                    console.log("[LoginCallbackComponent] no signup, routing to front page");
                    this._route.navigate(["/"])
                }
            })
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
