<ng-container *ngIf="(profile$ | async)?.data as profile">
  <div class="nav bg-poseidon">
    <a
      class="nav-item"
      routerLink="/"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
    >
      <dc-icon-home class="nav-item-icon" title="Home"></dc-icon-home>
      <div class="nav-item-title">Forside</div>
    </a>
    <a class="nav-item" routerLink="playlist" routerLinkActive="active">
      <dc-icon-challenges
        class="nav-item-icon"
        title="Spilleliste"
      ></dc-icon-challenges>
      <div class="nav-item-title">Spilleliste</div>
    </a>
    <a aria-disabled="true" class="nav-item nav-item--disabled">
      <dc-icon-article class="opacity-30 nav-item-icon" title="Puls"></dc-icon-article>
      <div class="nav-item-title">Puls</div>
    </a>
    <a class="nav-item" routerLink="pulse" routerLinkActive="active">
      <dc-icon-user class="nav-item-icon" title="Profil"></dc-icon-user>
      <div class="nav-item-title">Profil</div>
    </a>
  </div>
</ng-container>
