import * as Highcharts from 'highcharts';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { BarChartSeries } from 'src/app/models/statistics';
import { getBarChartOptions } from 'src/app/utils/highcharts-config';
import { getExtendedBarChartOptions } from './bar-chart.const';
import { HighchartsChartModule } from 'highcharts-angular';

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [HighchartsChartModule],
})
export class BarChartComponent implements OnInit {
    chartConfig: Highcharts.Options;
    Highcharts = Highcharts;

    @Input()
    series: BarChartSeries | BarChartSeries[] | null;

    @Input()
    categories: string[] | null;

    @Input()
    title: string = '';

    @Input()
    subtitle: string;

    constructor() { }

    public ngOnInit(): void {
        const genericBarChartOptions = getBarChartOptions();
        const data = {
            series: this.series || ([] as any),
            categories: this.categories || [],
            title: this.title,
            subtitle: this.subtitle,
        };
        this.chartConfig = getExtendedBarChartOptions(
            genericBarChartOptions,
            data
        );
    }
}
