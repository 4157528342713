import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DatePipe, NgIf, NgOptimizedImage} from "@angular/common";
import {Icon, Icons} from "../../models/icon";
import {FullScreenOverlayComponent} from "../full-screen-overlay/full-screen-overlay.component";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'dc-audio-player',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  imports: [
    DatePipe,
    NgIf,
    NgOptimizedImage,
    FullScreenOverlayComponent
  ],
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss'
})
export class AudioPlayerComponent {
  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  @ViewChild('waveContainer') waveContainer: ElementRef;

  @Input() icon?: Icon = "sun";
  @Input() title: string;
  @Input() audioUrl: string;
  @Input() description: string;

  @Output() private playerClosed = new EventEmitter();
  @Output() private playbackStarted = new EventEmitter();
  @Output() private audioClipCompleted = new EventEmitter();

  duration: number = 0;
  currentTime: number = 0;
  skip = 15;
  maxTimeReached: number = 0;
  currentTimeElapsed = 0;
  barSeconds = 5;
  trackPosition = 0;

  constructor(private renderer: Renderer2) {}

  _closePlayer() {
    this.playerClosed.emit();
  }

  _getAudioUrl() {
    return `${environment.cmsEndpoint}${this.audioUrl}`;
  }


  waveClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target != null && target.classList.contains('bar')) {
      const barIndexAttribute = target.getAttribute('data-bar-index');
      if (barIndexAttribute) {
        const timeToSeek = parseInt(barIndexAttribute)*this.barSeconds;
        this.audioPlayerRef.nativeElement.currentTime = timeToSeek;
        this.currentTimeElapsed = timeToSeek;
        this.audioPlayerRef.nativeElement.play();
      }
    }
  }

  getAudioIconPath() {
    return Icons[this.icon ?? "sun"];
  }

  playAudio() {
    //If the clip is over, start from the beginning
    if (
      this.audioPlayerRef.nativeElement.currentTime ===
      this.audioPlayerRef.nativeElement.duration
    ) {
      this.audioPlayerRef.nativeElement.currentTime = 0;
    }
    this.audioPlayerRef.nativeElement.play();
    this.playbackStarted.emit();
  }

  pauseAudio() {
    this.audioPlayerRef.nativeElement.pause();
  }

  rewindAudio() {
    this.audioPlayerRef.nativeElement.currentTime -= this.skip;
  }

  forwardAudio() {
    const skipTime = this.audioPlayerRef.nativeElement.currentTime + this.skip;
    if(skipTime < this.audioPlayerRef.nativeElement.duration) {
      this.audioPlayerRef.nativeElement.currentTime = skipTime;
    } else {
      this.audioPlayerRef.nativeElement.currentTime = this.audioPlayerRef.nativeElement.duration;
    }
  }

  onAudioEnded() {
    this.audioClipCompleted.emit();
  }

  updateCurrentTime() {
    this.currentTime =
      this.audioPlayerRef.nativeElement.duration -
      this.audioPlayerRef.nativeElement.currentTime;
    this.currentTimeElapsed = this.audioPlayerRef.nativeElement.currentTime;
    this.maxTimeReached = Math.max(this.maxTimeReached, this.currentTime);

    const containerOffsetWidth = this.waveContainer.nativeElement.offsetWidth;
    const bars = document.querySelectorAll('.bar');
    const currentBar = this.currentTimeElapsed / this.barSeconds;
    const currentBarElement = bars[Math.floor(currentBar)] as HTMLElement;
    Array.from(bars).forEach((bar: Element, index: number) => {
      const barIndexAttribute = bar.getAttribute('data-bar-index');
      if (barIndexAttribute !== null){
        const barIndex = parseInt(barIndexAttribute);
        this.renderer.removeClass(bar, 'current-bar');
        if (barIndex <= currentBar && currentBar < barIndex + 1) {
          this.renderer.addClass(bar, 'current-bar');
        }

/*        // Manage visibility of bars
        if (time <= this.maxTimeReached) {
          this.renderer.setStyle(bar, 'visibility', 'visible');
        } else {
          // Optionally hide bars that are beyond the current time, not working atm
          this.renderer.setStyle(bar, 'visibility', 'hidden');
        }*/
      }
    });
    this.trackPosition = (containerOffsetWidth/2)-currentBarElement.offsetLeft-currentBarElement.offsetWidth/2;
  }

  initializePlayerUi() {
    this.duration = this.audioPlayerRef.nativeElement.duration;
    this.currentTime =
      this.duration - this.audioPlayerRef.nativeElement.currentTime;

    // Create bars dynamically based on audio duration
    const barCount = Math.floor(this.duration) / this.barSeconds;
    for (let i = 0; i < barCount; i++) {
      let bar = document.createElement('div');
      const randomHeight = Math.random() * 100 + 10;
      this.renderer.setStyle(bar, 'height', `${randomHeight}px`);
      bar.classList.add('bar');
      this.renderer.setAttribute(bar, 'data-bar-index', i.toString());
      this.renderer.appendChild(this.waveContainer.nativeElement, bar);

      if (i === 0) {
        bar.classList.add('current-bar');
      }
    }
    const containerOffsetWidth = this.waveContainer.nativeElement.offsetWidth;
    const currentBarElement = document.getElementsByClassName('current-bar')[0] as HTMLElement;
    this.trackPosition = containerOffsetWidth/2-currentBarElement.offsetWidth/2;
  }

  resetAudio() {
    this.audioPlayerRef.nativeElement.currentTime = 0;
  }
}
