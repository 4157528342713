import {AuthFacade} from "../../facades/auth.facade";

import {Component} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Router} from "@angular/router";
import {FormValidators} from 'src/app/utils/form.validator';
import { CompanyFacade } from 'src/app/facades/company.facade';
import { Observable } from 'rxjs';
import { Company } from 'src/app/models/company';
import { ProfileFacade } from 'src/app/facades/profile.facade';
import translations from 'src/assets/translations';
import { DepartamentsSelectionComponent } from './ui/departments-selection/departments-selection.component';
import { DcFormInputComponent } from '../../extended-components/form/dc-form-input/dc-form-input.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe } from '@angular/common';
import { ContainerComponent } from '../../components/container/container.component';
import {ButtonDirective} from "../../directives/button.directive";
import {DataFetchState} from "../../models/fetch-state";
@Component({
    selector: 'dc-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: true,
    imports: [ContainerComponent, FormsModule, ReactiveFormsModule, NgIf, MatCardModule, DcFormInputComponent, DepartamentsSelectionComponent, AsyncPipe, ButtonDirective]
})

export class SignupComponent{
    company$: Observable<DataFetchState<Company>>;
    signupForm: UntypedFormGroup;
    translations = translations.profile;

    isFormValid() {
        return this.signupForm.valid;
    }

    check(){
        console.log(this.signupForm.valid, this.signupForm);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private _companyFacade: CompanyFacade,
        private _profileFacade: ProfileFacade,
        private _authFacade: AuthFacade,
        private router: Router,
        private validators: FormValidators
    ) {
        this.company$ = this._companyFacade.company$;

        this.signupForm = this.fb.group({
            firstName: ['', this.validators.firstName],
            lastName: ['', this.validators.lastName],
            /*
                        phone: ['', this.validators.phone],
                        birthDate: ['', this.validators.birthDate],
            */
            department: ['', this.validators.required]
        })
    }

    signUp() {
        if (this.signupForm.valid) {
            this._profileFacade.createProfile({
                phone: this.phone?.value || "",
                givenName: this.firstName?.value || "",
                familyName: this.lastName?.value || "",
                departmentId: this.departmentId?.value || "",
                birthDate: this.birthDate?.value || new Date(),
            });

            this._profileFacade.profile$.subscribe(profile => {
                if(profile !== null)
                    this.router.navigate(['']);
            })
        } else {
            console.warn("The form is not valid");
        }
    }

    logOut(){
        this._authFacade.logOut();
    }

    get firstName() {
        return this.signupForm.get('firstName');
    }

    getFirstNameError(){
        return  this.firstName?.hasError('minLength') ? translations.formErrors.lengthReq(2)
            : translations.formErrors.required;
    }

    get lastName() {
        return this.signupForm.get('lastName');
    }

    getLastNameError() {
        return this.lastName?.hasError('minLength') ? translations.formErrors.lengthReq(2)
            : translations.formErrors.required;
    }

    get phone() {
        return this.signupForm.get('phone');
    }

    getPhoneError() {
        return this.lastName?.hasError('minLength') ? translations.formErrors.wrongLength
            : translations.formErrors.required
    }

    get departmentId() {
        return this.signupForm.get('department');
    }

    get birthDate() {
        return this.signupForm.get('birthDate');
    }
}
