export const HighchartsGaugeConfig = (
    height?: string | number,
    width?: string | number
) =>
    ({
        exporting: { enabled: false },
        credits: { enabled: false },
        /*  plotOptions: {
series: {
enableMouseTracking: false,
events: {
legendItemClick: (e:any) => {e.preventDefault();return false;}
},
label: {
style: {
color: '#00ff00'
}
}
}
},*/
        caption: {
            style: {
                color: '#00ff00',
            },
        },
        chart: {
            type: 'solidgauge',
            backgroundColor: 'transparent',
            height: 100 + '%', // 100% height,
            width: width ? width : null,
            margin: 0,
            reflow: true,
        },
        /*  chart: {
polar: false,
type: 'solidgauge',
backgroundColor: 'transparent',
className: 'chart-container',
style: {
color: '#00ff00',
},
events: {

}
},*/
        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [
                {
                    // Track for Move
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                } /*, { // Track for Exercise
            outerRadius: '87%',
            innerRadius: '63%',
            backgroundColor: 'rgba(242,230,64,0.3)',
            borderWidth: 0
        }*/ /*, { // Track for Stand
            outerRadius: '62%',
            innerRadius: '38%',
            backgroundColor: 'rgba(240,93,120,0.3)',
            borderWidth: 0
        }*/,
            ],
        },
        legend: {
            enabled: false,
            className: 'bluejay-legend',
            itemStyle: {
                color: '#FFFFFF',
            },
            itemHiddenStyle: {
                color: '#333333',
            },
            itemHoverStyle: {
                color: '#333333',
            },
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    color: '#FFF',
                    formatter: function () {
                        return `<div class="prc" style="text-align:center;"><span style="font-size:30px;' +
                        'black' + '">${this.series.data[0].total || 0}%</span>`; //first data in list is for total fraction
                    },
                    zIndex: 3,
                    inside: true,
                    borderWidth: 0, //needed to remove the bounding box around the inner value
                },
                linecap: 'round',
                label: {
                    onArea: true,
                },
                showInLegend: true,
            },
        },

        /*  yAxis: {
gridLineInterpolation: 'polygon',
lineWidth: 0,
min: 0,
lineColor: '#FFFFFF'
},*/
        /*  xAxis: {
categories: [
'Vulnerability',
'Courage in relations',
'Self insight',
],
tickmarkPlacement: 'on',
lineWidth: 0,
},*/
        title: {
            style: {
                color: '#FFFFFF',
            },
        },
        labels: {
            style: {
                color: '#FFFFFF',
            },
        },
        tooltip: {
            style: {
                color: '#FFFFFF',
            },
        },
        /*  global: {
events: {
legendItemClick: (e:any) => {e.preventDefault();return false;}
}
}*/
    } as Highcharts.Options);

export const getBarChartOptions = (): Highcharts.Options => {
    return {
        chart: {
            type: 'column',
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        title: {
            text: '',
            align: 'left',
        },
        subtitle: {
            text: '',
            align: 'left',
        },
        xAxis: {
            categories: [],
            crosshair: true,
            accessibility: {
                description: '',
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
            },
            maxPadding: 0,
        },
        tooltip: {
            valueSuffix: '',
            borderColor: '#697b7a',
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },
        exporting: {
            enabled: false,
        },
        series: [],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    } as Highcharts.Options;
};
