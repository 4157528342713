import { ActivatedRoute, Params, Router } from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, forkJoin, Subscription} from 'rxjs';

import { Article } from 'src/app/models/article';
import { ArticleList } from '../../common/store/reducers/articles.reducers';
import { ArticlesFacade } from '../../facades/articles.facade';
import { CmsService } from 'src/app/services/cms/cms.service';
import { Frontpage } from '../../models/pages/frontpage';
import { StrapiContent } from '../../models/strapi/StrapiContent';
import { PopulateList } from 'src/app/utils/animation';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-article',
    animations: [PopulateList(undefined, null)],
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
    ],
})
export class ArticleComponent implements OnInit, OnDestroy {
    subscriptions: Subscription;
    id: Observable<string | null>;
    article: Observable<StrapiContent<Article> | null>;
    articleImageHeight = '28vw';

    error$: Observable<any>;

    constructor(
        private articlesFacade: ArticlesFacade,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.params['id'];
        this.subscriptions = this.articlesFacade.articles$.subscribe((articleList) => {
            if (id && articleList.data) {
                const article = articleList.data[id];
                if (article) {
                    this.article =
                        new Observable<StrapiContent<Article> | null>(
                            (subscriber) => {
                                subscriber.next(article);
                            }
                        );
                } else {
                    console.error(`Could not find article for id ${id}`);
                    this.error$ = new Observable<any>((subscriber) => {
                        subscriber.next(`Could not find article for id ${id}`);
                    });
                }
            } else {
                console.log(`No article loaded for id ${id}`);
            }
        });
        this.articlesFacade.getArticle(id);
    }
}
