import {booleanAttribute, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {Observable} from "rxjs";

export type MessageType = "info" | "warning" | "error" | "success";

type MessageBoxChanges = SimpleChanges & {
    open: boolean;
}

@Component({
    selector: 'dc-message-box',
    standalone: true,
    imports: [
        NgClass,
        NgIf
    ],
    templateUrl: './message-box.component.html',
    styleUrl: './message-box.component.scss'
})
export class MessageBoxComponent {
    @Input()
    open: boolean = true;

    @Input()
    type: MessageType = "error";

    @Input()
    messageHeadline: string = "";

    constructor() {
    }

    closeDialog() {
        this.open = false;
    }
}
