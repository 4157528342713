import { Component } from '@angular/core';
import {CompanyFacade} from "../../facades/company.facade";
import {Observable} from "rxjs";
import {Company, CompanyExtendedInfo} from "../../models/company";
import {Employee} from "../../models/employee";
import { DcCardCompactContentComponent } from '../../extended-components/dc-card-compact/ui/dc-card-compact-content.component';
import { DcCardCompactHeaderComponent } from '../../extended-components/dc-card-compact/ui/dc-card-compact-header.component';
import { DcCardCompactComponent } from '../../extended-components/dc-card-compact/dc-card-compact.component';
import { ProgressIndicatorComponent } from '../../components/progress-indicator/progress-indicator.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {DataFetchState} from "../../models/fetch-state";

@Component({
    selector: 'dc-company-administration',
    templateUrl: './company-administration.component.html',
    styleUrls: ['./company-administration.component.scss'],
    standalone: true,
    imports: [NgIf, ProgressIndicatorComponent, DcCardCompactComponent, DcCardCompactHeaderComponent, DcCardCompactContentComponent, NgFor, AsyncPipe]
})
export class CompanyAdministrationComponent {
    companyInformation$: Observable<DataFetchState<CompanyExtendedInfo>>;
    companyUsers$: Observable<DataFetchState<Array<Employee>>>;

    constructor(private _companyFacade: CompanyFacade) {
        this.companyInformation$ = this._companyFacade.companyInformation$;
    }
}
