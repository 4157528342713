import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    template: 'Redirecting...',
    standalone: true,
})

export class RedirectComponent implements OnInit {
	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		const externalUrl = this.route.snapshot.data["externalUrl"];
		if(!externalUrl) throw new Error("You must provide route.data.externalUrl value to Redirect component")
        console.log("Redirecting to external url");
		window.location.href = externalUrl;
	}
}
