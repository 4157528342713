import {catchError, finalize, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {environment} from 'src/environments/environment';
import {throwError} from "rxjs";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    // extend server response observable with logging
    return (environment.production || !environment.logHttpRequests)
      ? next.handle(req)
      : next.handle(req)
        .pipe(
          tap({
            // Succeeds when there is a response; ignore other events
            next: (event) => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
            // Operation failed; error is an HttpErrorResponse
            error: (error) => {
              console.error('LoggingInterceptor:', error);
              return (ok = 'failed');
            }
          }),
          // Log when response observable either completes or errors
          finalize(() => {
            const elapsed = Date.now() - started;
            const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
            console.log(msg);
          })
        );
  }
}
