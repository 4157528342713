<!-- bytt til ngSwitch når funker, må takle checkbox, dropdown, textfield OG mix av desse -->
<!-- name="{{ 'q' + questionData.id + 'c' + choice.id }}" -->
<div class="uppercase px-2 py-3">
    Påstand <span>{{progress}}</span>
</div>
<dc-simple-card class="px-6 py-8">
    <p class="text-2xl text-hades py-5  px-4 text-center m-auto max-w-sm min-h-[10vh]">«{{resultMessage || question.text}}»</p>
    <div *ngIf="!resultMessage" class="answers" [ngSwitch]="question.type">
        <ng-container *ngSwitchCase="questionTypes.multipleChoice" [ngTemplateOutlet]="multipleChoice"></ng-container>
        <ng-container *ngSwitchCase="questionTypes.yesNo" [ngTemplateOutlet]="yesNo"></ng-container>
        <ng-container *ngSwitchCase="questionTypes.shortText" [ngTemplateOutlet]="shortText"></ng-container>
        <ng-container *ngSwitchCase="questionTypes.longText" [ngTemplateOutlet]="longText"></ng-container>
    </div>
    <div *ngIf="!resultMessage" class="flex justify-center py-3">
        <button class="uppercase rounded-2xl px-10" [disabled]="!answer.answerId" dcButton (click)="onNextQuestion()">Neste</button>
    </div>
</dc-simple-card>

<ng-template #multipleChoice>
    <div class="text-hades flex content-center gap-4 py-2 m-auto max-w-sm" [@listAnimation]="question.availableAnswers.length" *ngFor="let option of question.availableAnswers">
        <input name="choice" [id]="option.id" type="radio" [attr.aria-label]="option.text"
            class="h-6 w-6 checked:bg-demeter active:bg-demeter2 focus:bg-demeter2 focus:border-none focus:outline-none bg-hercules border-none"
            (click)="onOptionSelect(option.id)"
            [defaultChecked]="false"
            [attr.checked]="option.id === answer.answerId" />
        <label [attr.for]="option.id">
            {{option.text}}
        </label>
    </div>
</ng-template>

<ng-template #yesNo>
    <ng-container aria-label="questionchoice">
        <button dcButton  (click)="onYesNo(true)" class="option" [attr.selected]="answer.boolAnswer === true"> Yes </button>
        <button dcButton  (click)="onYesNo(false)" class="option" [attr.selected]="answer.boolAnswer === false"> No</button>
    </ng-container>
</ng-template>

<ng-template #shortText>
    <mat-form-field>
        <input matInput (input)="onInputChange()" placeholder="Skriv inn svaret ditt her..." [(ngModel)]="answer.textAnswer"/>
    </mat-form-field>
</ng-template>

<ng-template #longText>
    <mat-form-field class="question__long-answer">
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="6" placeholder="Skriv inn svaret ditt her..." (input)="onInputChange()" [(ngModel)]="answer.textAnswer"></textarea>
    </mat-form-field>
</ng-template>
