import { Injectable} from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if(!req.withCredentials)
            return next.handle(req);
        const authReq = req.clone({
            headers: req.headers.set('Authorization', this.authService.getAuthorizationHeader())
        });
        return next.handle(authReq);
    }
}
