<dc-full-screen-overlay class="audio-player" (closeWindow)="_closePlayer()">
  <ng-container>
    <img
      [ngSrc]="getAudioIconPath()"
      alt="audio icon"
      width="143"
      height="143"
      class="mb-10"
    />
    <h3 class="text-apollo mb-2">{{ title }}</h3>
    <p class="px-14 text-center">{{ description }}</p>

    <p class="audio-player__play-time">
      {{ currentTimeElapsed * 1000 | date : "mm:ss" }} /
      {{ duration * 1000 | date : "mm:ss" }}
    </p>

    <div
      class="audio-player__wave-container"
      #waveContainer
      (click)="waveClick($event)"
      [style.left.px]="trackPosition"
    >
      <!-- Bars are created here with a loop in JS -->
    </div>

    <div class="audio-player__control">
      <button class="audio-player__control__button" (click)="rewindAudio()">
        <img
          height="50"
          width="50"
          src="assets/icons/rewind15.svg"
          alt="Spol tilake 15 sekunder"
        />
      </button>
      <button
        class="audio-player__control__button"
        *ngIf="audioOption.paused"
        (click)="playAudio()"
      >
        <img height="37" width="43" src="assets/icons/play.svg" alt="Spill av" />
      </button>
      <button
        class="audio-player__control__button"
        *ngIf="!audioOption.paused"
        (click)="pauseAudio()"
      >
        <img height="29" width="22" src="assets/icons/pause.svg" alt="Pause" />
      </button>
      <button class="audio-player__control__button" (click)="forwardAudio()">
        <img
          height="50"
          width="50"
          src="assets/icons/forward15.svg"
          alt="Spol frem 15 sekunder"
        />
      </button>
    </div>
    <audio
      [src]="_getAudioUrl()"
      (timeupdate)="updateCurrentTime()"
      (loadeddata)="initializePlayerUi()"
      (ended)="onAudioEnded()"
      #audioOption
    ></audio>
  </ng-container>
</dc-full-screen-overlay>
