import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'dc-full-screen-overlay',
  templateUrl: './full-screen-overlay.component.html',
  styleUrls: ['./full-screen-overlay.component.scss'],
  standalone: true,
  imports: [ContainerComponent, CommonModule],
})
export class FullScreenOverlayComponent {
  @Input() hideCloseIcon = false;
  /** The function to be called when closing the overlay */
  @Output() private closeWindow = new EventEmitter<void>();

  onClose() {
    this.closeWindow.emit();
  }
}
