import { Component } from '@angular/core';

@Component({
  selector: 'dc-card-compact-title',
  template: '<ng-content></ng-content>',
  styles: [
    `
      @import 'variables';

      :host {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: $color-hades;
        line-height: 1;
      }
    `,
  ],
  standalone: true,
})
export class DcCardCompactTitleComponent {}
