<div>
  <ng-container *ngIf="(challenges$ | async) as challenges">
    <dc-spinner *ngIf="challenges.isLoading" ></dc-spinner>
    <div @fade>
      <ng-container *ngIf="challenges.data as challenges">
        <ng-container *ngIf="challenges?.length; else noChallenges">
          <dc-challenge-card
            *ngFor="let ch of challenges; index as i"
            [challenge]="ch"
            [index]="i"
            (click)="onChallengeClick(ch)"
          ></dc-challenge-card>
        </ng-container>
        <ng-template #noChallenges>
          <!--<p>-&#45;&#45;</p>-->
        </ng-template>
      </ng-container>
      <dc-challenge *ngIf="activeChallenge"
        [challenge]="activeChallenge"
        (closeChallenge)="onChallengeClosed()"
      ></dc-challenge>
    </div>
  </ng-container>
</div>
