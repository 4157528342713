import {Injectable} from '@angular/core';
import {SwPush} from '@angular/service-worker';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {HttpService} from '../http/http.service';
import {SettingsFacade} from "../../facades/settings.facade";
import {Observable} from "rxjs";
import {AuthService} from "../auth/auth.service";

@Injectable({
    providedIn: 'root'
})


export class PushNotificationsService {
    private showNotification = false;
    private _endpoint = `${environment.coachApiUrl}`;

    constructor(private authService: AuthService,private http: HttpService, private swPush: SwPush, private _settingsFacade: SettingsFacade) {
        // Browser on iOS does not provide "Notification" global variable unless application added to Home screen.
        const isPushSupported = ("Notification" in window) && this.swPush.isEnabled;
        const isLoggedIn = this.authService.isTokenValid();
        if (this.showNotification) {
            console.log("---------------SW PUSH:", this.swPush)
            console.log("---------------NOTIFICATION:", Notification.permission)
        }
    }

    private _getServerPublicKey() {
        return this.http.get<({
            vapidPublicKey: string
        })>(`${this._endpoint}/notifications/push/config`, {withCredentials: true})
            .pipe(map(({vapidPublicKey}) => vapidPublicKey))
    }

    private _saveSubscription(subscription: PushSubscription) {
        console.log("--------------- SAVING SUBSCRIPTION", subscription)
        return this.http.post<void>(`${this._endpoint}/notifications/push/subscriptions`, subscription, {withCredentials: true})
            .subscribe(
                () => {
                    console.log("Subscribed successfully");
                    //We don't want to prompt the user for push notifications when they already subscribed
                    this._settingsFacade.setPushSettings({
                        show: false,
                        timeOfLastPrompt: new Date()
                    });
                },
                error => console.error("Error saving subscription in backend",error)
            )
    }

    private _deleteSubscription(subscription: PushSubscription) {
        return this.http.delete(`${this._endpoint}/notifications/push/subscriptions/${subscription.endpoint}`,{withCredentials: true})
            .subscribe(
                () => {
                    console.log("Unsubscribed successfully");
                },
                error => console.error("Error removing subscription from backend",error)
            )
    }
    sendDebugPushMessage() {
        this.http.post<void>(`${this._endpoint}/notifications/debug`, {
            title: "Debug push message",
            content: "This is a debug push message",
            DestinationId: "b1f0643b-e029-4cba-a1f5-38ca89fb9582"
        }, {withCredentials: true})
            .subscribe(
                () => {
                    console.log("Debug push message sent successfully");
                },
                error => console.error("[PushService]: Error sending debug push message ",error)
            )
    }

    isNotificationAvailable() {
        return this.showNotification;
    }

    isNotificationsEnabled() {
        return Notification.permission === "granted";
    }

    subscribe() {
        if (!this.showNotification) {
            console.warn("Service worker is not supported in this browser => push notifications will not be enabled.")
            return;
        }
        console.debug("Subscribing to push notifications");
        this.swPush.subscription.subscribe(existingSubscription => {
            if(existingSubscription != null) {
                console.debug("Subscription already exists, nothing to do");
                return;
            }
            this._getServerPublicKey().subscribe(
                publicKey => {
                    console.debug("Server public key retrieved", publicKey);
                    this.swPush.requestSubscription({
                        serverPublicKey: publicKey
                    })
                        .then(subscription => {
                            console.debug("Subscription successful", subscription);
                            this._saveSubscription(subscription);
                            this.swPush.notificationClicks.subscribe(
                                (notification) => {
                                    console.log("Clicked on notification:", notification)
                                });
                        })
                        .catch(error => {
                            console.error("Notifications returned an error ",error)
                        })
                        .finally(() => {
                            console.debug("Subscription completed");
                        });
                },
                error => {
                    console.error(error)
                }
            );
        });
    };

    unsubscribe() {
        console.debug("Unsubscribing from push notifications");
        this.swPush.subscription.toPromise()
            .then(subscription => {
                if(subscription != null) {
                    console.debug("Found subscription, unsubscribing");
                    this.swPush.unsubscribe().then(() => {
                        console.log("Unsubscribed successfully.")
                        this._deleteSubscription(subscription);
                    }).catch(error => {
                        console.error("Error while unsubscribing",error);
                    });
                } else {
                    console.debug("No subscription found, nothing to unsubscribe from");
                }
            })
            .catch(error => {
                console.error("Error while getting subscription",error);
            })
            .finally(() => {
                console.debug("Unsubscribing completed");
            });
    }
}
