import { Component } from "@angular/core";

@Component({
    selector: 'dc-card-compact-actions',
    template: '<ng-content></ng-content>',
    styles: [`
        :host {
            width: 100%;
            padding-top: 10px;
        }
    `],
    standalone: true
})
export class DcCardCompactActionsComponent { }
