import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {TrainingProgramFacade} from "../../facades/training-program.facade";
import {OnboardingCard, StrapiTrainingProgram} from "../../models/strapi-training-program";
import {FullScreenOverlayComponent} from "../full-screen-overlay/full-screen-overlay.component";
import {ButtonDirective} from "../../directives/button.directive";
import {CommonModule} from "@angular/common";
import {ActiveTrainingProgramStageProgression} from "../../models/training-program-progression";

@Component({
  selector: 'dc-onboarding',
  standalone: true,
  imports: [
    FullScreenOverlayComponent,
    ButtonDirective,
    CommonModule
  ],
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent implements OnInit {
  @Input() trainingProgram: StrapiTrainingProgram;
  onboardingCards: OnboardingCard[] | undefined;
  onboardingCancelCard: OnboardingCard | undefined;
  currentStep: OnboardingCard | undefined;
  currentStepNum: number;
  onboardingCardCount: number;
  showCancelCard = false;

  @Output() completedOnboarding: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _trainingProgramFacade: TrainingProgramFacade) {}

  ngOnInit(): void {

    this._trainingProgramFacade.currentTrainingProgramProgression$.subscribe(
      (data) => {
        this.onboardingCards = this.trainingProgram.onboardingCards ;
        this.onboardingCancelCard = this.trainingProgram.onboardingCancelCard;
      }
    );

    if (this.onboardingCards == undefined) {
      this.currentStep = undefined
    }
    else {
      this.currentStep = this.onboardingCards[0];
      this.currentStepNum = 0;
      this.onboardingCardCount = this.onboardingCards.length;
    }
  }

  public nextStep(stepNumber: number) {
    if (this.onboardingCards == undefined) {
      return;
    }
    this.currentStep = this.onboardingCards[stepNumber];
    this.currentStepNum += 1;
  }

  public completeOnboarding() {
    localStorage.setItem('hasCompletedOnboarding', JSON.stringify(true));
    this.completedOnboarding.emit(true);
    //TODO: Set completed onboarding in the active stage for user
  }

  cancelOnboarding() {
    this.currentStep = this.onboardingCancelCard;
    this.showCancelCard = true;
  }


  quitApplication() {
    //TODO: Implement what happens if the user do not want to do the onboarding.
  }

  continueOnboarding() {
    if (this.onboardingCards == undefined) {
      return;
    }
    this.currentStep = this.onboardingCards[this.currentStepNum];
    this.showCancelCard = false;
  }
}
