import  { HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HTTP_STATUS } from 'src/app/constants/httpStatuses';
import { environment } from 'src/environments/environment';
import {BasicProfile, Profile} from '../../models/profile';
import { HttpService } from '../http/http.service';


const profileErrors = {
  isProfileNotFound(error: HttpErrorResponse){
    return error.status === HTTP_STATUS.BAD_REQUEST && (error?.error?.errors?.DomainError as string[]).includes("User Profile not found");
  }
}

@Injectable({ providedIn: 'root' })

export class ProfileService {
    private endpoint = `${environment.coachApiUrl}/profiles`;
    errors = profileErrors;

    constructor(private http: HttpService) {}

    getProfile(){
        return this.http.get<Profile>(
            `${this.endpoint}/me`,
            {withCredentials: true}
        )
    }

    createProfile(profile: BasicProfile & {email: string}): Observable<Profile>{
        return this.http.post(
            `${this.endpoint}`,
            {...profile, departmentId: profile.departmentId},
            {withCredentials: true}
        )
    }

    updateProfile(profile: Profile) {
        return this.http.put(
            `${this.endpoint}/me`,
            profile,
            {withCredentials: true}
        );
    }
}
