@if ((profile$ | async); as profileRequest) {
  @if (profileRequest.isLoading) {
    <dc-spinner></dc-spinner>
  }
  @if (profileRequest.data; as profile) {
    <div class="profile">
      <div class="flex justify-center items-center flex-col flex-nowrap">
        <dc-personal-bird></dc-personal-bird>
        <p class="subtitle-sans mt-2 mb-4">{{ profile.givenName }}</p>
      </div>
      <dc-training-summary></dc-training-summary>
      <div class="section-seperator"></div>
      <div>
        <h2>Min Profil</h2>
        <ng-container
          *ngTemplateOutlet="editMode ? edit : preview; context: { profile }"
        ></ng-container>
        <div class="flex flex-col lg:flex-row gap-2">
          <ng-container *ngIf="profile.type === ProfileType.Admin">
            <button
              dcButton
              class="settings-page"
              (click)="navigateToSettings()"
            >Instillinger
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  }
}

<ng-template #preview let-profile="profile">
  <div class="profile-preview-field">
    <p class="label">Fornavn</p>
    <p class="profile-preview-field-value">{{ profile.givenName }}</p>
  </div>
  <div class="profile-preview-field">
    <p class="label">Etternavn</p>
    <p class="profile-preview-field-value">{{ profile.familyName }}</p>
  </div>
  <div class="profile-preview-field">
    <p class="label">E-post</p>
    <p class="profile-preview-field-value">{{ profile.email }}</p>
  </div>
  <!--
    <div class="profile-preview-field">
        <p class="title">Telefon</p>
        <p class="profile-preview-field-value">{{profile.phone}}</p>
    </div>
-->
  <!--
    <div class="profile-preview-field">
        <p class="title">Birth date</p>
        <p class="profile-preview-field-value">{{profile.birthDate | date: 'yyyy-MM-dd' }}</p>
    </div>
-->
  <div class="profile-preview-field">
    <p class="label">Bedrift</p>
    <p class="profile-preview-field-value">{{ profile.company ?? "-" }}</p>
  </div>
  <div class="profile-preview-field">
    <p class="label">Avdeling</p>
    <p class="profile-preview-field-value">{{ profile.department ?? "-" }}</p>
  </div>
  <!--  <div class="flex flex-col lg:flex-row gap-2">
      <ng-container *ngIf="user$ | async as user">
        <button
          dcButton
          *ngIf="user.isSuperAdmin"
          class="settings-page"
          (click)="navigateToSettings()"
        >
          Instillinger
        </button>
      </ng-container>-->
  <!--
      <ng-container *ngTemplateOutlet="editBtn"></ng-container>
-->
  <button
    dcButton
    class="company-administration"
    (click)="navigateToCompanyAdministration()"
  >
    Administrer selskap
  </button>
  <button dcButton theme="ghost" class="logOut" (click)="logOut()">
    Logg ut
  </button>
</ng-template>

<ng-template #editBtn>
  <button dcButton class="edit-btn" (click)="startEdit()">
    <mat-icon>edit</mat-icon>
    Edit
  </button>
</ng-template>

<ng-template #phoneEdit [formGroup]="editForm">
  <mat-form-field appearance="outline">
    <mat-label for="phone">Phone</mat-label>
    <input
      matInput
      type="text"
      id="phone"
      class="form-control"
      formControlName="phone"
      required
    />
    <mat-error *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
      Dette feltet er påkrevd
    </mat-error>
    <mat-error *ngIf="phone?.errors?.['required']">
      Name is required.
    </mat-error>
    <mat-error *ngIf="phone?.errors?.['minlength']">
      Name must be at least 4 characters long.
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #nameEdit [formGroup]="editForm">
  <mat-form-field appearance="outline">
    <mat-label for="name">Name</mat-label>
    <input
      matInput
      type="text"
      id="name"
      class="form-control"
      formControlName="name"
      required
    />
    <mat-error
      *ngIf="name?.invalid && (name?.dirty || name?.touched) || name?.errors?.['required']"
    >
      Dette feltet er påkrevd
    </mat-error>
    <mat-error *ngIf="name?.errors?.['minlength']">
      Etternavn må være minst 2 tegn langt.
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #edit let-profile="profile">
  <form [formGroup]="editForm">
    <ng-container *ngTemplateOutlet="nameEdit"></ng-container>
    <div class="row">
      <span class="title">Last name</span>
      <span class="value">{{ profile.familyName }}</span>
    </div>
    <div class="row">
      <span class="title">E-post</span>
      <span class="value">{{ profile.email }}</span>
    </div>
    <!--
        <ng-container *ngTemplateOutlet="phoneEdit"></ng-container>
-->
    <div class="row">
      <span class="title">Birth date</span>
      <span class="value">{{ profile.birthDate | date : "yyyy-MM-dd" }}</span>
    </div>
    <div class="row">
      <span class="title">Company</span>
      <span class="value">{{ profile.company }}</span>
    </div>
    <div class="row">
      <span class="title">Department</span>
      <span class="value">{{ profile.department }}</span>
    </div>
  </form>
  <div class="buttons">
    <button dcButton class="edit" (click)="cancel()">Cancel</button>
    <button dcButton [disabled]="!editForm.valid" (click)="saveChanges()">
      <mat-icon>save</mat-icon>
      Save
    </button>
  </div>
</ng-template>
