<ng-template #loading> ...Loading </ng-template>

<ng-template #noArticle>
  <span *ngIf="error$ | async as error; else loading">Article not found</span>
</ng-template>

<ng-container *ngIf="article | async as article">
  <article>
    <div
      class="horizontal-scroll"
      [@listAnimation]="article.data.attributes.paragraphs.length"
    >
      <h1>{{ article.data.attributes.title }}</h1>
      <!-- <dc-cms-image
                [imageHeight]="articleImageHeight"
                [imageUrl]="
                    article.data.attributes.mainImage.data.attributes.url
                "
            ></dc-cms-image> -->
      <div *ngFor="let contentBlock of article.data.attributes.paragraphs">
        <div
          class="content-block article-text"
          *ngIf="contentBlock.__component === 'blocks.text-component'"
        >
          <div>{{ contentBlock.text }}</div>
        </div>
        <div
          class="content-block article-image"
          *ngIf="contentBlock.__component === 'blocks.image'"
        >
          <!-- <dc-cms-image
                        [imageHeight]="articleImageHeight"
                        [imageUrl]="contentBlock.image.data.attributes.url"
                    ></dc-cms-image> -->
        </div>
      </div>
    </div>
  </article>
</ng-container>
