import { Component } from '@angular/core';

@Component({
    selector: 'dc-card-content',
    template: '<ng-content></ng-content>',
    styles: [
        `
            @import 'variables';

            :host {
                grid-area: content;
                font-size: 10px;
                color: $color-hades;
            }
        `,
    ],
    standalone: true,
})
export class DcCardContentComponent {}
