import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/common/store';
import {Frontpage} from "../models/pages/frontpage";
import {loadFrontpage} from "../common/store/actions/frontpage.actions";
import {DataFetchState} from "../models/fetch-state";

@Injectable({
    providedIn: 'root'
})

export class FrontpageFacade {
    private _frontpage$: Observable<DataFetchState<Frontpage>>;
    constructor(private _store: Store<AppState>) {
        this._frontpage$ = this._store.select(x => x.frontpage.frontpage);
    }

    get frontpage$ (){
        this._store.dispatch(loadFrontpage());
        return this._frontpage$;
    }

}
