import { Component } from '@angular/core';

@Component({
    selector: 'dc-card-compact-header',
    template: '<ng-content></ng-content>',
    styles: [
        `
            @import 'variables';

            :host {
                //border: 1px none $color-background-secondary;
                //border-bottom-style: solid;
                //padding: 0 20px 10px;
                color: $color-hades;
                margin: 0 20px 5px 20px;
                //margin-bottom: 5px;
                font-size: 0.8rem;
                display: flex;
                justify-content: space-between;
            }
        `,
    ],
    standalone: true,
})
export class DcCardCompactHeaderComponent {}
