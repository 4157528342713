import  {LocalstorageRepository} from "./LocalstorageRepository";
import {AdminSettingsState} from "../models/admin-settings";

const ADMIN_SETTINGS_KEY = "4832492374";
export const AdminSettingsRepository = {
	GetAdminSettings: () => {
		return LocalstorageRepository.GetObject<AdminSettingsState>(ADMIN_SETTINGS_KEY)
		|| {
			adminMode: false,
			showWelcomeOnFrontPage: false
		};
	},
	SetAdminSettings: (adminSettings: AdminSettingsState): void => {
		LocalstorageRepository.SetObject(ADMIN_SETTINGS_KEY, adminSettings);
	}
}
