import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StarRatingComponent } from "../star-rating/star-rating.component";
import { CommonModule } from "@angular/common";
import { ButtonDirective } from "../../directives/button.directive";

@Component({
  selector: 'dc-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  imports: [
    StarRatingComponent,
    CommonModule,
    ButtonDirective
  ],
  standalone: true
})
export class FeedbackComponent {
  @Input() title = 'Vil du gi en tilbakemelding?'
  @Input() description = '';
  @Output() private closeFeedback = new EventEmitter<any>();

  public buttonText: 'Nei, takk' | 'Send' = 'Nei, takk'
  public rating = 0;


  onRatingUpdate(rating: number) {
    this.rating = rating;
    this.buttonText = 'Send';
  }

  onButtonClick() {
    if (this.buttonText === 'Send') {
      this.closeFeedback.emit(this.rating);
    } else {
      this.closeFeedback.emit();
    }
  }
}
