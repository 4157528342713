import {LocalstorageRepository} from "./LocalstorageRepository";
import {SettingsField, SettingsState} from "../models/settings";

const SETTINGS_KEY = "settings";
export const SettingsRepository = {
	GetSettings: () => {
		return LocalstorageRepository.GetObject<SettingsState>(SETTINGS_KEY)
		|| {
			pushSettings: {
                show: true,
                timeOfLastPrompt: new Date()
            } as SettingsField,
            pwaSettings: {
                show: true,
                timeOfLastPrompt: new Date()
            } as SettingsField
		} as SettingsState;
	},
	SetSettings: (settings: SettingsState): void => {
		LocalstorageRepository.SetObject(SETTINGS_KEY, settings);
	}
}
