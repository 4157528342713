<dc-card-compact class="questionnaire-card bg-demeter">
  <dc-card-compact-title>{{ questionnaire.title }}</dc-card-compact-title>
  <dc-card-compact-content>{{
    questionnaire.shortDescription
  }}</dc-card-compact-content>
  <dc-card-compact-actions class="buttons">
    <button dcButton theme="primary" (click)="onOpen(questionnaire.id)">
      Start
    </button>
  </dc-card-compact-actions>
</dc-card-compact>
