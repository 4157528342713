import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
export * from './ui/dc-card-actions.component';
export * from './ui/dc-card-content.component';
export * from './ui/dc-card-header.component';
export * from './ui/dc-card-title.component';

@Component({
    selector: 'dc-card',
    templateUrl: './dc-card.component.html',
    styleUrls: ['./dc-card.component.scss'],
    standalone: true,
    imports: [MatCardModule],
})
export class DcCardComponent {}
