import {createReducer, on} from '@ngrx/store';
import {SystemSettingsState} from "../../../models/system-settings";
import {systemSettingsChanged} from "../actions/system-settings.actions";

const ResolveInitialSettingsState = ():SystemSettingsState => {
    return {
        hideMenu: false
    } as SystemSettingsState;
}
export const systemSettingsReducer = createReducer<SystemSettingsState>(ResolveInitialSettingsState(),
    on(systemSettingsChanged, (state,{systemSettings}) => {
        return {...state,...systemSettings}
    }),
);
