<dc-full-screen-overlay [hideCloseIcon]="true" class="relative z-10">
  <img width="130" height="130" class="mb-3" [src]="currentStep?.icon ?? 'assets/logos/Fugl06_Rosa.svg'" alt="Fugle ikon" />
  <div class="flex flex-col gap-4 max-w-[270px] mt-12">
    <h2>{{ currentStep?.title }}</h2>
    <p class="font-normal">{{ currentStep?.text }}</p>
  </div>
  <div class="absolute bottom-24 flex-col flex">
    <button dcButton theme="primary" *ngIf="currentStepNum < onboardingCardCount - 1 && !showCancelCard" (click)="nextStep(currentStepNum + 1)">
      Neste</button>
    <button dcButton theme="primary" *ngIf="currentStepNum === onboardingCardCount - 1 && !showCancelCard" (click)="completeOnboarding()">Klar</button>
    <button dcButton theme="primary" *ngIf="showCancelCard" (click)="continueOnboarding()">OK, Kjør test</button>
    <button *ngIf="!showCancelCard" dcButton theme="ghost" class="text-demeter" (click)="cancelOnboarding()">Skeptisk</button>
    <button *ngIf="showCancelCard" dcButton theme="ghost" class="text-demeter" (click)="quitApplication()">Takk for nå</button>
  </div>
</dc-full-screen-overlay>
