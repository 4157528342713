import {Component, OnInit} from '@angular/core';
import {LogoComponent} from "../logo/logo.component";
import {SettingsFacade} from "../../facades/settings.facade";
import {Observable} from "rxjs";

@Component({
    selector: 'dc-personal-bird',
    standalone: true,
    imports: [
        LogoComponent
    ],
    templateUrl: './personal-bird.component.html',
    styleUrl: './personal-bird.component.scss'
})
export class PersonalBirdComponent implements OnInit {
    showSelectBirdModal: boolean = true;

    constructor(private _settings: SettingsFacade) {
    }

    ngOnInit(): void {
    }
}
