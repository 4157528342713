import { createAction, props } from '@ngrx/store';
import {
  ChallengeCompleteResult,
  PaginationInfo,
  Challenge,
  ChallengeProgression,
} from 'src/app/models/challenge';
import {Playlist} from "../../../models/playlist";
import {Guid} from "../../../models/types";
import {HttpErrorResponse} from "@angular/common/http";

const LOAD_CURRENT_CHALLENGES = '[Challenges] Loading current challenges';
const LOAD_CURRENT_CHALLENGES_IS_LOADING = '[Challenges] Loading current challenges is loading';
const LOAD_CURRENT_CHALLENGES_SUCCESS = '[Challenges] Load current challenges success';
const LOAD_CURRENT_CHALLENGES_FAILURE = '[Challenges] Load current challenges failure';

const LOAD_CURRENT_PLAYLIST = '[Challenges] Loading current playlist';
const LOAD_CURRENT_PLAYLIST_IS_LOADING = '[Challenges] Loading current playlist is loading';
const LOAD_CURRENT_PLAYLIST_SUCCESS = '[Challenges] Load current playlist success';
const LOAD_CURRENT_PLAYLIST_FAILURE = '[Challenges] Load current playlist failure';

export const loadCurrentPlaylist = createAction(LOAD_CURRENT_PLAYLIST,
  props<{ reload?: boolean }>());
export const loadCurrentPlaylistIsLoading = createAction(LOAD_CURRENT_PLAYLIST_IS_LOADING);
export const loadCurrentPlaylistSuccess = createAction(
  LOAD_CURRENT_PLAYLIST_SUCCESS,
  props<{ playlist: Playlist }>()
);
export const loadCurrentPlaylistFailure = createAction(
  LOAD_CURRENT_PLAYLIST_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

export const loadCurrentChallenges = createAction(LOAD_CURRENT_CHALLENGES,
  props<{ reload?: boolean }>());
export const loadCurrentChallengesIsLoading = createAction(LOAD_CURRENT_CHALLENGES_IS_LOADING);
export const loadCurrentChallengesSuccess = createAction(
  LOAD_CURRENT_CHALLENGES_SUCCESS,
  props<{ challenges: Challenge[] }>()
);
export const loadCurrentChallengesFailure = createAction(
  LOAD_CURRENT_CHALLENGES_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

const START_CHALLENGE = '[Challenges] Start';
const START_CHALLENGE_SUCCESS = '[Challenges] Start success';
const START_CHALLENGE_FAILURE = '[Challenges] Start failure';

export const startChallenge = createAction(
  START_CHALLENGE,
  props<{ challengeId: Guid }>()
);
export const startChallengeSuccess = createAction(
  START_CHALLENGE_SUCCESS,
  props<{ challenge: Challenge }>()
);
export const startChallengeFailure = createAction(
  START_CHALLENGE_FAILURE,
  props<{ error: HttpErrorResponse }>()
);

const COMPLETE_CHALLENGE = '[Challenges] Complete';
const COMPLETE_CHALLENGE_SUCCESS = '[Challenges] Complete success';

export const completeChallenge = createAction(
  COMPLETE_CHALLENGE,
  props<{ result: ChallengeCompleteResult }>()
);
export const completeChallengeSuccess = createAction(COMPLETE_CHALLENGE_SUCCESS);

const BOOKMARK_PROGRESSION_STEP = '[Challenges] Bookmark progression';
const BOOKMARK_PROGRESSION_STEP_SUCCESS =
  '[Challenges] Bookmark progression success';
export const bookmarkProgressionStep = createAction(
  BOOKMARK_PROGRESSION_STEP,
  props<{ progress: number; progression: ChallengeProgression }>()
);
export const bookmarkProgressionStepSuccess = createAction(
  BOOKMARK_PROGRESSION_STEP_SUCCESS,
  props<{ challenge: Challenge }>()
);
