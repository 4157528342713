import {Component} from '@angular/core';
import {isDevMode} from '@angular/core';
import {SettingsFacade} from "../../facades/settings.facade";
import {BrowserAgentUtils} from "../../utils/BrowserAgentUtils";
import { SettingsVisibilityPipe } from '../../pipes/settings-visibility.pipe';
import { NgIf, AsyncPipe } from '@angular/common';
import {ButtonDirective} from "../../directives/button.directive";


@Component({
    selector: 'dc-pwa-install-prompt',
    templateUrl: './pwa-install-prompt.component.html',
    styleUrls: ['./pwa-install-prompt.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, SettingsVisibilityPipe, ButtonDirective]
})
export class PwaInstallPromptComponent {
    deferredPrompt: Event | null;
    settings$ = this._settingsFacade.settings$;

    constructor(private _settingsFacade: SettingsFacade) {
        //We don't want to prompt the user for PWA when they already installed it
        const isPwaInstalled = BrowserAgentUtils.isInStandaloneMode();
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            console.debug("beforeinstallprompt fired, client ready to install PWA");
            this.deferredPrompt = event;
        });
        window.addEventListener("appinstalled", (event) => {
            console.debug("appinstalled fired, PWA was installed");
           this._settingsFacade.setPwaSettings({
                show: false,
                timeOfLastPrompt: new Date()
            });
        });
    }
    isDevEnvironment() {
        return isDevMode();
    }
    resetPwaSettings() {
        this._settingsFacade.setPwaSettings({
            show: true,
            timeOfLastPrompt: new Date()
        });
    }
    declinePwaInstall() {
        this._settingsFacade.setPwaSettings({
            show: false,
            timeOfLastPrompt: new Date()
        });
    }
    async installPwa() {
        this._settingsFacade.setPwaSettings({
            show: false,
            timeOfLastPrompt: new Date()
        });
        // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
        if (!BrowserAgentUtils.isIos() && !BrowserAgentUtils.isInStandaloneMode() && this.deferredPrompt) {
            // @ts-ignore
            this.deferredPrompt.prompt();
            // Find out whether the user confirmed the installation or not
            // @ts-ignore
            const {outcome} = await this.deferredPrompt.userChoice;
            // The deferredPrompt can only be used once.
            this.deferredPrompt = null;
            // Act on the user's choice
            if (outcome === 'accepted') {
                console.log('User accepted the install prompt.');
            } else if (outcome === 'dismissed') {
                console.log('User dismissed the install prompt');
            }
        } else {
            console.warn("PWA installation is not supported on this device", BrowserAgentUtils.isInStandaloneMode(), BrowserAgentUtils.isIos());
        }
    }
}
