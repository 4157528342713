import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StatisticsService } from '../services/statistics/statistics.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  loadCompletedChallenges,
  loadCompletedChallengesError,
  loadCompletedChallengesSuccess,
  loadPersonaTypes,
  loadPersonaTypesError,
  loadPersonaTypesSuccess
} from "../common/store/actions/statistics.actions";

@Injectable()
export class StatisticsEffects {
    constructor(
        private actions$: Actions,
        private statisticsService: StatisticsService
    ) { }

    loadCompletedChallenges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCompletedChallenges),
            switchMap(({ from, to, interval }) =>
                this.statisticsService
                    .getCompletedChallenges(from, to, interval)
                    .pipe(
                        map((completedChallenges) =>
                            loadCompletedChallengesSuccess({
                                completedChallenges,
                            })
                        ),
                        catchError((error: HttpErrorResponse) => {
                            console.error(error);
                            return of(loadCompletedChallengesError(error));
                        })
                    )
            )
        )
    );

    loadPersonaTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPersonaTypes),
            switchMap(() =>
                this.statisticsService.getPersonaTypeScores().pipe(
                    map((personaTypes) =>
                        loadPersonaTypesSuccess({
                            personaTypes,
                        })
                    ),
                    catchError((error: HttpErrorResponse) => {
                        console.error(error);
                        return of(loadPersonaTypesError(error));
                    })
                )
            )
        )
    );
}
