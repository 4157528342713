/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from "./auth-interceptor";
import { LoggingInterceptor } from "./logging-interceptor";
import { HttpMockInterceptor } from "./http-mock-interceptor";
import { TraceContextInterceptor } from './trace-context.interceptor';


/** Http interceptor providers in outside-in order */
export const interceptorProviders = [
//  { provide: HTTP_INTERCEPTORS, useClass: HttpMockInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TraceContextInterceptor, multi: true }
];
