import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/common/store';
import {AdminSettingsState} from "../models/admin-settings";
import {adminSettingsChanged} from "../common/store/actions/admin-settings.actions";

@Injectable({
    providedIn: 'root'
})

export class AdminSettingsFacade {
	adminSettings: Observable<AdminSettingsState>;
    constructor(private _store: Store<AppState>) {
		this.adminSettings = this._store.select(x => x.adminSettings);
    }
    setSettings(adminSettings: AdminSettingsState) {
        this._store.dispatch(adminSettingsChanged({adminSettings}));
    }
}
