// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  logHttpRequests: false,
  mock: false,
  envName: "Development",
  authUrl: "https://identity.dialead.net",
  authClientId: "33d42aab-242f-4367-bab9-982ce5ad4785",
  coachApiUrl: "https://capi-dev.dialead.net/api",
  cmsEndpoint: "https://strapi.dialead.net",
  cmsApiKey: "b4b826e065e875220147da64586ffc637b37cbf9562180457e83d4459923ae1bf3840fd6171c3cd7e48b59d4f67a8a4003c5ca1c1bb22a919b2499c0983200701aa34ace62512ec5a39ea4cb45697a1f19214004dde656481678ba32d42932c1c0e80c77387541894ce0c6c82c03d6ea6090551bdaa0ca5304ce2bf629bb56ec",
  notificationsRequestTimeSpanInMin: 2,
  notificationsDisplayTimeSpanInSec: 30,
}
