import {AsyncPipe, CommonModule, NgTemplateOutlet,} from '@angular/common';
import {Challenge, ChallengeProgressionState} from '../../models/challenge';
import {Component, OnInit} from '@angular/core';
import {FadeInFadeOut, PopulateList} from 'src/app/utils/animation';

import {AuthService} from '../../services/auth/auth.service';
import {ChallengesFacade} from 'src/app/facades/challenges.facade';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Observable, Subscription} from 'rxjs';
import {SortPipe} from '../../pipes/sort.pipe';
import {ButtonDirective} from 'src/app/directives/button.directive';
import {SystemSettingsFacade} from 'src/app/facades/system-settings.facade';
import {ChallengeCardComponent} from '../../components/challenges/challenge-card/challenge-card.component';
import {ChallengeComponent} from "../../components/challenges/challenge/challenge.component";
import {DataFetchState} from "../../models/fetch-state";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

@Component({
  selector: 'dc-challenges',
  animations: [FadeInFadeOut, PopulateList()],
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ChallengeCardComponent,
    ButtonDirective,
    NgTemplateOutlet,
    MatProgressSpinnerModule, // Probably replace by "SpinnerDirective"?
    AsyncPipe,
    SortPipe,
    ChallengeComponent,
    SpinnerComponent,
  ],
})
export class ChallengesComponent implements OnInit {
  subscriptions: Subscription;
  public activeChallenge: Challenge | undefined;
  challenges$: Observable<DataFetchState<Challenge[]>>;
  constructor(
    public auth: AuthService,
    private _systemSettingsFacade: SystemSettingsFacade,
    private _challengesFacade: ChallengesFacade // private trainingProgramFacade: TrainingProgramFacade
  ) {}

  onChallengeClick = (challenge: Challenge) => {
    console.log(`[ChallengesComponent] Challenge clicked: title: ${challenge.title}, state: ${challenge.state}`);
    if (challenge.state === ChallengeProgressionState.NotAvailable) {
      // TODO verify if it is correct to do nothing in this case
      return;
    }
    this.activeChallenge = challenge;
    this._systemSettingsFacade.setSystemSettings({
      hideMenu: true,
    });
  };

  onChallengeClosed() {
    this.activeChallenge = undefined;
    this._systemSettingsFacade.setSystemSettings({
      hideMenu: false,
    });
  }

  onChallengeFinished() {
    console.log('[ChallengeComponent] Challenge finished');
    this.activeChallenge = undefined;
    this._systemSettingsFacade.setSystemSettings({
      hideMenu: false
    });
  }

  ngOnInit(): void {
    this.challenges$ = this._challengesFacade.currentChallenges$;
    console.log("[Challenges.component]These challenges....")
    console.log(this.challenges$);
  }
}
