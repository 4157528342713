import { Component } from '@angular/core';

//todo: remove usage
@Component({
    selector: 'dc-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    standalone: true
})
export class ContainerComponent {}
