import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {NgIf, DatePipe, NgOptimizedImage} from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { FullScreenOverlayComponent } from 'src/app/components/full-screen-overlay/full-screen-overlay.component';
import { ButtonDirective } from 'src/app/directives/button.directive';
import { SystemSettingsFacade } from 'src/app/facades/system-settings.facade';
import { Challenge } from 'src/app/models/challenge';
import {Icon, Icons} from "../../../models/icon";

@Component({
  selector: 'dc-text-challenge',
  templateUrl: './text-challenge.component.html',
  styleUrls: ['./text-challenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    ButtonDirective,
    FullScreenOverlayComponent,

    // TODO maybe not needed, check
    DatePipe,
    MatRippleModule,
    MatIconModule,
    NgOptimizedImage,
  ],
})
export class TextChallengeComponent {
  @Input() challenge: Challenge;
  @Output() private challengeClosed = new EventEmitter();
  @Output() private challengeAccepted = new EventEmitter();
  constructor(private _systemSettingsFacade: SystemSettingsFacade) {}

  acceptChallenge() {
    console.debug("[TextChallengeComponent] acceptChallenge");
    this.challengeAccepted.emit();
  }

  postponeChallenge() {
    console.debug("[TextChallengeComponent] OnPostponeChallenge");
    this.challengeClosed.emit();
  }

  onCloseChallenge = () => {
    console.debug("[TextChallengeComponent] onCloseChallenge");
    this.challengeClosed.emit();
  };
  getIcon(icon: Icon = "sun") {
    return Icons[icon] ?? Icons["sun"];
  }
}
