import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { SpinnerComponent } from "../components/spinner/spinner.component";

@Directive({
    selector: '[appSpinnerDirective]',
    standalone: true
})
export class SpinnerDirective {
    @Input()
    spinnerDiameter: number = 25;

    @Input('appSpinnerDirective')
    set isElementLoading(isLoading: boolean) {
        if (isLoading) {
            this.vcr.clear();
            const spinner = this.vcr.createComponent(SpinnerComponent);
            spinner.instance.diameter = this.spinnerDiameter;
        } else {
            this.vcr.createEmbeddedView(this.templateRef);
        }
    }

    constructor(private templateRef: TemplateRef<any>, private vcr: ViewContainerRef) { }
}
