import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/common/store';
import {AdminSettingsState} from "../models/admin-settings";
import {adminSettingsChanged} from "../common/store/actions/admin-settings.actions";
import {SystemSettingsState} from "../models/system-settings";
import {systemSettingsChanged} from "../common/store/actions/system-settings.actions";

@Injectable({
    providedIn: 'root'
})

export class SystemSettingsFacade {
	systemSettings: Observable<SystemSettingsState>;
    hideMenu: Observable<boolean>;
    constructor(private _store: Store<AppState>) {
		this.systemSettings = this._store.select(x => x.systemSettings);
        this.hideMenu = this._store.select(x => x.systemSettings.hideMenu);
    }
    setSystemSettings(systemSettings: SystemSettingsState) {
        this._store.dispatch(systemSettingsChanged({systemSettings}));
    }
}
