import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'dc-signup-departments-selection',
    template: `
      <mat-form-field appearance="outline" [formGroup]="form">
        <mat-label>Select department</mat-label>
        <mat-select formControlName="department">
            <mat-option *ngFor="let dep of company.departments" [value]="dep.id">
                {{dep.name}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    `,
    styleUrls: ['./../../signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, NgFor, MatOptionModule]
})


export class DepartamentsSelectionComponent {
    @Input()
    company: Company;

    @Input()
    form: FormGroup;

    constructor() {
        console.log({company: this.company});
    }
}
