import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Questionnaire } from 'src/app/models/questionnaire';
import { DcCardCompactActionsComponent } from '../../../../extended-components/dc-card-compact/ui/dc-card-compact-actions.component';
import { DcCardCompactContentComponent } from '../../../../extended-components/dc-card-compact/ui/dc-card-compact-content.component';
import { DcCardCompactTitleComponent } from '../../../../extended-components/dc-card-compact/ui/dc-card-compact-title.component';
import { DcCardCompactHeaderComponent } from '../../../../extended-components/dc-card-compact/ui/dc-card-compact-header.component';
import { DcCardCompactComponent } from '../../../../extended-components/dc-card-compact/dc-card-compact.component';
import {ButtonDirective} from "../../../../directives/button.directive";

@Component({
    selector: 'dc-questionnaire-list-card',
    templateUrl: './questionnaire-list-card.component.html',
    styleUrls: ['./questionnaire-list-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DcCardCompactComponent, DcCardCompactHeaderComponent, DcCardCompactTitleComponent, DcCardCompactContentComponent, DcCardCompactActionsComponent, ButtonDirective]
})
export class QuestionnaireListCardComponent{
    @Input() questionnaire: Questionnaire;
    @Input() onOpen: (id: string) => void;

    constructor(private router: Router) { }
}
