import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Questionnaire} from 'src/app/models/questionnaire';
import {QuestionnairesFacade} from 'src/app/facades/questionnaires.facade';
import {Observable, Subscription} from 'rxjs';
import {FadeInFadeOut, PopulateList} from 'src/app/utils/animation';
import {QuestionnaireListCardComponent} from './ui/questionnaire-list-card/questionnaire-list-card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {DataFetchState} from "../../models/fetch-state";
import {Router} from "@angular/router";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

@Component({
  selector: 'dc-questionnaires',
  templateUrl: './questionnaires.component.html',
  animations: [FadeInFadeOut, PopulateList(undefined, null)],
  styleUrls: ['./questionnaires.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    NgFor,
    QuestionnaireListCardComponent,
    AsyncPipe,
    SpinnerComponent,
  ],
})

export class QuestionnairesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  questionnaires$: Observable<DataFetchState<Questionnaire[]>>;

  @Input() showHeader: boolean = true;

  constructor(
    private router: Router,
    private _questionnairesFacade: QuestionnairesFacade,
  ) {
    this.questionnaires$ = this._questionnairesFacade.questionnaires$;
  }

  ngOnInit(): void {
    this.subscriptions = this.questionnaires$.subscribe((e) => {
      console.log("Questionnaire:", e);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onOpenQuestionnaire(id: string) {
    this.router.navigate([`/questionnaires/${id}`]);
  }
}
