import {createReducer, on} from '@ngrx/store';
import {Questionnaire} from 'src/app/models/questionnaire';
import {
  loadQuestionnaires, loadQuestionnairesFailure, loadQuestionnairesIsLoading,
  loadQuestionnairesSuccess,
  submitQuestionnaire,
  submitQuestionnaireSuccess
} from '../actions/questionnaires.actions';
import {DataFetchState} from "../../../models/fetch-state";

export interface QuestionnairesState {
  questionnaires: DataFetchState<Questionnaire[]>;
  isQuestionnaireSubmitting: boolean;
}

export const initialState: QuestionnairesState = {
  questionnaires: {
    data: undefined,
    isLoading: false,
    error: null
  },
  isQuestionnaireSubmitting: false
};

export const questionnairesReducer = createReducer<QuestionnairesState>(initialState,
  on(loadQuestionnairesIsLoading, (state) => ({
    ...state,
    questionnaires: {
      ...state.questionnaires,
      isLoading: true
    }
  })),
  on(loadQuestionnairesSuccess, (state, {questionnaires}) => {
    console.log("QUESTIONNAIRE_COMPLETED: ", questionnaires);
    return {
      ...state,
      questionnaires: {
        data: questionnaires,
        isLoading: false,
        error: null
      }
    };
  }),
  on(loadQuestionnairesFailure, (state, {error}) => ({
    ...state,
    questionnaires: {
      data: null,
      isLoading: false,
      error: error
    }
  })),
  on(submitQuestionnaire, (state) => ({
    ...state,
    isQuestionnaireSubmitting: true
  })),
  on(submitQuestionnaireSuccess, (state) => ({
    ...state,
    isQuestionnaireSubmitting: false
  })),
);
