<div class="bg-hades bg-opacity-35 absolute top-0 right-0 left-0 bottom-0">
  <div class="flex flex-col items-center justify-center h-full w-full">
    <div class="mx-5">
      <dc-simple-card>
        @if(title) {
          <p class="text-hades text-lg text-center font-bold">{{title}}</p>
        }
        <ng-content></ng-content>
      </dc-simple-card>
    </div>
  </div>
</div>
