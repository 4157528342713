<ng-container>
    <div *ngIf="true">HELLO</div>
    <dialog [open]="open" id="messagebox-global">
        HELLO
        <div class="border-2 rounded-sm" [ngClass]="{
	'bg-apollo':type==='warning',
	'bg-achilles':type==='info',
	'bg-theseus':type==='error'
	}">
            <h4>{{ messageHeadline }}</h4>
            <ng-content></ng-content>
        </div>
        <div (click)="closeDialog()">Lukk</div>
    </dialog>
</ng-container>
