import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnairesFacade } from 'src/app/facades/questionnaires.facade';
import { QuestionAnswer, QuestionType, Questionnaire } from 'src/app/models/questionnaire';
import { QuestionComponent } from '../ui/question/question.component';
import { Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import {ButtonDirective} from "../../../directives/button.directive";
import {SimpleCardComponent} from "../../../components/simple-card/simple-card.component";
import {ProgressBarComponent} from "../../../components/progress-bar/progress-bar.component";
import {LogoComponent} from "../../../components/logo/logo.component";
import {SystemSettingsFacade} from "../../../facades/system-settings.facade";

//TODO: questions should be done with seperate request
@Component({
    selector: 'dc-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        MatIconModule,
        QuestionComponent,
        MatProgressSpinnerModule,
        ButtonDirective,
        SimpleCardComponent,
        ProgressBarComponent,
        LogoComponent,
    ],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
    questionnaire: Questionnaire | undefined;
    isNotFound: boolean = false;
    questionIndex: number = 0;
    answers: QuestionAnswer[] = [];
    isAllAnswered: boolean = false;
    isSubmiting: boolean;
    showResultMessage: boolean = false;
    submitBtnTooltip: string = "Please answer questions first";

    subscriptions: Subscription = new Subscription();

    get progress() {
        const question = this.questionIndex + 1 + (this.showResultMessage ? 1 : 0);
        const total = this.questionnaire!.questions.length + 1;
        return `${question}/${total}`;
    }

    @ViewChild(QuestionComponent) questionComponent: QuestionComponent

    constructor(private activatedRoute: ActivatedRoute, private facade: QuestionnairesFacade, private _systemSettingsFacade: SystemSettingsFacade) { }

    ngOnInit(): void {
        this.facade.questionnaires$.subscribe(
            list => {
                const id = this.activatedRoute.snapshot.params['id'];
                this.questionnaire = list.data?.find(q => q.id === id.toString());
                this.isNotFound = list.data !== undefined && this.questionnaire === undefined;
                if (this.questionnaire) {
                    this.answers = this.questionnaire.questions.map(x => ({ questionId: x.id, questionType: x.type }));
                }
            }
        )

        const sub = this.facade.isQuestionnaireSubmitting$.subscribe(((isSubmitting) => {
            this.isSubmiting = isSubmitting;
        }));
        this.subscriptions.add(sub);
        this._systemSettingsFacade.setSystemSettings({
            hideMenu: true
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this._systemSettingsFacade.setSystemSettings({
            hideMenu: false
        });
    }

    onSubmit() {
        const { isAllAnswered, isSubmiting } = this;
        if (isAllAnswered && !isSubmiting) {
            this.facade.submitQuestionnaire(this.questionnaire!.id, this.answers);
        }
    }

    nextQuestion() {
        if (this.questionIndex + 1 >= this.questionnaire!.questions.length) {
            this.showResultMessage = true;
        } else {
            this.saveCurrentAnswer();
            this.questionIndex++;
        }
    }

    previousQuestion() {
        if (this.questionIndex > 0) {
            if (this.showResultMessage) {
                this.showResultMessage = false;
            } else {
                this.saveCurrentAnswer();
                this.questionIndex--;
            }
        }
    }

    private isAnswered(answer: QuestionAnswer) {
        return answer.boolAnswer !== undefined
            || (answer.textAnswer !== undefined && answer.textAnswer !== "")
            || answer.answerId !== undefined
    }

    onAnswerChange(answer: QuestionAnswer) {
        this.checkIfAllIsAnswered(answer);
        if (answer.questionType !== QuestionType.shortText && answer.questionType !== QuestionType.longText)
            this.nextQuestion();
    }

    checkIfAllIsAnswered(answer: QuestionAnswer) {
        let nonAnsweredQuestionsIds: number[] = [];
        const answeredAmount = this.answers.filter((answ, idx) => {
            const isAnswered = answ.questionId === answer.questionId ? this.isAnswered(answer) : this.isAnswered(answ);
            if (!isAnswered) nonAnsweredQuestionsIds.push(idx + 1);
            return isAnswered;
        }).length;

        this.submitBtnTooltip = nonAnsweredQuestionsIds.length > 0
            ? `Questions ${nonAnsweredQuestionsIds.join(',')} require answers`
            : "Send in";

        this.isAllAnswered = answeredAmount === this.questionnaire!.questions.length;
    }

    saveCurrentAnswer() {
        this.answers[this.questionIndex] = this.questionComponent.answer;
    }

    protected readonly Math = Math;
}
