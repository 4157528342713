import {
  transition,
  style,
  animate,
  query,
  stagger,
  trigger,
  group
} from '@angular/animations';

const optional = { optional: true };
const defaultAnimationSettings = {speed: 500, stagger: 100}

//provide
//null to turn off transition,
//undedined to use default
export const PopulateList = (enter?: { speed?: number, stagger?: number } | null, leave?: { speed ?: number, stagger ?: number } | null) => {
    const transitions = [];

    if(leave !== null) {
        const leaveConfig = leave || defaultAnimationSettings;
        if (leaveConfig.speed === undefined) leaveConfig.speed = defaultAnimationSettings.speed;
        if (leaveConfig.stagger === undefined) leaveConfig.stagger = defaultAnimationSettings.stagger;
        transitions.push(
            query(':leave',
                [stagger(leaveConfig.stagger, [animate(leaveConfig.speed, style({ opacity: 0 }))])],
                optional
            ),
        )
    }

    if (enter !== null) {
        const enterConfig = enter || defaultAnimationSettings;;
        if (enterConfig.speed === undefined) enterConfig.speed = defaultAnimationSettings.speed;
        if (enterConfig.stagger === undefined) enterConfig.stagger = defaultAnimationSettings.stagger;
        transitions.push(
            query(':enter',
                [
                    style({ opacity: 0 }),
                    stagger(enterConfig.stagger, [animate(enterConfig.speed, style({ opacity: 1 }))])
                ],
                optional
            )
        )
    }

    return trigger('listAnimation', [ transition('* => *', transitions) ]);
}

export const FadeInFadeOut = trigger('fade', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 }))
    ])
]);


export const fader = trigger('routeAnimations', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 }))
    ])
]);

export const slider = trigger('routeAnimations', [
    transition(':increment', slideTo('right')),
    transition(':decrement', slideTo('left')),
]);

function slideTo(direction: 'left' | 'right'){
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                [direction]: 0,
                width: '100%',
            }),
        ], optional),
        query(':enter', [
            style({
                [direction]: '-100%',
            })
        ], optional),
        query(':leave', [
            style({
                [direction]: '0%'
            })
        ], optional),
        group([
            query(':leave', [
                animate('200ms ease',
                    style({ [direction]: '100%'})
                )
            ], optional),
            query(':enter', [
                animate('300ms ease',
                    style({ [direction]: '0%' })
                )
            ], optional),
        ])
    ];
}
