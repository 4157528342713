import {Component, Input} from '@angular/core';
import {SimpleCardComponent} from "../simple-card/simple-card.component";

@Component({
  selector: 'dc-simple-modal',
  standalone: true,
    imports: [
        SimpleCardComponent
    ],
  templateUrl: './simple-modal.component.html',
  styleUrl: './simple-modal.component.scss'
})
export class SimpleModalComponent {
  @Input() title?: string;
}
