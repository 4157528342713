import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authCodeFlowConfig: AuthConfig = {
	issuer: environment.authUrl,
	redirectUri: window.location.origin + "/login-callback",
	clientId: environment.authClientId,
	responseType: 'code',
	scope: 'openid profile email offline_access Dialead roles',
	showDebugInformation: true,

    // logoutUrl: environment.authUrl + "/Account/Logout", //needed in order to logout from idenity server
	postLogoutRedirectUri: window.location.origin,
};
