import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Company, Company_v1, CompanyExtendedInfo} from "../../models/company";
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environments/environment';
import {Employee} from "../../models/employee";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    private endpoint = `${environment.coachApiUrl}/companies`
    constructor(private http: HttpService, private _http: HttpClient) {}

    getCompanyByEmail(email: string): Observable<Company>{
        const domain = email.split('@')[1];
        return this.http.get<Company>(`${this.endpoint}/${domain}`, {withCredentials: true});
    }
    getCompanyInformationByLogin(): Observable<CompanyExtendedInfo>{
        return this.http.get<CompanyExtendedInfo>(`${this.endpoint}/my`, {withCredentials: true});
    }
    getCompanyUsersByCompanyId(companyId: string): Observable<Array<Employee>>{
        return this.http.get<Array<Employee>>(`${this.endpoint}/${companyId}/profiles`, {withCredentials: true});
    }
    getCompaniesByEmail_v1(email: string): Observable<Array<Company_v1>> { //initial func
        return this._http
        .get<Array<Company_v1>>(`${this.endpoint}`, {
            responseType: "json",
            params: new HttpParams().set('email',encodeURIComponent(email))
        })
    }
}
