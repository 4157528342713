import {
  ECompletedChallengesInterval,
  BarChartSeries,
  CompletedChallenges,
  PersonaTypeScore,
  PersonaTexts,
} from '../models/statistics';
import {Observable, combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AppState} from '../common/store';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DataFetchState} from "../models/fetch-state";
import {loadCompletedChallenges, loadPersonaTypes } from '../common/store/actions/statistics.actions';

@Injectable({
  providedIn: 'root',
})
export class StatisticsFacade {
  completedChallenges$: Observable<DataFetchState<CompletedChallenges[]>>;
  hasCompletedChallanges$: Observable<boolean>;
  challengesBarChartSeries$: Observable<BarChartSeries>;
  challengesBarChartCategories$: Observable<string[]>;
  personaTypes$: Observable<DataFetchState<PersonaTypeScore[]>>;
  isPersonaTypesLoading$: Observable<boolean>;
  personaTypeSeries$: Observable<BarChartSeries[]>;
  personaTypeCategories$: Observable<string[]>;
  hasPersonaTypes$: Observable<boolean>;

  isStatisticsLoading$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.completedChallenges$ = this.store.select(({statistics}) => statistics.completedChallenges);
    this.personaTypes$ = this.store.select(({statistics}) => statistics.personaTypes);
    this.hasCompletedChallanges$ = this.hasCompletedChallengesData$(this.completedChallenges$);
    this.challengesBarChartSeries$ = this.getChallengesBarChartSeries$(this.completedChallenges$);
    this.challengesBarChartCategories$ = this.getChallengesBarChartCategories$(this.completedChallenges$);
    const personaTypes$ = this.store.select(
      ({statistics}) => statistics.personaTypes
    );
    this.personaTypeSeries$ =
      this.getPersonaTypeBarChartSeries$(this.personaTypes$);
    this.personaTypeCategories$ =
      this.getPersonaTypeBarChartCategories$(this.personaTypes$);
    this.hasPersonaTypes$ = this.hasPersonaTypesData$(this.personaTypes$);
  }

  loadCompletedChallenges(): void {
    const to = new Date();
    const fourWeeksDurationMs = 24 * 60 * 60 * 1000 * (3 * 7);
    const from = new Date(to.getTime() - fourWeeksDurationMs);
    const interval = ECompletedChallengesInterval.WEEK;

    this.store.dispatch(
      loadCompletedChallenges({
        from: this.getFormattedDate(from),
        to: this.getFormattedDate(to),
        interval,
      })
    );
  }

  loadPersonaTypes(): void {
    this.store.dispatch(loadPersonaTypes());
  }

  private getFormattedDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private getChallengesBarChartSeries$(
    challenges$: Observable<DataFetchState<CompletedChallenges[]>>
  ): Observable<BarChartSeries> {
    return challenges$.pipe(
      map((challenges) => ({
        name: 'Challenges completed',
        data: challenges.data?.map((c) => c.completedChallenges) ?? [],
        color: '#71B23E',
      }))
    );
  }

  private getChallengesBarChartCategories$(
    challenges$: Observable<DataFetchState<CompletedChallenges[]>>
  ): Observable<string[]> {
    return challenges$.pipe(
      map((challenges) => challenges.data?.map((c) => c.startDate) ?? [])
    );
  }

  private hasCompletedChallengesData$(
    challenges$: Observable<DataFetchState<CompletedChallenges[]>>
  ): Observable<boolean> {
    return challenges$.pipe(
      map(
        (challenges) =>
          !!challenges.data &&
          !!Array.isArray(challenges.data) &&
          !!challenges.data.find(({completedChallenges}) => completedChallenges > 0)
      )
    );
  }

  private getPersonaTypeBarChartSeries$(
    types$: Observable<DataFetchState<PersonaTypeScore[]>>
  ): Observable<BarChartSeries[]> {
    return types$.pipe(
      map((types) => {
        const series: [BarChartSeries, BarChartSeries] = [
          {name: 'Previous', data: [], color: '#A7CEE3'},
          {name: 'Current', data: [], color: '#71B23E'},
        ];
        types.data?.forEach((t) => {
          series[0].data.push(t.previousScore);
          series[1].data.push(t.currentScore);
        });

        return series;
      })
    );
  }

  private getPersonaTypeBarChartCategories$(
    types$: Observable<DataFetchState<PersonaTypeScore[]>>
  ): Observable<string[]> {
    return types$.pipe(
      map((types) =>
        types.data?.map(({personaType}) => PersonaTexts[personaType]) ?? []
      )
    );
  }

  private hasPersonaTypesData$(
    types$: Observable<DataFetchState<PersonaTypeScore[]>>
  ): Observable<boolean> {
    return types$.pipe(
      map((types) =>
        !!types &&
        !!Array.isArray(types.data) && types.data.length > 0)
    );
  }

  private getIsStatisticsLoading$(
    isPersonaTypesLoading$: Observable<boolean>,
    isCompletedChallengesLoading$: Observable<boolean>
  ): Observable<boolean> {
    return combineLatest([
      isPersonaTypesLoading$,
      isCompletedChallengesLoading$,
    ]).pipe(
      map(
        ([isPersonaTypesLoading, isCompletedChallengesLoading]) =>
          isPersonaTypesLoading || isCompletedChallengesLoading
      )
    );
  }
}
