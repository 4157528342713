import {Injectable} from '@angular/core';
import {authCodeFlowConfig} from './auth-code-flow-config';
import {OAuthService} from 'angular-oauth2-oidc';
import {Subject} from 'rxjs';

interface Claims {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    //This is used to trigger an internal redirect from the login callback component after returning from identity server
    tokenReceived = new Subject<void>();

    constructor(private oauthService: OAuthService) {
        this.oauthService.configure(authCodeFlowConfig);
        this.oauthService.setupAutomaticSilentRefresh();
    }

    restoreLoginStateFromStorage() {
        return this.oauthService.loadDiscoveryDocumentAndTryLogin();
    }

    logIn() {
        this.oauthService.initCodeFlow();
    }

    isTokenValid() {
        return this.oauthService.hasValidAccessToken();
    }

    loadUserProfile() {
        return this.oauthService.loadUserProfile();
    }

    get authEvents$() {
        return this.oauthService.events;
    }

    getAccessToken() {
        return this.oauthService.getAccessToken();
    }

    getAuthorizationHeader() {
        return this.oauthService.authorizationHeader();
    }

    getClaims(): Claims {
        return this.oauthService.getIdentityClaims() as Claims;
    }

    getRoles(): string[] {
        const roles = this.oauthService.getIdentityClaims()
        return roles ? roles["role"] as string[] : [] as string[];
    }

    get isUserLoggedIn() {
        return this.oauthService.hasValidAccessToken();
    }

    async logOut() {
        console.log("[AuthService] Logging the user out");
        this.oauthService.stopAutomaticRefresh();
        //automaticRedirectAfterSignOut sends the use back to the angular app after logout from identity server
        await this.oauthService.revokeTokenAndLogout({automaticRedirectAfterSignOut: true}); //logs out only from app, not identity server
    }
}
