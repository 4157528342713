import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/common/store';
import {loadCompany, loadCompanyAdminInfo, loadCompanyUsers} from 'src/app/common/store/actions/company.actions';
import {Company, CompanyExtendedInfo} from '../models/company';
import {Employee} from "../models/employee";
import {DataFetchState} from "../models/fetch-state";

@Injectable({
  providedIn: 'root'
})

export class CompanyFacade {
  private _company$: Observable<DataFetchState<Company>>;
  private _companyInformation$: Observable<DataFetchState<CompanyExtendedInfo>>;
  private _companyUsers$: Observable<DataFetchState<Array<Employee>>>;

  constructor(private _store: Store<AppState>) {
    this._company$ = this._store.select(x => x.company.company);
    this._companyInformation$ = this._store.select(x => x.company.companyInformation);
    this._companyUsers$ = this._store.select(x => x.company.companyUsers);
  }

  get companyInformation$() {
    this._store.dispatch(loadCompanyAdminInfo());
    return this._companyInformation$;
  }

  getCompanyUsers(companyId: string) {
    this._store.dispatch(loadCompanyUsers({companyId}));
    return this._companyUsers$;
  }

  get company$() {
    this._store.dispatch(loadCompany());
    return this._company$;
  }
}
