import {Component, OnDestroy, OnInit} from '@angular/core';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import {SystemSettingsFacade} from "../../facades/system-settings.facade";
import {Observable} from "rxjs";
import {AsyncPipe, NgIf} from "@angular/common";
@Component({
    selector: 'dc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [MainMenuComponent, NgIf, AsyncPipe]
})

export class HeaderComponent {
        hideMenu: Observable<boolean>;
        constructor(private _systemSettingsFacade: SystemSettingsFacade) {
            this.hideMenu = this._systemSettingsFacade.hideMenu;
        }
}
