import { BarChartSeries } from 'src/app/models/statistics';
import { getBarChartOptions } from 'src/app/utils/highcharts-config';

export const getExtendedBarChartOptions = (
    chartConfig: Highcharts.Options,
    data: {
        title: string;
        categories: string[];
        series: BarChartSeries | BarChartSeries[];
        subtitle: string;
    }
): Highcharts.Options => {
    let config = { ...chartConfig };
    config = {
        ...getBarChartOptions(),
    };

    config.xAxis = {
        ...config.xAxis,
        categories: data.categories || [],
    };
    config.series =
        data.series instanceof Array ? data.series : [data.series as any];
    config.title = {
        ...config.title,
        text: data.title,
    };
    config.subtitle = {
        ...config.subtitle,
        text: data.subtitle,
    };
    config.yAxis = {
        ...config.yAxis,
        min: 0,
        // max: Math.max(...(data.series?.data || [1])),
    };

    return config;
};
