import { createAction, props } from '@ngrx/store';
import {
    ECompletedChallengesInterval,
    CompletedChallenges,
    PersonaTypeScore,
} from 'src/app/models/statistics';
import {HttpErrorResponse} from "@angular/common/http";

const LOAD_COMPLETED_CHALLENGES = '[Statistics] Load completed challenges';
const LOAD_COMPLETED_CHALLENGES_SUCCESS =
    '[Statistics] Load completed challenges success';
const LOAD_COMPLETED_CHALLENGES_ERROR =
    '[Statistics] Load completed challenges error';

export const loadCompletedChallenges = createAction(
    LOAD_COMPLETED_CHALLENGES,
    props<{
        from: string;
        to: string;
        interval: ECompletedChallengesInterval;
    }>()
);
export const loadCompletedChallengesSuccess = createAction(
    LOAD_COMPLETED_CHALLENGES_SUCCESS,
    props<{ completedChallenges: CompletedChallenges[] }>()
);
export const loadCompletedChallengesError = createAction(
    LOAD_COMPLETED_CHALLENGES_ERROR,
    props<{ error: HttpErrorResponse }>()
);

const LOAD_PERSONA_TYPES = '[Statistics] Load persona types';
const LOAD_PERSONA_TYPES_SUCCESS = '[Statistics] Load persona types success';
const LOAD_PERSONA_TYPES_ERROR = '[Statistics] Load persona types error';

export const loadPersonaTypes = createAction(LOAD_PERSONA_TYPES);
export const loadPersonaTypesSuccess = createAction(
    LOAD_PERSONA_TYPES_SUCCESS,
    props<{ personaTypes: PersonaTypeScore[] }>()
);
export const loadPersonaTypesError = createAction(
    LOAD_PERSONA_TYPES_ERROR,
    props<{ error: HttpErrorResponse }>()
);
