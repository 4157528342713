import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
export * from "./ui/dc-card-compact-actions.component"
export * from "./ui/dc-card-compact-content.component"
export * from "./ui/dc-card-compact-header.component"
export * from "./ui/dc-card-compact-title.component"


@Component({
    selector: 'dc-card-compact',
    templateUrl: './dc-card-compact.component.html',
    styleUrls: ['./dc-card-compact.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class DcCardCompactComponent {

}
