import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpService} from "../http/http.service";
import {Observable} from "rxjs";
import {StrapiDataList} from "../../models/strapi/StrapiContent";
import {
  ActiveTrainingProgramStageProgression,
  TrainingProgramPlaylist
} from "../../models/training-program-progression";
import {StrapiTrainingProgram} from "../../models/strapi-training-program";
import {catchError, tap} from "rxjs/operators";
import {Guid} from "../../models/types";

@Injectable({
  providedIn: 'root'
})
export class TrainingProgramService {
    private endpoint = `${environment.coachApiUrl}`;
    constructor(private http: HttpService) {}

    getRelevantTrainingPrograms(): Observable<Array<StrapiTrainingProgram>> {
        console.log("loading recommended programs")
        return this.http.get<Array<StrapiTrainingProgram>>(`${this.endpoint}/trainingprograms/me/relevant`, { withCredentials: true })
    }

    getActiveTrainingProgram(): Observable<ActiveTrainingProgramStageProgression> {
        return this.http.get<ActiveTrainingProgramStageProgression>(`${this.endpoint}/trainingprograms/me/active`, {withCredentials: true})
    }

    startTrainingProgram(trainingProgramId?: Guid): Observable<ActiveTrainingProgramStageProgression> {
        console.log("START TP", trainingProgramId);
        return this.http.post<ActiveTrainingProgramStageProgression>(
          `${this.endpoint}/trainingprograms/me/start`, {trainingProgramId: trainingProgramId}, {withCredentials: true}
        )
    }

/*    completeCurrentTrainingProgram(): Observable<TrainingProgramProgression> {
        //This might need to return an error if the user does not have a training program
        return this.http.put<TrainingProgramProgression>(`${this.endpoint}/me/current/complete`,null, {withCredentials: true});
    }*/
/*    cancelCurrentTrainingProgram(): Observable<TrainingProgramProgression> {
        //This might need to return an error if the user does not have a training program
        return this.http.put<TrainingProgramProgression>(`${this.endpoint}/me/current/cancel`,null, {withCredentials: true});
    }*/
}
