export const LocalstorageRepository = {
    GetObject: <T>(key: string): T | null => {
        const item = localStorage.getItem(key);
        try {
            return !!item ? JSON.parse(item) as T : null;
        } catch (e) {
            console.error(`Error fetching data from local storage with key ${key}`, e);
            return null;
        }
    },
    DeleteObject: (key: string): void => {
        localStorage.removeItem(key);
    },
    SetString: (key: string, value: string): void => {
        localStorage.setItem(key, value);
    },
    GetString: (key: string): string | null => {
        const item = localStorage.getItem(key);
        return !!item ? item as string : null;
    },
    SetObject: (key: string, data: any): void => {
        const stringified = JSON.stringify(data);
        localStorage.setItem(key, stringified);
    },
    ClearAllData: (): void => localStorage.clear(),
}
