import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

const base = `
  inline-flex items-center min-w-[120px]
  border border-olympia rounded-full
  font-sans font-semibold uppercase justify-center
  focus:outline-none focus:ring-2 focus:ring-offset-2
  disabled:opacity-50 disabled:focus:ring-0 disabled:active:ring-0`;

export const themes = ['primary', 'secondary', 'tertiary', 'ghost'] as const;
export type Theme = (typeof themes)[number];
const themeClasses: Record<Theme, string> = {
  primary: 'bg-zeus text-olympia hover:bg-zeus2 active:bg-zeus3 focus:bg-zeus3',
  secondary:
    'bg-apollo text-hades hover:bg-apollo2 active:bg-apollo3 focus:bg-apollo3',
  tertiary:
    'bg-theseus text-olympia hover:bg-theseus2 active:bg-theseus3 focus:bg-theseus3',
  ghost: 'border-0 p-0 underline',
};

export const sizes = ['base', 'lg'] as const;
export type Size = (typeof sizes)[number];
const sizeClasses: Record<Size, string> = {
  base: 'px-4 py-2 text-sm',
  lg: 'px-5 py-3 text-base',
};

const buildTwClasses = (currentTheme: Theme, size: Size): string =>
  `${base} ${sizeClasses[size]} ${themeClasses[currentTheme]}`;

type ButtonChanges = SimpleChanges & {
  theme: Theme;
  size: Size;
};

@Directive({
  selector: '[dcButton]',
  standalone: true,
})
export class ButtonDirective implements OnChanges {
  @Input() theme: Theme = 'primary';
  @Input() size: Size = 'base';

  @HostBinding('class')
  private class: string;
  constructor() {
    this.class = buildTwClasses(this.theme, this.size);
  }
  ngOnChanges(changes: ButtonChanges): void {
    if (changes.theme || changes.size) {
      this.class = buildTwClasses(this.theme, this.size);
    }
  }

  /*
        @Input()
        set theme(value: Theme) {
            this._theme = value;
        }

        private _size: Size = 'base';
        @Input()
        set size(value: Size) {
            this._size = value;
            this.twClasses = buildTwClasses(this._theme, this._size);
        }
    */
}
