import { createAction, props } from '@ngrx/store';
import { QuestionAnswer, Questionnaire, QuestionnaireResult } from 'src/app/models/questionnaire';
import {HttpErrorResponse} from "@angular/common/http";

export const dummyAction = createAction("[Questionnaire] no action (dummy)");

const LOAD_QUESTIONNAIRES           = "[Questionnaire] Load questionnaires";
const LOAD_QUESTIONNAIRES_IS_LOADING = "[Questionnaire] Load questionnaires is loading";
const LOAD_QUESTIONNAIRES_SUCCESS   = "[Questionnaire] Load questionnaires success";
const LOAD_QUESTIONNAIRES_FAILURE   = "[Questionnaire] Load questionnaires failure";

export const loadQuestionnaires = createAction(LOAD_QUESTIONNAIRES, props<{ reload?: boolean }>());
export const loadQuestionnairesIsLoading = createAction(LOAD_QUESTIONNAIRES_IS_LOADING);
export const loadQuestionnairesSuccess = createAction(LOAD_QUESTIONNAIRES_SUCCESS, props<{ questionnaires: Questionnaire[] }>());
export const loadQuestionnairesFailure = createAction(LOAD_QUESTIONNAIRES_FAILURE, props<{ error: HttpErrorResponse }>());
const SUBMIT_QUESTIONNAIRE          = "[Questionnaire] Submit";
const SUBMIT_QUESTIONNAIRE_SUCCESS  = "[Questionnaire] Submit success";

export const submitQuestionnaire = createAction(SUBMIT_QUESTIONNAIRE, props<{ questionnaireId: string, answers: QuestionAnswer[] }>());
export const submitQuestionnaireSuccess = createAction(SUBMIT_QUESTIONNAIRE_SUCCESS);
