import { Component } from "@angular/core";

@Component({
    selector: 'dc-card-actions',
    template: '<ng-content></ng-content>',
    styles: [`
        :host {
            grid-area: actions;
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    `],
    standalone: true
})
export class DcCardActionsComponent { }
