import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  	constructor(private _authService: AuthService, private _router: Router) {}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(this._authService.isTokenValid())
			return true;

		return this._router.navigate(["/401"])
	}
}
