import { Component, OnInit } from '@angular/core';
import { TrainingProgramFacade } from '../../facades/training-program.facade';
import { Observable } from 'rxjs';
import {
  ActiveTrainingProgramStageProgression,
} from '../../models/training-program-progression';
import {
  AsyncPipe,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgClass,
} from '@angular/common';
import {DataFetchState} from "../../models/fetch-state";

@Component({
  selector: 'dc-training-summary',
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, NgOptimizedImage, NgClass],
  templateUrl: './training-summary.component.html',
  styleUrl: './training-summary.component.scss',
})
export class TrainingSummaryComponent implements OnInit {
  currentTrainingProgram$: Observable<DataFetchState<ActiveTrainingProgramStageProgression>>;
  constructor(private _trainingProgramFacade: TrainingProgramFacade) {}

  ngOnInit(): void {
    this.currentTrainingProgram$ = this._trainingProgramFacade.currentTrainingProgramProgression$;
  }

  protected readonly Array = Array;
}
