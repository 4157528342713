import {createReducer, on} from '@ngrx/store';
import {SettingsState} from "../../../models/settings";
import {SettingsRepository} from "../../../utils/SettingsRepository";
import {pushSettingsChanged, pwaSettingsChanged, settingsChanged} from "../actions/settings.actions";

const ResolveInitialSettingsState = ():SettingsState => {
    return SettingsRepository.GetSettings();
}
export const settingsReducer = createReducer(ResolveInitialSettingsState(),
    on(settingsChanged, (state,{settings}) => {
        SettingsRepository.SetSettings(settings);
        return {...settings}
    }),
    on(pwaSettingsChanged, (state,{pwaSettings}) => {
        SettingsRepository.SetSettings({...state, pwaSettings});
        return {...state, pwaSettings}
    }),
    on(pushSettingsChanged, (state,{pushSettings}) => {
        SettingsRepository.SetSettings({...state, pushSettings});
        return {...state, pushSettings}
    })
);
