import {Component, Input} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'dc-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.scss'],
    standalone: true,
    imports: [MatProgressSpinnerModule]
})
export class ProgressIndicatorComponent {

    @Input()
    size: "large" | "medium" | "small" = "medium";


}
