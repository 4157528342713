import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {authError, authTokenReceived, logInFailure, logInSuccess} from "../common/store/actions/auth.actions";
import {AuthService} from "../services/auth/auth.service";
import {AppState} from "../common/store";
import {Store} from "@ngrx/store";
import {EMPTY, of} from "rxjs";

@Injectable()
export class AuthEffects {
    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _authService: AuthService
    ) {}

    authTokenReceived$ = createEffect(() => this._actions$.pipe(
            ofType(authTokenReceived),
            withLatestFrom(this._store$.pipe(map(store => store.auth.userClaims))),
            //TODO: Emit an event if the user claims is not present, otherwise do nothing
            switchMap(([,userClaims]) => {
                if(!userClaims) {
                    console.log("%c[Auth Effects]: authTokenReceived - loading userClaims", "color:cyan");
                    const user = this._authService.getClaims();
                    const roles = this._authService.getRoles();
                    return of(logInSuccess({
                        userClaims: {
                            email: user.email,
                            emailVerified: user.email_verified,
                            isSuperAdmin: roles?.includes('"DialeadSystemAdmin"') || true
                        }
                    }));
                } else {
                    console.log("%c[Auth Effects]: authTokenReceived - userClaims already loaded", "color:cyan");
                    return EMPTY;
                }
            })
        ),
    );

    loginSuccessLogging$ = createEffect(() => this._actions$.pipe(
            ofType(logInSuccess),
            tap(({userClaims}) => {
                console.log("%c[Auth Effects]: logInSuccess", "color:cyan");
            })
        ),
        {dispatch: false}
    );

    loginFailureLogging$ = createEffect(() => this._actions$.pipe(
            ofType(logInFailure),
            tap(({error}) => {
                console.log("%c[Auth Effects]: login failed","color:indianred");
            })
        ),
        {dispatch: false}
    );

    authErrorLogging$ = createEffect(() => this._actions$.pipe(
            ofType(authError),
            tap(({error}) => {
                console.log("%c[Auth Effects]: auth error","color:crimson");
            })
        ),
        {dispatch: false}
    );

}
