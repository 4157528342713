import { Guid } from './types';

export interface Questionnaire {
    id: string;
    title: string;
    shortDescription: string;
    description: string;
    questions: Question[];
    resultMessage:string;
}

export interface Question {
    id: number;
    text: string;
    type: QuestionType;
    availableAnswers: AnswerOption[];
}

export enum QuestionType {
    yesNo = 1,
    shortText = 2,
    longText = 3,
    multipleChoice = 4
}

export interface QuestionAnswer {
    questionId: number;
    questionType: QuestionType;
    boolAnswer?: boolean;
    answerId?: number;
    textAnswer?: string;
}

export interface AnswerOption {
    id: number;
    text: string;
}

type SavedQuestionAnswer = QuestionAnswer & {id: Guid};
export interface QuestionnaireResult {
    id: Guid;
    questionnaireId: string;
    profileId: Guid;
    finished: Date;
    answers: SavedQuestionAnswer[]
}
