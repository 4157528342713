import {createReducer, on} from '@ngrx/store';
import {Profile} from 'src/app/models/profile';
import {
  createProfileSuccess,
  loadProfileFailure, loadProfileIsLoading,
  loadProfileSuccess,
  profileNotRegistered,
  updateProfileFailure,
  updateProfileSuccess
} from '../actions/profile.actions';
import {DataFetchState} from "../../../models/fetch-state";

export interface ProfileState {
  isRegistrationNotFinished: boolean;
  profile: DataFetchState<Profile>;
}

export const initialState: ProfileState = {
  isRegistrationNotFinished: false,
  profile: {
    data: undefined,
    isLoading: false,
    error: null
  }
};

export const profileReducer = createReducer(initialState,
  on(loadProfileIsLoading, state => ({
    ...state,
    profile: {
      ...state.profile,
      isLoading: true
    }
  })),
  on(loadProfileSuccess, (state, {profile}) => ({
    ...state,
    profile: {
      data: profile,
      isLoading: false,
      error: null
    },
    isRegistrationNotFinished: false,
  })),
  on(loadProfileFailure, (state, action) => ({...state, error: action.error})),
  on(createProfileSuccess, (state, {profile}) => ({
    ...state,
    profile: {
      data: profile,
      isLoading: false,
      error: null
    },
    isRegistrationNotFinished: false,
  })),
  on(updateProfileSuccess, (state, {profile}) => ({
    ...state,
    profile: {
      data: profile,
      isLoading: false,
      error: null
    }
  })),
  on(updateProfileFailure, (state, {error}) => ({
    ...state,
    error: {
      data: {
        ...state.profile.data //if an update fails we keep the profile state
      },
      isLoading: false,
      error: error
    }
  })),
  on(profileNotRegistered,
    state => ({
      ...state,
      isRegistrationNotFinished: true
    }))
);
