import { Question, QuestionAnswer, QuestionType } from 'src/app/models/questionnaire';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PopulateList } from 'src/app/utils/animation';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgFor } from '@angular/common';
import { DcCardContentComponent } from '../../../../extended-components/dc-card/ui/dc-card-content.component';
import { DcCardComponent } from '../../../../extended-components/dc-card/dc-card.component';
import {ButtonDirective} from "../../../../directives/button.directive";
import {SimpleCardComponent} from "../../../../components/simple-card/simple-card.component";

@Component({
    selector: 'dc-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    animations: [PopulateList({ speed: 300 }, { speed: 200, stagger: 0 })],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DcCardComponent, DcCardContentComponent, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgFor, MatFormFieldModule, MatInputModule, FormsModule, TextFieldModule, ButtonDirective, SimpleCardComponent]
})

export class QuestionComponent {
    @Input() question: Question;
    @Input() answer: QuestionAnswer;
    @Input() progress: string;
    @Input() resultMessage?: string;
    @Output() onAnswerChange: EventEmitter<QuestionAnswer> = new EventEmitter();
    questionTypes = QuestionType;
    textAnswer = "";

    constructor() {
        console.log("QuestionComponent constructor");
        console.log({question: this.question, answer: this.answer, progress: this.progress, resultMessage: this.resultMessage});
    }

    onYesNo(answer: boolean){
        this.answer.boolAnswer = answer;
        this.onAnswerChange.emit(this.answer);
    }

    onOptionSelect(answerId: number){
        this.answer.answerId = answerId;
    }

    onNextQuestion(){
        this.onAnswerChange.emit(this.answer);
    }

    onInputChange(){
        this.onAnswerChange.emit(this.answer);
    }
}
