import {Component} from '@angular/core';

@Component({
	selector: 'dc-simple-card',
	standalone: true,
	imports: [],
	template: `<ng-content></ng-content>`,
	styles: [`
	  :host {
		display: block;
		padding: 1rem;
		border-radius: 20px;
    background-color: hsl(var(--color-olympia-3));
		box-shadow: 0 0 4px rgba(0, 0, 0, .1);
	  }
	`]
})
export class SimpleCardComponent {

}
