<main *ngIf="(currentPlaylist$ | async) as playlist" class="flex flex-col items-center gap-7 px-4 pt-4">
  <dc-spinner *ngIf="playlist.isLoading"></dc-spinner>
  <div *ngIf="playlist.data as playlistItems" class="audio-challenges flex flex-col gap-4 w-full mt-4">
    <div class="flex flex-col text-center items-center gap-2">
      <img alt="Playlist icon" width="143" height="143" [ngSrc]="getIcon(playlistItems.icon)"/>
      <h1 class="text-apollo font-serif font-normal">{{ playlistItems.trainingProgramName }}</h1>
    </div>
    <div *ngFor="let currentAudioChallenge of playlistItems.playlist"
      class="rounded-2xl py-6 px-5 flex justify-between w-full"
      [ngClass]="{'bg-zeus': currentAudioChallenge.isCompleted, 'bg-achilles': !currentAudioChallenge.isCompleted}">
      <div class="flex flex-col gap-0.5">
        <h2 class="text-lg">{{ currentAudioChallenge.text || currentAudioChallenge.title }}</h2>
        <p class="text-sm">{{currentAudioChallenge?.caption}}</p>
      </div>
      <div class="w-50">
        <button (click)="onPlayAudio(currentAudioChallenge)"
          class="w-[43px] h-[43px] bg-demeter2 flex-col flex rounded-full justify-center items-center">
          <img *ngIf="currentAudioChallenge.isCompleted" width="32" height="32" src="assets/icons/replay.svg"/>
          <img *ngIf="!currentAudioChallenge.isCompleted" width="22" height="22" src="assets/icons/play.svg"/>
        </button>
      </div>
    </div>
  </div>
  @if (currentPlaylistItem) {
    <dc-audio-player
      *ngIf="currentPlaylistItem"
      [title]="currentPlaylistItem.text || currentPlaylistItem.title"
      [description]="currentPlaylistItem.description"
      [icon]="currentPlaylistItem.icon"
      [audioUrl]="currentPlaylistItem.url"
      (playerClosed)="onCloseAudioPlayer()"
    >
    </dc-audio-player>
  }
</main>
