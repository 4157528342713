<ng-container *ngIf="isNotFound"> Questionnaire not found...</ng-container>
<div *ngIf="questionnaire" class="mb-40 flex flex-col px-3">
  <div class="pb-16 pt-8 flex justify-center">
    <dc-logo [size]="52"></dc-logo>
  </div>
  <dc-progress-bar
    [progress]="showResultMessage ? 100 : Math.round((questionIndex / questionnaire.questions.length) * 100)"
  ></dc-progress-bar>
  <dc-question
    *ngIf="questionnaire.questions.length > 0"
    class="question"
    [resultMessage]="showResultMessage ? questionnaire.resultMessage : undefined"
    [question]="questionnaire.questions[questionIndex]"
    [answer]="answers[questionIndex]"
    [progress]="progress"
    (onAnswerChange)="onAnswerChange($event)">
  </dc-question>
  <button dcButton
    *ngIf="showResultMessage && !isSubmiting"
    class="submit"
    (click)="onSubmit()"
    [title]="submitBtnTooltip"
    [disabled]="!isAllAnswered || isSubmiting">
    Send inn
  </button>
  <mat-spinner
    *ngIf="showResultMessage && isSubmiting"
    class="spinner"
    [diameter]="48"
  ></mat-spinner>
</div>
