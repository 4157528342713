<ng-container *ngIf="recommendedTrainingPrograms$ | async as suggestedTrainingPrograms">
  <dc-card
    class="recommended-training-program"
    *ngFor="let program of suggestedTrainingPrograms.data"
  >
    <dc-card-header>Programforslag</dc-card-header>
    <dc-card-title>{{ program.title }}</dc-card-title>
    <dc-card-content>{{ program.description }}</dc-card-content>
    <dc-card-actions>
      <button
        dcButton
        *appSpinnerDirective="startingTrainingProgramId === program.id"
        [disabled]="suggestedTrainingPrograms.isLoading"
        theme="primary" >
        Start treningen
      </button>
    </dc-card-actions>
  </dc-card>
</ng-container>
