<div>
  <button
    *ngFor="let ratingId of stars"
    (click)="onStarRating(ratingId)"
    class="bg-transparent min-h-max min-w-max p-2 focus:bg-transparent focus:ring-0 focus:ring-offset-0"
  >
    <img
      *ngIf="ratingId > rating"
      width="31"
      height="29"
      alt="grey-star-icon"
      src="assets/icons/grey-star.svg"
    />
    <img
      *ngIf="ratingId <= rating"
      width="31"
      height="29"
      alt="filled-star-icon"
      src="assets/icons/green-star.svg"
    />
  </button>
</div>
