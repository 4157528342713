import {createReducer, on} from '@ngrx/store';
import {Company, CompanyExtendedInfo} from 'src/app/models/company';
import {
  loadCompany, loadCompanyAdminInfo,
  loadCompanyAdminInfoError,
  loadCompanyAdminInfoSuccess,
  loadCompanyError,
  loadCompanySuccess, loadCompanyUsers, loadCompanyUsersError, loadCompanyUsersSuccess
} from '../actions/company.actions';
import {Employee} from "../../../models/employee";
import {DataFetchState} from "../../../models/fetch-state";

export interface CompanyState {
  company: DataFetchState<Company>;
  companyInformation: DataFetchState<CompanyExtendedInfo>;
  companyUsers: DataFetchState<Array<Employee>>;
}

export const initialState: CompanyState = {
  company: {
    data: undefined,
    isLoading: false,
    error: null
  },
  companyInformation: {
    data: undefined,
    isLoading: false,
    error: null
  },
  companyUsers: {
    data: undefined,
    isLoading: false,
    error: null
  }
};

export const companyReducer = createReducer(initialState,
  on(loadCompany, state => ({
    ...state,
    company: {
      ...state.company,
      isLoading: true
    }
  })),
  on(loadCompanySuccess, (state, {company}) => ({
    ...state,
    company: {
      data: company,
      isLoading: false,
      error: null
    }
  })),
  on(loadCompanyError, (state, {error}) => ({
    ...state,
    company: {
      data: undefined,
      isLoading: false,
      error: error
    }
  })),
  on(loadCompanyAdminInfo, state => ({...state, isCompanyInformationLoading: true})),
  on(loadCompanyAdminInfoSuccess, (state, {company}) => ({
    ...state,
    companyInformation: {
      data: company,
      isLoading: false,
      error: null
    }
  })),
  on(loadCompanyAdminInfoError, (state, {error}) => ({
    ...state,
    companyInformation: {
      data: undefined,
      isLoading: false,
      error: error
    }
  })),

  on(loadCompanyUsers, state => ({
    ...state,
    companyUsers: {
      ...state.companyUsers,
      isLoading: true
    }
  })),
  on(loadCompanyUsersSuccess, (state, {employees}) => ({
    ...state,
    companyUsers: {
      data: employees,
      isLoading: false,
      error: null
    }
  })),
  on(loadCompanyUsersError, (state, {error}) => ({
    ...state,
    companyUsers: {
      data: undefined,
      isLoading: false,
      error: error
    }
  })),
);
