<!-- <mat-card class="card p-5">
    <ng-content select="dc-card-header"></ng-content>
    <ng-content select="dc-card-title"></ng-content>
    <ng-content select="dc-card-content"></ng-content>
    <ng-content select="dc-card-actions"></ng-content>
    <ng-content select="[right-side]"></ng-content>
</mat-card> -->
<div class="card p-5">
    <ng-content select="dc-card-header"></ng-content>
    <ng-content select="dc-card-title"></ng-content>
    <ng-content select="dc-card-content"></ng-content>
    <ng-content select="dc-card-actions"></ng-content>
    <ng-content select="[right-side]"></ng-content>
</div>
