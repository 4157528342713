@if(profile$ | async; as profileRequest) {
  <dc-spinner *ngIf="profileRequest.isLoading"></dc-spinner>
  <div *ngIf="profileRequest.data as profile" class="flex justify-center items-center flex-col flex-nowrap">
    <dc-personal-bird></dc-personal-bird>
    <p class="subtitle-sans mt-2 mb-4">{{ profile.givenName }}</p>
  </div>
  <dc-onboarding *ngIf="!onboardingCompleted && (activeTrainingProgramProgression$ | async)?.data as trainingProgram"
    (completedOnboarding)="onCompleteOnboarding($event)"
    [trainingProgram]="trainingProgram.trainingProgram"
  />
  <dc-training-summary/>
  <div class="section-seperator"></div>
  <dc-greeting class="block mb-8"/>
}

<ng-container>
  <div class="content">
    <section *ngIf="isFinishingRegistrationRequired$ | async">
      <button mat-icon-button (click)="gotoProfile()">
        Fullfør registrering
      </button>
    </section>
    <section *ngIf="(profile$ | async)?.error as error">
      <dc-message-box [messageHeadline]="'Noe har gått galt'">
        Forsøk å laste siden på nytt eller kontakt administrator hvis det ikke
        løser seg.
      </dc-message-box>
    </section>
  </div>
  @if(activeTrainingProgramProgression$ | async; as activeTrainingProgram) {
    @if(activeTrainingProgram.data) {
      <dc-challenges></dc-challenges>
      <dc-questionnaires [showHeader]="false"></dc-questionnaires>
    }
    @else if(activeTrainingProgram.error) {
      <dc-recommended-training-programs></dc-recommended-training-programs>
    }
  }
</ng-container>
<!--<ng-container *ngIf="(frontpage | async) as frontpage">
	<dc-articles-list [articles]="frontpage.featuredArticles"></dc-articles-list>
</ng-container>-->
