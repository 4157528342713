import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { FormValidators } from 'src/app/utils/form.validator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {Profile, ProfileType} from 'src/app/models/profile';
import { ProfileFacade } from 'src/app/facades/profile.facade';
import { Router } from '@angular/router';
import { UserClaims } from '../../models/userClaims';
import { NgIf, NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';
import { ButtonDirective } from '../../directives/button.directive';
import { AuthFacade } from '../../facades/auth.facade';
import { TrainingSummaryComponent } from '../../components/training-summary/training-summary.component';
import { PersonalBirdComponent } from '../../components/personal-bird/personal-bird.component';
import {DataFetchState} from "../../models/fetch-state";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

@Component({
  selector: 'dc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AsyncPipe,
    DatePipe,
    ButtonDirective,
    TrainingSummaryComponent,
    PersonalBirdComponent,
    SpinnerComponent,
  ],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profile$: Observable<DataFetchState<Profile>>;
  user$: Observable<UserClaims | null>;
  editMode: boolean;
  editForm: FormGroup;
  subscription: Subscription;
  profile: Profile;

  constructor(
    private formBuilder: FormBuilder,
    private validators: FormValidators,
    private profileFacade: ProfileFacade,
    private authFacade: AuthFacade,
    private _route: Router
  ) {
    this.profile$ = this.profileFacade.profile$;
    this.user$ = this.authFacade.userClaims$;
    this.editForm = this.formBuilder.group({
      name: ['', this.validators.firstName],
      phone: ['', this.validators.phone],
    });
  }

  ngOnInit(): void {
    this.subscription = this.profile$.subscribe((profile) => {
      if (profile.data) {
        this.profile = profile.data;
        this.editForm.setValue({
          name: profile.data.givenName || '',
          phone: profile.data.phone || '',
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  startEdit(): void {
    this.editForm.setValue({
      name: this.profile?.givenName || '',
      phone: this.profile?.phone || '',
    });
    this.editMode = true;
  }

  cancel() {
    this.editMode = false;
  }

  saveChanges() {
    if (this.editForm.valid) {
      if (
        this.profile.givenName !==
        this.name?.value /* || this.profile.phone !== this.phone?.value*/
      ) {
        this.profileFacade.updateProfileValues({
          givenName: this.name?.value,
          /*,phone: this.phone?.value*/
        });
      }
    }
    this.editMode = false;
    this.editForm.reset();
  }

  async logOut(): Promise<void> {
    await this.authFacade.logOut();
  }
  navigateToSettings(): void {
    this._route.navigate(['/settings']);
  }
  navigateToCompanyAdministration(): void {
    this._route.navigate(['/company-administration']);
  }
  get name() {
    return this.editForm.get('name');
  }

  get phone() {
    return this.editForm.get('phone');
  }

  protected readonly ProfileType = ProfileType;
}
