import { Component } from '@angular/core';
import {SettingsFacade} from "../../facades/settings.facade";
import {PushNotificationsService} from "../../services/push-notifications/push-notifications.service";
import { NgIf, AsyncPipe } from '@angular/common';
import {ButtonDirective} from "../../directives/button.directive";

@Component({
    selector: 'dc-push-notifications-prompt',
    templateUrl: './push-notifications-prompt.component.html',
    styleUrls: ['./push-notifications-prompt.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, ButtonDirective]
})
export class PushNotificationsPromptComponent {
    settings$ = this._settingsFacade.settings$;
    constructor(private _settingsFacade: SettingsFacade, private notificationsService: PushNotificationsService) {
    }

    isPushSupported() {
        return this.notificationsService.isNotificationAvailable();
    }
    isPushEnabled() {
        return this.notificationsService.isNotificationsEnabled();
    }
    turnOnPushMessages() {
        console.debug("Turning on push messages");
        this.notificationsService.subscribe();
    }
}
