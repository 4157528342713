import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()

export class NotLoggedInGuard  {
  	constructor(private authService: AuthService, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(this.authService.isTokenValid()) {
            console.log("[NotLoggedInGuard] User is logged in.");
            return this.router.navigate(["/"])
        }
		else return true;
	}
}
