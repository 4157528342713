import {
  CompletedChallenges,
  PersonaTypeScore,
} from 'src/app/models/statistics';
import {createReducer, on} from '@ngrx/store';
import {DataFetchState} from "../../../models/fetch-state";
import {
  loadCompletedChallenges,
  loadCompletedChallengesError,
  loadCompletedChallengesSuccess,
  loadPersonaTypes, loadPersonaTypesError, loadPersonaTypesSuccess
} from "../actions/statistics.actions";

export interface StatisticsState {
  completedChallenges: DataFetchState<CompletedChallenges[]>;
  personaTypes: DataFetchState<PersonaTypeScore[]>;
}

export const initialState: StatisticsState = {
  completedChallenges: {
    data: undefined,
    isLoading: false,
    error: null
  },
  personaTypes: {
    data: undefined,
    isLoading: false,
    error: null
  },
};

export const statisticsReducer = createReducer<StatisticsState>(
  initialState,
  on(loadCompletedChallenges, (state) => ({
    ...state,
    completedChallenges: {
      ...state.completedChallenges,
      isLoading: true
    }
  })),
  on(loadCompletedChallengesSuccess, (state, {completedChallenges}) => ({
      ...state,
      completedChallenges: {
        data: completedChallenges,
        isLoading: false,
        error: null
      },
    })
  ),
  on(loadCompletedChallengesError, (state, {error}) => ({
    ...state,
    completedChallenges: {
      data: undefined,
      isLoading: false,
      error: error
    }
  })),
  on(loadPersonaTypes, (state) => ({
    ...state,
    personaTypes: {
      ...state.personaTypes,
      isLoading: true
    }
  })),
  on(loadPersonaTypesSuccess, (state, {personaTypes}) => ({
      ...state,
      personaTypes: {
        data: personaTypes,
        isLoading: false,
        error: null
      },
    })
  ),
  on(loadPersonaTypesError, (state, {error}) => ({
    ...state,
    personaTypes: {
      data: undefined,
      isLoading: false,
      error: error,
    }
  }))
);
