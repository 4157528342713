import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import {
    ECompletedChallengesInterval,
    CompletedChallenges,
    PersonaTypeScore,
} from 'src/app/models/statistics';
import {
    ICompletedChallengesResponse,
    IPersonaTypeScoresResponse,
} from './statistics.api-models';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    private endpoint = `${environment.coachApiUrl}/profiles/me/statistics`;

    constructor(private http: HttpService) { }

    // from & to date format(ISO 8601): "YYYY-MM-DD"
    getCompletedChallenges(
        from: string,
        to: string,
        interval: ECompletedChallengesInterval
    ): Observable<CompletedChallenges[]> {
        return this.http
            .get<ICompletedChallengesResponse>(
                `${this.endpoint}/challengesCompletions?startDate=${from}&endDate=${to}&interval=${interval}`,
                { withCredentials: true }
            )
            .pipe(
                map(({ challengesCompletions }) =>
                    challengesCompletions.sort((a, b) => {
                        if (a.startDate < b.startDate) {
                            return -1;
                        }

                        if (a.startDate > b.startDate) {
                            return 1;
                        }

                        return 0;
                    })
                )
            );
    }

    getPersonaTypeScores(): Observable<PersonaTypeScore[]> {
        return this.http
            .get<IPersonaTypeScoresResponse>(`${this.endpoint}/personaTypes`, {
                withCredentials: true,
            })
            .pipe(
                map(({ personaTypeProgressions }) => personaTypeProgressions)
            );
    }
}
