import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {fader} from './utils/animation';

import {environment} from 'src/environments/environment';
import {
    PushNotificationsPromptComponent
} from './components/push-notifications-prompt/push-notifications-prompt.component';
import {PwaInstallPromptComponent} from './components/pwa-install-prompt/pwa-install-prompt.component';
import {FooterComponent} from './components/footer/footer.component';
import {ContainerComponent} from './components/container/container.component';
import {HeaderComponent} from './components/header/header.component';
import {Observable, Subscription} from "rxjs";
import {AuthFacade} from "./facades/auth.facade";
import {MessageBoxComponent} from "./components/message-box/message-box.component";
import {AsyncPipe} from "@angular/common";

@Component({
    selector: 'dc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fader],
    standalone: true,
    imports: [
        HeaderComponent,
        ContainerComponent,
        RouterOutlet,
        FooterComponent,
        PwaInstallPromptComponent,
        PushNotificationsPromptComponent,
        MessageBoxComponent,
        AsyncPipe,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'gro-appen';
    authError: Observable<boolean>
    private _subscription: Subscription;
    prepareRoute(outlet: RouterOutlet) {
        return (
            outlet &&
            outlet.activatedRouteData &&
            outlet.activatedRouteData['animation']
        );
    }

    constructor(private _authFacade: AuthFacade) {
        if (!environment.production) {
            console.log('ENV_NAME', environment.envName);
            //console.log({environment});
        }
        this.authError = this._authFacade.authError$;

        this._subscription = this._authFacade.authError$.subscribe((error) => {
            if (error) {
                console.log({error});
            }
        });
    }

  ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
    ngOnInit(): void {
        this._authFacade.initializeAuthStateChangeListener();
        this._authFacade.tryRestoreLogin();
    }
}
