import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, catchError, switchMap, withLatestFrom, filter, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CmsService} from "../services/cms/cms.service";
import {
  loadFrontpage,
  loadFrontpageError,
  loadFrontpageIsLoading,
  loadFrontpageSuccess
} from "../common/store/actions/frontpage.actions";
import {AppState} from "../common/store";
import {Store} from "@ngrx/store";

@Injectable()
export class FrontpageEffects {

  constructor(
    private _store$: Store<AppState>,
    private _actions$: Actions,
    private _cmsService: CmsService
  ) {
  }

  tryLoadFrontpage$ = createEffect(() => this._actions$.pipe(
      ofType(loadFrontpage),
      withLatestFrom(this._store$.select(state => state.frontpage.frontpage)),
      filter(([_, {data, isLoading}]) => !data && !isLoading),
      mergeMap(() => of(loadFrontpageIsLoading()))
    )
  );
  loadFrontpage$ = createEffect(() => this._actions$.pipe(
      ofType(loadFrontpageIsLoading),
      mergeMap(() => this._cmsService.getFrontpage().pipe(
        map(cmsFrontpage => {
          return loadFrontpageSuccess({frontpage: cmsFrontpage.data.attributes})
        }),
        catchError(error => {
          console.error("[frontpage.effects]: LoadFrontpageError: ", error);
          return of(loadFrontpageError({error:error}));
        }))
      )
    )
  );
}
