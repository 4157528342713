import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PopulateList } from '../../utils/animation';
import { GreetingComponent } from '../../components/greeting/greeting.component';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ButtonDirective } from '../../directives/button.directive';
import { VisitorGreetingComponent } from '../../components/visitor-greeting/visitor-greeting.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AuthFacade } from '../../facades/auth.facade';
import { SpinnerComponent } from '../../components/spinner/spinner.component';

@Component({
  selector: 'dc-frontpage',
  animations: [PopulateList(undefined, null)],
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    GreetingComponent,
    NgTemplateOutlet,
    VisitorGreetingComponent,
    AsyncPipe,
    ButtonDirective,
    DashboardComponent,
    SpinnerComponent,
  ],
})
export class FrontpageComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  isLoggingIn: Observable<boolean>;

  constructor(private authFacade: AuthFacade) {}
  ngOnInit(): void {
    this.isLoggedIn = this.authFacade.isLoggedIn$;
    this.isLoggingIn = this.authFacade.isLoggingIn$;
  }
}
