import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {CmsService} from "../services/cms/cms.service";
import {ArticlesFacade} from "../facades/articles.facade";
import {loadArticle, loadArticleError, loadArticleSuccess} from "../common/store/actions/articles.actions";

@Injectable()
export class ArticlesEffects {

  constructor(
    private actions$: Actions,
    private cmsService: CmsService,
    private articlesFacade: ArticlesFacade,
    private router: Router
  ) {
  }

  loadArticle$ = createEffect(() => this.actions$.pipe(
    ofType(loadArticle),
    switchMap(({articleId}) => this.cmsService.getArticle(articleId).pipe(
      map(article => loadArticleSuccess({article})),
      catchError(error => {
        console.error(error);
        return of(loadArticleError(error));
      })
    ))
  ));
}
