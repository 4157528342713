import {AnswerOption, Questionnaire, QuestionType} from "src/app/models/questionnaire";
import {StrapiContentInformation, StrapiDataArray, StrapiPlainItem} from "src/app/models/strapi/StrapiContent";

type StrapiQuestionType =
  "questionnaire.question-multiple-choice"
  | "questionnaire.question-yes-no"
  | "questionnaire.question-short-text"
  | "questionnaire.question-long-text";

interface StrapiQuestion {
  id: number,
  __component: StrapiQuestionType,
  text: string,
  availableAnswers: AnswerOption[]
}

export interface StrapiQuestionnaire extends StrapiContentInformation {
  PID: string;
  title: string;
  shortDescription: string;
  description: string;
  questions: StrapiQuestion[];
  resultMessage: string;

}

const parseQuestionType = (type: StrapiQuestionType) => {
  switch (type) {
    case "questionnaire.question-multiple-choice":
      return QuestionType.multipleChoice;
    case "questionnaire.question-yes-no":
      return QuestionType.yesNo;
    case "questionnaire.question-short-text":
      return QuestionType.shortText;
    case "questionnaire.question-long-text":
      return QuestionType.longText;
    default:
      throw new Error("Failed to map '" + type + "' to any of supported QuestionTypes")
  }
}

export const mapFromStrapiQuestionnaire = (responseData: Array<StrapiPlainItem<StrapiQuestionnaire>>): Questionnaire[] => {
  // FIXME: why is this null?
  if (responseData === undefined || responseData === null || responseData.length === 0) {
    return [] as Questionnaire[]
  }
  const questionnaires: Questionnaire[] = [];
  for (const x of responseData) {
    try {
      questionnaires.push({
        id: x.attributes.PID,
        shortDescription: x.attributes.shortDescription,
        description: x.attributes.description,
        title: x.attributes.title,
        questions: x.attributes.questions.map(q => ({
          id: q.id,
          text: q.text,
          type: parseQuestionType(q.__component),
          availableAnswers: q.availableAnswers
        })),
        resultMessage: x.attributes.resultMessage
      });
    } catch (error) {
      console.error(error);
    }
  }
  return questionnaires;
}
