import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/common/store';
import {SettingsField, SettingsState} from "../models/settings";
import {pushSettingsChanged, pwaSettingsChanged, settingsChanged} from "../common/store/actions/settings.actions";

@Injectable({
    providedIn: 'root'
})

export class SettingsFacade {
	settings$: Observable<SettingsState>;
    constructor(private _store: Store<AppState>) {
		this.settings$ = this._store.select(x => x.settings);
    }
    setSettings(settings: SettingsState) {
        this._store.dispatch(settingsChanged({settings}));
    }
    setPushSettings(pushSettings: SettingsField) {
        this._store.dispatch(pushSettingsChanged({pushSettings}));
    }
    setPwaSettings(pwaSettings: SettingsField) {
        this._store.dispatch(pwaSettingsChanged({pwaSettings}));
    }
}
