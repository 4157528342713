import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/common/store';
import {loadQuestionnaires, submitQuestionnaire} from '../common/store/actions/questionnaires.actions';
import {QuestionAnswer, Questionnaire} from '../models/questionnaire';
import {DataFetchState} from "../models/fetch-state";

@Injectable({
  providedIn: 'root'
})

export class QuestionnairesFacade {
  private _questionnaires$: Observable<DataFetchState<Questionnaire[]>>;
  isQuestionnaireSubmitting$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>
  ) {
    this._questionnaires$ = this._store.select(x => x.questionnaires.questionnaires);
    this.isQuestionnaireSubmitting$ = this._store.select(x => x.questionnaires.isQuestionnaireSubmitting);
  }

  get questionnaires$() {
    this._store.dispatch(loadQuestionnaires({reload: false}));
    return this._questionnaires$;
  }

  submitQuestionnaire(questionnaireId: string, answers: QuestionAnswer[]) {
    this._store.dispatch(submitQuestionnaire({questionnaireId, answers}));
  }
}
