import { createAction, props } from '@ngrx/store';
import {Article} from "src/app/models/article";
import {StrapiContent} from "../../../models/strapi/StrapiContent";
import {HttpErrorResponse} from "@angular/common/http";

export const LOAD_ARTICLE           = "[Article] Load"
export const LOAD_ARTICLE_SUCCESS   = "[Article] Load success"
export const LOAD_ARTICLE_ERROR     = "[Article] Load error"

export const loadArticle = createAction(LOAD_ARTICLE, props<{articleId:string}>());
export const loadArticleSuccess = createAction(LOAD_ARTICLE_SUCCESS, props<{ article: StrapiContent<Article> }>())
export const loadArticleError = createAction(LOAD_ARTICLE_ERROR, props<{ error: HttpErrorResponse }>())
