<dc-header class="app-header"></dc-header>
<div [@routeAnimations]="prepareRoute(outlet)" class="app-content">
    <dc-container>
        <router-outlet #outlet="outlet"></router-outlet>
    </dc-container>
</div>
<!--<dc-message-box [open]="!!(authError | async)" [messageHeadline]="'Autentiseringsfeil'" [type]="'error'">
    <p>Noe gitt galt med sesjonen din. Vennligst last appen inn på nytt</p>
</dc-message-box>-->
<dc-footer class="app-footer"></dc-footer>
<!--<dc-pwa-install-prompt></dc-pwa-install-prompt>-->
<!--<dc-push-notifications-prompt></dc-push-notifications-prompt>-->
