import {Component} from "@angular/core";
import {NgForOf} from "@angular/common";
import {SimpleCardComponent} from "../simple-card/simple-card.component";
import {SimpleModalComponent} from "../simple-modal/simple-modal.component";
import {birdIcons} from "../../models/birds";

interface BirdOption {
    icon:string;
}


@Component({
  selector: 'dc-personal-bird-selector',
  standalone: true,
    imports: [
        NgForOf,
        SimpleCardComponent,
        SimpleModalComponent
    ],
  templateUrl: './personal-bird-selector.component.html',
  styleUrl: './personal-bird-selector.component.scss'
})
export class PersonalBirdSelectorComponent {
    birdOptions: Array<BirdOption> = Object.entries(birdIcons)
        .filter(([key,value]) => key !== "default") //Filter out the default bird
        .map(([key, value]) => ({icon: value, id:key}));
}
