import { RouterModule, Routes } from '@angular/router';

import { AdminSettingsComponent } from './pages/admin-settings/admin-settings.component';
import { ArticleComponent } from './pages/article/article.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { ChallengesComponent } from './pages/challenges/challenges.component';
import { CommonModule } from '@angular/common';
import { CompanyAdministrationComponent } from './pages/company-administration/company-administration.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FinishedRegistrationGuard } from './guards/finished-registration/finished-registration.guard';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { LoginCallbackComponent } from './components/login-callback.component';
import { NgModule } from '@angular/core';
import { NotLoggedInGuard } from './guards/not-logged-in/not-logged-in.guard';
import { PageNotFoundComponentComponent } from './pages/page-not-found-component/page-not-found-component.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PulseComponent } from './pages/pulse/pulse.component';
import { QuestionnaireComponent } from './pages/questionnaires/questionnaire/questionnaire.component';
import { QuestionnairesComponent } from './pages/questionnaires/questionnaires.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { environment } from 'src/environments/environment';
import { SignupComponent } from './pages/signup/signup.component';
import { PlaylistComponent } from './pages/playlist/playlist.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

//animation- defines item idx in nav menu
const routes: Routes = [
  { path: '', component: FrontpageComponent, data: { animation: 0 } },
  { path: 'login-callback', component: LoginCallbackComponent },
  {
    path: 'register',
    component: RedirectComponent,
    data: { externalUrl: `${environment.authUrl}/identity/register.htm` },
  }, //sign up with auth provider
  { path: 'signUp', component: SignupComponent, canActivate: [AuthGuard] }, //finish creating profile
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard, FinishedRegistrationGuard],
    data: { animation: 3 },
  },
  { path: 'challenges', component: ChallengesComponent },
  {
    path: 'playlist',
    component: PlaylistComponent,
    data: { animation: 1 },
  },
  {
    path: 'questionnaires',
    component: QuestionnairesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'questionnaires/:id',
    component: QuestionnaireComponent,
    canActivate: [AuthGuard],
  },
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'company-administration',
    component: CompanyAdministrationComponent,
  },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'articles', component: ArticleComponent },
  { path: 'articles/:id', component: ArticleComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'pulse', component: PulseComponent, data: { animation: 2 } },
  {
    path: 'settings',
    component: AdminSettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', component: PageNotFoundComponentComponent }, // Wildcard route for a 404 page
  // { path: '', component: },
];

@NgModule({
  declarations: [],
  imports: [
    [RouterModule.forRoot(routes)],
    StoreRouterConnectingModule.forRoot(),
    CommonModule,
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
