<div class="absolute top-0 right-0 left-0 bottom-0">
    <dc-simple-modal>
        <h1 class="text-hades text-xl text-center my-3">Velg en fugl!</h1>
        <div class="flex flex-wrap m-auto">
            <div class="w-1/3 p-1" *ngFor="let option of birdOptions">
                <img class="w-16 h-16" src="{{option.icon}}" alt="Bird icon">
            </div>
        </div>
    </dc-simple-modal>
</div>

