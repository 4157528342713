import { createAction, props } from '@ngrx/store';
import { ProfileUpdate, BasicProfile, Profile } from 'src/app/models/profile'

const LOAD_PROFILE_IS_LOADING    = "[Profile] Load is loading";
const LOAD_PROFILE_SUCCESS      = "[Profile] Load success"
const LOAD_PROFILE_FAILURE      = "[Profile] Load failure"
const PROFILE_NOT_REGISTERED    = "[Profile] Not registered"
const CREATE_PROFILE            = "[Profile] create"
const CREATE_PROFILE_SUCCESS    = "[Profile] create success"
const CREATE_PROFILE_FAILURE    = "[Profile] create failure"
const UPDATE_PROFILE            = "[Profile] update"
const UPDATE_PROFILE_SUCCESS    = "[Profile] update success"
const UPDATE_PROFILE_FAILURE    = "[Profile] update failure"

export const loadProfileIsLoading = createAction(LOAD_PROFILE_IS_LOADING);
export const loadProfileSuccess = createAction(LOAD_PROFILE_SUCCESS, props<{ profile: Profile }>());
export const loadProfileFailure = createAction(LOAD_PROFILE_FAILURE, props<{ error: any }>());
export const profileNotRegistered = createAction(PROFILE_NOT_REGISTERED);

export const createProfile = createAction(CREATE_PROFILE, props<{ profile: BasicProfile }>());
export const createProfileSuccess = createAction(CREATE_PROFILE_SUCCESS, props<{ profile: Profile }>());
export const createProfileFailure = createAction(CREATE_PROFILE_FAILURE, props<{ error: any }>());

export const updateProfile = createAction(UPDATE_PROFILE, props<{ changes: ProfileUpdate }>());
export const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS, props<{ profile: Profile }>());
export const updateProfileFailure = createAction(UPDATE_PROFILE_FAILURE, props<{ error: any }>());

