import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'dc-form-input',
    templateUrl: './dc-form-input.component.html',
    styleUrls: ['./dc-form-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, NgIf, MatIconModule]
})
export class DcFormInputComponent implements OnInit {
    @Input() label: string;
    @Input() placeholder: string | null;
    @Input() matIcon: string | null;
    @Input() hint: string | null;
    @Input() getError: () => string;
    @Input() controlName: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
    }
}
