import * as Highcharts from 'highcharts';

import {Component, OnInit} from '@angular/core';

import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HC_exporting from 'highcharts/modules/exporting';
import HC_more from 'highcharts/highcharts-more';
import {StatisticsFacade} from 'src/app/facades/statistics.facade';
import {PersonaTypeScore} from 'src/app/models/statistics';
import {Observable} from 'rxjs';
import {BarChartComponent} from '../../components/bar-chart/bar-chart.component';
import {DcCardContentComponent} from '../../extended-components/dc-card/ui/dc-card-content.component';
import {DcCardHeaderComponent} from '../../extended-components/dc-card/ui/dc-card-header.component';
import {DcCardComponent} from '../../extended-components/dc-card/dc-card.component';
import {ProgressIndicatorComponent} from '../../components/progress-indicator/progress-indicator.component';
import {AsyncPipe, NgIf} from '@angular/common';
import {HighchartsChartModule} from "highcharts-angular";
import {DataFetchState} from "../../models/fetch-state";
import {ActiveTrainingProgramStageProgression} from "../../models/training-program-progression";
import {TrainingProgramFacade} from "../../facades/training-program.facade";
import {ButtonDirective} from "../../directives/button.directive";
import {Router} from "@angular/router";
import {ProfileFacade} from "../../facades/profile.facade";
import {Profile} from "../../models/profile";
import {PersonalBirdComponent} from "../../components/personal-bird/personal-bird.component";

HC_exporting(Highcharts);
HC_more(Highcharts);
HCSoldGauge(Highcharts);

const personaTypeNames = [
  'Skyldneren',
  'Impulskontrolløren',
  'Ødeleggeren',
  'Formeren',
  'Arbeidsjern',
  'Undergraveren',
  'Perfeksjonisten'
] as const;

const personaTypes = [
  'guilt tripper',
  'inner controller',
  'destroyer',
  'molder',
  'taskmaster',
  'underminer',
  'perfectionist'
] as const;
type personaType = (typeof personaTypes)[number];

const personaTypeMap: Record<personaType, string> = {
  'guilt tripper': 'Skyldneren',
  'inner controller': 'Impulskontrolløren',
  'destroyer': 'Ødeleggeren',
  'taskmaster': 'Arbeidsjern',
  'underminer': 'Undergraveren',
  'perfectionist': 'Perfeksjonisten',
  'molder': 'Formeren',
}

@Component({
  selector: 'dc-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgressIndicatorComponent,
    DcCardComponent,
    DcCardHeaderComponent,
    DcCardContentComponent,
    BarChartComponent,
    AsyncPipe,
    HighchartsChartModule,
    ButtonDirective,
    PersonalBirdComponent,
  ],
})
export class PulseComponent implements OnInit {
  personaTypes$: Observable<DataFetchState<PersonaTypeScore[]>>;
  profile$: Observable<DataFetchState<Profile>>;
  currentTrainingProgram$: Observable<DataFetchState<ActiveTrainingProgramStageProgression>>;

  constructor(
    private _router: Router,
    private _trainingProgramFacade: TrainingProgramFacade,
    private statisticsFacade: StatisticsFacade,
    private _profileFacade: ProfileFacade,
  ) {
  }
  public Highcharts: any = Highcharts; // required
  public chartConstructor: string = 'chart'; // optional string, defaults to 'chart'

  public chartOptions: Observable<any>;

  ngOnInit(): void {
    this.currentTrainingProgram$ = this._trainingProgramFacade.currentTrainingProgramProgression$;
    this.statisticsFacade.loadPersonaTypes();
    this.profile$ = this._profileFacade.profile$;
    this.personaTypes$ = this.statisticsFacade.personaTypes$;
    this.chartOptions = new Observable<any>(obs => {
      this.personaTypes$.subscribe(personaTypes => {
        if (personaTypes) {
          const options = this.progressTracking;
          if (personaTypes.data) {
            options.series = [
              {
                name: 'Nå',
                data: personaTypes.data.map(personaType => personaType.currentScore), //This needs to be changed to make sure we select the right persona type according to the order in the personaTypeNames array
                pointPlacement: 'on',
                type: "area",
              }/*,
              {
                name: 'Før',
                data: personaTypes.data.map(personaType => personaType.previousScore), //This needs to be changed to make sure we select the right persona type according to the order in the personaTypeNames array
                pointPlacement: 'on',
                type: "area",
              }*/
            ]
            obs.next(options);
          }
        }
      })
      return obs;
    })
  }
  goToProfile() {
    return this._router.navigate(["/profile"])
  }
  chartCallback(chart: Highcharts.Chart): void {
    setTimeout(() => {
      chart.reflow();
    }, 0);
  }

  private progressTracking: any = {
    chart: {
      //backgroundColor: 'transparent',
      polar: true,
      type: 'line',
    },

    title: {
      text: null,
      style: {
        color: '#f8f8f8'
      }
    },
    pane: {
      size: '50%',
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      categories: personaTypeNames,
      gridLineColor: 'transparent',
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          color: '#12525A',
          textDecoration: 'underline',
          fontSize: '12px'
        }
      }
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
        color: 'hsl(44, 90%, 52%)',
      }
    },
    yAxis: {
      tickAmount: 5,
      gridLineInterpolation: 'polygon',
      gridLineColor: '#12525A',
      lineWidth: 0,
      min: 0,
      labels: {
        enabled: false,
      },
    },

    tooltip: {
      shared: false,
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>${point.y:,.0f}</b><br/>',
    },
    legend: {
      enabled: false,
      align: 'top',
      verticalAlign: 'top',
      layout: 'vertical',
      itemStyle: {
        //color: '#f8f8f8'
      },

    }
  };
}
