<div
  class="flex flex-wrap items-top align-top justify-between gap-3 max-w-md m-auto"
  *ngIf="(currentTrainingProgram$ | async)?.data as currentProgram"
>
  <div class="flex flex-col gap-2">
    <div>
      <p class="subtitle-sans mb-1">
        {{ currentProgram.trainingProgram.title }}
      </p>
      <p class="uppercase text-apollo text-xl">
        {{ currentProgram.currentStage.name }}
      </p>
    </div>
    <div>
      <p
        class="subtitle-sans mb-1"
        *ngIf="
          currentProgram.currentStage.challenges.length > 0
        "
      >
        Fullførte øvelser
      </p>
      <div class="flex flex-nowrap gap-2">
        <div
          class="size-6 rounded-full flex items-center justify-center"
          [ngClass]="{
            'bg-hercules':
              item >= currentProgram.completedChallenges.length,
            'bg-demeter':
              item < currentProgram.completedChallenges.length
          }"
          *ngFor="
            let item of Array.from(
              Array(
                currentProgram.currentStage.challenges
              ).keys()
            )
          "
        >
          <p
            class="text-lg text-achilles"
            *ngIf="item >= currentProgram.completedChallenges.length"
          >
            {{ item + 1 }}
          </p>
          <div
            *ngIf="item < currentProgram.completedChallenges.length"
          >
            <img
              class="mt-0.5"
              ngSrc="assets/icons/white_check.svg"
              alt="White checkmark"
              width="14"
              height="14"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-2">
    <div
      *ngIf="(daysActive$ | async) as active"
    >
      <p class="subtitle-sans mb-1">DAGER PÅ RAD</p>
      <div class="flex w-auto">
        <p class="uppercase text-apollo text-xl inline" > {{ active.data !== null && active.data !== undefined ? active.data : '-' }}</p>
        <ng-container *ngIf="active.data !== null && active.data !== undefined && active.data >= 3">
          <img
            width="15"
            height="20"
            alt="Flame streak icon"
            src="assets/icons/fire.png"
            class="relative left-1"
          />
        </ng-container>
      </div>
    </div>

    <!--
      TODO if all tasks are complete, the text should be "I MORGEN"
      TODO if next task is ready, the text should be "NÅ"
      TODO if user has to wait for the next task, show time until next task

      .. also remove the 'true ||' below - it was added to make sure the text is displayed
  -->
    <div
      *ngIf="
        (currentProgram.currentStage.challenges.length > 0 &&
          currentProgram.completedChallenges.length >=
            currentProgram.currentStage.challenges.length)
      "
    >
      <p class="subtitle-sans mb-1">Neste øvelse</p>
      <p class="subtitle-sans text-apollo">
        <span class="text-xl mr-0.5">3</span> timer
        <span class="text-xl ml-0.5 mr-0.5">49</span> min
      </p>
    </div>
  </div>
</div>
