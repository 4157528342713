import { createAction, props } from '@ngrx/store';
import {Frontpage} from "src/app/models/pages/frontpage";
import {HttpErrorResponse} from "@angular/common/http";

export const LOAD_FRONTPAGE           = "[Frontpage] Load"
export const LOAD_FRONTPAGE_IS_LOADING = "[Frontpage] Load is loading"
export const LOAD_FRONTPAGE_SUCCESS   = "[Frontpage] Load success"
export const LOAD_FRONTPAGE_ERROR     = "[Frontpage] Load error"

export const loadFrontpage = createAction(LOAD_FRONTPAGE);
export const loadFrontpageIsLoading = createAction(LOAD_FRONTPAGE_IS_LOADING);
export const loadFrontpageSuccess = createAction(LOAD_FRONTPAGE_SUCCESS, props<{ frontpage: Frontpage }>())
export const loadFrontpageError = createAction(LOAD_FRONTPAGE_ERROR, props<{ error: HttpErrorResponse }>())
