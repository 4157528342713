<ng-container *ngIf="isLoggingIn | async; else content">
  <dc-spinner></dc-spinner>
</ng-container>
<ng-template #content>
  <ng-container *ngIf="isLoggedIn | async; else login">
    <dc-dashboard></dc-dashboard>
  </ng-container>
</ng-template>
<ng-template #login>
  <ng-container>
    <dc-visitor-greeting></dc-visitor-greeting>
  </ng-container>
</ng-template>
