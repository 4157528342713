import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface HttpOptions {
    withCredentials: boolean
}

@Injectable({providedIn: 'root'})
export class HttpService {

	constructor(private http: HttpClient) {}

	get<T>(endpoint: string, options?: HttpOptions): Observable<T>{
		return this.http.get<T>(endpoint, options);
	}

	post<T>(endpoint: string, body: any, options?: HttpOptions): Observable<T>{
		return this.http.post<T>(
			endpoint,
			body,
            options
		);
	}

	put<T>(endpoint: string, body: any, options?: HttpOptions): Observable<T>{
		return this.http.put<T>(
			endpoint,
			body,
            options
		);
	}

    delete<T>(endpoint: string, options?: HttpOptions): Observable<T> {
        return this.http.delete(
            endpoint,
            options
        ) as Observable<T>;
    }
}

