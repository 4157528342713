import  {Component} from '@angular/core';
import {Observable} from "rxjs";
import {PushNotificationsService} from "../../services/push-notifications/push-notifications.service";
import {AdminSettingsFacade} from "../../facades/admin-settings.facade";
import {AdminSettingsInfo, AdminSettingsState, availableAdminSettings} from "../../models/admin-settings";
import {SettingsFacade} from "../../facades/settings.facade";
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import {ButtonDirective} from "../../directives/button.directive";
import {PersonalBirdSelectorComponent} from "../../components/personal-bird-selector/personal-bird-selector.component";
import {AuthFacade} from "../../facades/auth.facade";

@Component({
    selector: 'dc-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, AsyncPipe, ButtonDirective, PersonalBirdSelectorComponent]
})
export class AdminSettingsComponent {
    adminSettings$: Observable<AdminSettingsState>;
    showBirds: boolean = false;
    protected readonly Info = AdminSettingsInfo;

    constructor(private _authFacade: AuthFacade,private _settingsFacade: SettingsFacade, private _adminSettingsFacade: AdminSettingsFacade, private notificationsService: PushNotificationsService) {
        this.adminSettings$ = this._adminSettingsFacade.adminSettings;
    }

    toggleProperty(settings: AdminSettingsState, property: availableAdminSettings) {
        this._adminSettingsFacade.setSettings({
            ...settings,
            [property]: !settings[property]
        });
    }

    sendDebugPushMessage() {
        this.notificationsService.sendDebugPushMessage();
    }

    isPushSupported() {
        return this.notificationsService.isNotificationAvailable();
    }

    isPushEnabled() {
        return this.notificationsService.isNotificationsEnabled();
    }

    togglePush() {
        this.notificationsService.isNotificationsEnabled() ? this.notificationsService.unsubscribe() :
            this.notificationsService.subscribe();
    }

    turnOnPush() {
        this.notificationsService.subscribe();
    }

    resetPwaPromptSetting() {
        this._settingsFacade.setPwaSettings({
            show: true,
            timeOfLastPrompt: new Date()
        });
    }
    showBirdSelector() {
        this.showBirds = true;
    }
    sendClientSidePushNotification() {
        console.log("Sending frontend dummy notification");
        var notification = new Notification('Test', {
            body: 'This is a test notification',
            icon: 'assets/logos/Fugl01_Gul.svg',
            tag: 'test'
        });
        notification.onerror = (e) => {
            console.error(e);
        }
    }

    throwAuthError() {
        this._authFacade.throwAuthError();
    }
}
