import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { createSelector, select, Store } from '@ngrx/store';
import { AppState } from 'src/app/common/store';
import {ProfileFacade} from "../../facades/profile.facade";


const authStatus = createSelector(
    (state: AppState) => state.profile.profile,
    (state: AppState) => state.auth.error,
    (profile, error) => ({profile, error})
)

@Injectable({ providedIn: 'root' })
export class FinishedRegistrationGuard  {

    constructor(private _router: Router, private _authService: AuthService, private _store: Store<AppState>){}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Observable<boolean>(obs => {
            if(this._authService.isTokenValid()){
                this._store.select(x => x.profile.isRegistrationNotFinished).subscribe(notFinished => {
                    if(notFinished){
                        this._router.navigate(["/signUp"]);
                    } else obs.next(true);
                })
            } else obs.next(false);
        })
    }
}
