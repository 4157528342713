import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'dc-star-rating',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.scss'
})
export class StarRatingComponent implements OnInit {
  @Input() starCount = 5;
  @Input() rating = 0;
  @Output() private ratingUpdated = new EventEmitter<number>();

  public stars: number[] = [];

  ngOnInit() {
    for (let i = 1; i <= this.starCount; i ++) {
      this.stars.push(i);
    }
  }

  onStarRating(ratingId: number) {
    this.rating = ratingId;
    this.ratingUpdated.emit(this.rating);
  }
}
