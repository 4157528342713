import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {ButtonDirective} from '../../../directives/button.directive';
import {Challenge, ChallengeProgression, ChallengeProgressionState} from 'src/app/models/challenge';
import {SpinnerDirective} from '../../../directives/spinner.directive';
import {NgClass, NgIf, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'dc-challenge-card',
  templateUrl: './challenge-card.component.html',
  styleUrls: ['./challenge-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SpinnerDirective,
    ButtonDirective,
    NgOptimizedImage,
    NgClass,
    NgIf,
  ],
})
export class ChallengeCardComponent {
  @Input() challenge: Challenge;
  @Input() index: number;

  constructor() {
  }

  protected readonly ChallengeProgressionState = ChallengeProgressionState;
}
