import {Component, Input} from '@angular/core';

@Component({
  selector: 'dc-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {

    @Input() progress: number;

}
