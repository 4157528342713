import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, catchError, mergeMap, withLatestFrom, filter, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  loadCompany,
  loadCompanyAdminInfo, loadCompanyAdminInfoError, loadCompanyAdminInfoSuccess,
  loadCompanyError, loadCompanyIsLoading, loadCompanyIsLoadingAdminInfo, loadCompanyIsLoadingUsers,
  loadCompanySuccess, loadCompanyUsers, loadCompanyUsersError, loadCompanyUsersSuccess
} from '../common/store/actions/company.actions';
import {CompanyService} from 'src/app/services/company/company.service';
import {logInSuccess} from '../common/store/actions/auth.actions';
import {AppState} from "../common/store";
import {Store} from "@ngrx/store";

@Injectable()

export class CompanyEffects {

  constructor(
    private _store$: Store<AppState>,
    private _actions$: Actions,
    private _companyService: CompanyService,
  ) {
  }

  tryLoadCompany = createEffect(() => this._actions$.pipe(
    ofType(logInSuccess),
    withLatestFrom(this._store$.select(state => state.company.companyInformation)),
    filter(([_, {data, isLoading}]) => !data && !isLoading),
    mergeMap(() => of(loadCompanyIsLoading()))
  ))

  loadCompany$ = createEffect(() => this._actions$.pipe(
    ofType(loadCompanyIsLoading),
    withLatestFrom(this._store$.select(state => state.auth.userClaims)),
    filter(([_,userClaims]) => !userClaims),
    mergeMap(([_,userClaims]) => {
      return this._companyService.getCompanyByEmail(userClaims!.email).pipe(
        map(company => loadCompanySuccess({company})),
        catchError((error) => of(loadCompanyError({error:error})))
      )
    })
  ));
  tryLoadAdminCompanyInformation$ = createEffect(() => this._actions$.pipe(
    ofType(loadCompanyAdminInfo),
    withLatestFrom(this._store$.select(state => state.company.companyInformation)),
    filter(([_, {data, isLoading}]) => !data && !isLoading),
    mergeMap(() => of(loadCompanyIsLoadingAdminInfo())
    )));

  loadAdminCompanyInformation$ = createEffect(() => this._actions$.pipe(
    ofType(loadCompanyIsLoadingAdminInfo),
    mergeMap(() => {
      return this._companyService.getCompanyInformationByLogin().pipe(
        map(company => loadCompanyAdminInfoSuccess({company})),
        catchError(error => of(loadCompanyAdminInfoError({error:error})))
      )
    })));

  tryLoadCompanyUsers$ = createEffect(() => this._actions$.pipe(
    ofType(loadCompanyUsers),
    withLatestFrom(this._store$.select(state => state.company.companyUsers)),
    filter(([_, {data, isLoading}]) => !data && !isLoading),
    mergeMap(() => of(loadCompanyIsLoadingUsers())
    )));
  loadCompanyUsers$ = createEffect(() => this._actions$.pipe(
    ofType(loadCompanyIsLoadingUsers),
    withLatestFrom(this._store$.select(state => state.company.company.data?.id)),
    filter(([_,companyId]) => !!companyId),
    mergeMap(([_,companyId]) => {
      return this._companyService.getCompanyUsersByCompanyId(companyId!).pipe(
        map(employees => loadCompanyUsersSuccess({employees})),
        catchError(error => of(loadCompanyUsersError({error: error})))
      )
    })));
}
