import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { lib } from 'crypto-js';

@Injectable()
export class TraceContextInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const traceParentValue = this.getTraceParentHeaderValue();

    // skip strapi request for adding trace context
    // skip requests to 1337 as it is the default port for strapi development server
    if (request.url.includes('strapi') || request.url.includes('1337')) {
      return next.handle(request);
    }

    // create new request object with trace context for all remaining requests
    const reqWithTraceContext = request.clone({
      headers: request.headers.set('traceparent', traceParentValue)
    });

    return next.handle(reqWithTraceContext);
  }

  getTraceParentHeaderValue(): string {
    const version = '00';
    const traceId = lib.WordArray.random(16).toString();
    const id = lib.WordArray.random(8).toString();
    const flags = '01';

    return `${version}-${traceId}-${id}-${flags}`
  }
}
