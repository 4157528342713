import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dc-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true
})
export class LogoComponent{

    constructor() { }

    @Input() size: number = 40;
}
