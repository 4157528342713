import {Guid} from "./types";

export interface Profile {
    id: Guid;
    createdDate: Date;
    email: string;
    departmentId: string; // guid
    givenName: string;
    familyName: string;
    phone: string;
    type:ProfileType;
    birthDate: Date;
    companyId?:string;
    lastLogin?:Date
}

export enum ProfileType {
  User,
  Admin,
}

export type BasicProfile = Pick<Profile, 'givenName' | 'familyName' | 'phone' | 'departmentId' | 'birthDate'>;

export type ProfileUpdate = Partial<Pick<Profile, 'givenName' | 'phone'>>;
