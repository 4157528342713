export enum ECompletedChallengesInterval {
    DAY = 1,
    WEEK = 2,
    MONTH = 3,
}

export interface CompletedChallenges {
    startDate: string;
    endDate: string;
    completedChallenges: number;
}

export interface BarChartSeries {
    name: string;
    data: number[];
    color?: string;
}

const personaTypes = [
    'guilt tripper',
    'inner controller',
    'destroyer',
    'molder',
    'taskmaster',
    'underminer',
    'perfectionist',
] as const;

type PersonaType = (typeof personaTypes)[number];
export const PersonaTexts: Record<PersonaType, string> = {
  'guilt tripper': 'Fylt med skam og "Jeg bør"',
  'inner controller': 'Jeg må kontrollere alt',
  'destroyer': 'Ødeleggeren',
  'molder': 'Jeg må passe inn',
  'taskmaster': 'Jobb,jobb, det tar aldri slutt',
  'underminer': 'Jeg tar ingen sjanser',
  'perfectionist': 'Perfeksjonist',
};

export interface PersonaTypeScore {
    personaType: PersonaType;
    currentScore: number;
    previousScore: number;
}
