<!-- <mat-card class="compact-card">
    <ng-content select="dc-card-compact-header"></ng-content>
    <div class="padded-content">
        <ng-content select="dc-card-compact-title"></ng-content>
        <ng-content select="dc-card-compact-content"></ng-content>
        <ng-content select="dc-card-compact-actions"></ng-content>
    </div>
</mat-card> -->
<div class="compact-card bg-olympia">
    <ng-content select="dc-card-compact-header"></ng-content>
    <div class="padded-content">
        <ng-content select="dc-card-compact-title"></ng-content>
        <ng-content select="dc-card-compact-content"></ng-content>
        <ng-content select="dc-card-compact-actions"></ng-content>
    </div>
</div>
