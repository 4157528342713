import {Component, Input, OnInit} from '@angular/core';
import {TrainingProgramFacade} from "../../facades/training-program.facade";
import {Observable} from "rxjs";
import {SpinnerDirective} from '../../directives/spinner.directive';
import {DcCardActionsComponent} from '../../extended-components/dc-card/ui/dc-card-actions.component';
import {DcCardContentComponent} from '../../extended-components/dc-card/ui/dc-card-content.component';
import {DcCardTitleComponent} from '../../extended-components/dc-card/ui/dc-card-title.component';
import {DcCardHeaderComponent} from '../../extended-components/dc-card/ui/dc-card-header.component';
import {DcCardComponent} from '../../extended-components/dc-card/dc-card.component';
import {NgIf, NgFor, AsyncPipe} from '@angular/common';
import {ButtonDirective} from "../../directives/button.directive";
import {DataFetchState} from "../../models/fetch-state";
import {StrapiTrainingProgram} from "../../models/strapi-training-program";
import {AppState} from "../../common/store";
import {Store} from "@ngrx/store";
import {
  loadRecommendedTrainingPrograms,
  startTrainingProgram
} from "../../common/store/actions/training-programs.actions";
import {Guid} from "../../models/types";

@Component({
  selector: 'dc-recommended-training-programs',
  templateUrl: './recommended-training-programs.component.html',
  styleUrls: ['./recommended-training-programs.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, DcCardComponent, DcCardHeaderComponent, DcCardTitleComponent, DcCardContentComponent, DcCardActionsComponent, SpinnerDirective, AsyncPipe, ButtonDirective]
})
export class RecommendedTrainingProgramsComponent implements OnInit {
  recommendedTrainingPrograms$: Observable<DataFetchState<Array<StrapiTrainingProgram>>>;

  @Input()
  startingTrainingProgramId: Guid | null;

  constructor(
    private trainingProgramFacade: TrainingProgramFacade,
  ) {
  }

  ngOnInit(): void {
    //this.recommendedTrainingPrograms$ = this.trainingProgramFacade.recommendedTrainingPrograms$;
    //this._store.dispatch(loadRecommendedTrainingPrograms());
    // Once we are in this component we can either display a selection of programs or just start the first one
    this.trainingProgramFacade.startRecommendedTrainingProgram();
  }
}
