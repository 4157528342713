import { createAction, props } from '@ngrx/store';
import {Company, CompanyExtendedInfo} from 'src/app/models/company';
import {Employee} from "../../../models/employee";
import {HttpErrorResponse} from "@angular/common/http";

export const LOAD_COMPANY           = "[Company] Load"
export const LOAD_COMPANY_IS_LOADING = "[Company] Load is loading"
export const LOAD_COMPANY_SUCCESS   = "[Company] Load success"
export const LOAD_COMPANY_ERROR     = "[Company] Load error"

export const loadCompany = createAction(LOAD_COMPANY);
export const loadCompanyIsLoading = createAction(LOAD_COMPANY_IS_LOADING);
export const loadCompanySuccess = createAction(LOAD_COMPANY_SUCCESS, props<{ company: Company }>())
export const loadCompanyError = createAction(LOAD_COMPANY_ERROR, props<{ error: HttpErrorResponse }>())

export const LOAD_COMPANY_ADMIN_INFO           = "[Company] Load admin info"
export const LOAD_COMPANY_IS_LOADING_ADMIN_INFO = "[Company] Load admin info is loading"
export const LOAD_COMPANY_SUCCESS_ADMIN_INFO_SUCCESS   = "[Company] Load admin info success"
export const LOAD_COMPANY_ADMIN_INFO_ERROR     = "[Company] Load admin info error"

export const loadCompanyAdminInfo = createAction(LOAD_COMPANY_ADMIN_INFO);
export const loadCompanyIsLoadingAdminInfo = createAction(LOAD_COMPANY_IS_LOADING_ADMIN_INFO);
export const loadCompanyAdminInfoSuccess = createAction(LOAD_COMPANY_SUCCESS_ADMIN_INFO_SUCCESS, props<{ company: CompanyExtendedInfo }>())
export const loadCompanyAdminInfoError = createAction(LOAD_COMPANY_ADMIN_INFO_ERROR, props<{ error: HttpErrorResponse }>())

export const LOAD_COMPANY_USERS           = "[Company] Load users"
export const LOAD_COMPANY_IS_LOADING_USERS = "[Company] Load users is loading"
export const LOAD_COMPANY_SUCCESS_USERS_SUCCESS   = "[Company] Load users success"
export const LOAD_COMPANY_USERS_ERROR     = "[Company] Load users error"

export const loadCompanyUsers = createAction(LOAD_COMPANY_USERS, props<{companyId:string}>());
export const loadCompanyIsLoadingUsers = createAction(LOAD_COMPANY_IS_LOADING_USERS);
export const loadCompanyUsersSuccess = createAction(LOAD_COMPANY_SUCCESS_USERS_SUCCESS, props<{ employees: Array<Employee> }>())
export const loadCompanyUsersError = createAction(LOAD_COMPANY_USERS_ERROR, props<{ error: HttpErrorResponse }>())

