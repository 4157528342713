import {createReducer, on} from '@ngrx/store';
import {Frontpage} from "src/app/models/pages/frontpage";
import {loadFrontpage, loadFrontpageError, loadFrontpageSuccess} from "../actions/frontpage.actions";
import {DataFetchState} from "../../../models/fetch-state";

export interface FrontpageState {
  frontpage: DataFetchState<Frontpage>;
}

export const initialState: FrontpageState = {
  frontpage: {
    data: undefined,
    isLoading: false,
    error: null
  }
};

export const frontpageReducer = createReducer(initialState,
  on(loadFrontpageSuccess, (state, {frontpage}) => ({
    ...state,
    frontpage: {
      data: frontpage,
      isLoading: false,
      error: null
    },
    frontpageLoading: false
  })),
  on(loadFrontpageError, (state, {error}) => ({
    ...state,
    frontpage: {
      data: undefined,
      isLoading: false,
      error: error
    },
  })),
  on(loadFrontpage, (state) => ({
      ...state,
      frontpageLoading: true
    })
  )
);
