import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SystemSettingsFacade} from '../../facades/system-settings.facade';
import {ChallengesFacade} from "../../facades/challenges.facade";
import {Playlist, PlaylistItem} from "../../models/playlist";
import {Observable} from "rxjs";
import {AudioPlayerComponent} from "../../components/audio-player/audio-player.component";
import {Icon, Icons} from "../../models/icon";
import {DataFetchState} from "../../models/fetch-state";
import {SpinnerComponent} from "../../components/spinner/spinner.component";

@Component({
  selector: 'dc-playlist',
  standalone: true,
  imports: [CommonModule, AudioPlayerComponent, NgOptimizedImage, SpinnerComponent],
  templateUrl: './playlist.component.html',
  styleUrl: './playlist.component.scss',
})
export class PlaylistComponent implements OnInit {
  public currentPlaylistItem: PlaylistItem | undefined;
  public currentPlaylist$: Observable<DataFetchState<Playlist>>;

  constructor(private _systemSettingsFacade: SystemSettingsFacade, private _challengesFacade: ChallengesFacade) {
  }

  onPlayAudio(challenge: PlaylistItem) {
    this.currentPlaylistItem = challenge;
    this._systemSettingsFacade.setSystemSettings({
      hideMenu: true,
    });
  }

  ngOnInit(): void {
    this.currentPlaylist$ = this._challengesFacade.currentPlaylist$
  }

  onCloseAudioPlayer() {
    this.currentPlaylistItem = undefined;
    this._systemSettingsFacade.setSystemSettings({
      hideMenu: false,
    });
  }

  getIcon(icon: Icon = "sun") {
    return Icons[icon] ?? Icons["sun"];
  }

}
