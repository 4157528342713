<div class="bg-olympia flex flex-col items-stretch content-stretch">
  @if((profile$ | async)?.data; as profile) {
    <div class="py-4 text-hades flex flex-col items-center">
      <dc-personal-bird></dc-personal-bird>
      {{profile.givenName}} {{profile.familyName}}
    </div>
  }
  @if ((personaTypes$ | async)?.isLoading) {
    <div class="loading__container">
      <dc-progress-indicator></dc-progress-indicator>
    </div>
  }
  @if((currentTrainingProgram$ | async)?.data; as trainingProgram) {
    <div class="text-hades py-3 flex flex-col w-full items-center justify-center gap-2">
      <h1>{{trainingProgram.trainingProgram.title}}</h1>
      <p>Klikk på begrepen for å vite mer</p>
    </div>
  }
  @if(chartOptions | async; as options) {
      <highcharts-chart
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [callbackFunction]="chartCallback"
        [options]="options"
        style="width: 100%; height: auto; display: block">
      </highcharts-chart>
  }
  <div class="py-8 w-full flex justify-center pb-20">
    <button (click)="goToProfile()" dcButton theme="primary">TIL MIN SIDE</button>
  </div>
</div>
