
export type availableAdminSettings = "showWelcomeOnFrontPage" | "adminMode";
export type availableActions = "resetPwaPrompt";

type adminSettings = availableAdminSettings | availableActions;

interface Setting {
	title: string;
	description: string;
}

//Admin settings is meant to toggle debug features
export interface AdminSettingsState extends Record<availableAdminSettings,boolean> {

}
export const AdminSettingsInfo: Record<adminSettings,Setting> = {
	showWelcomeOnFrontPage: {
		title: "Vis velkomstmelding",
		description: "Vis velkomstmelding hver gang man går inn på forsiden"
	},
	adminMode: {
		title: "Aktiver admin-modus",
		description: "Aktiver admin-modus for å få tilgang til admin-funksjonalitet"
	},
    resetPwaPrompt: {
        title: "Tillat PWA installasjonsvarsel",
        description: "Tillat at nettleseren spør om å installere Degogmeg som en PWA"
    }
};
