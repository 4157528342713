import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {AsyncPipe, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {ProfileFacade} from "../../facades/profile.facade";
import {Observable} from "rxjs";
import {ChallengesComponent} from "../challenges/challenges.component";
import {QuestionnairesComponent} from "../questionnaires/questionnaires.component";
import {Profile} from "../../models/profile";
import {GreetingComponent} from "../../components/greeting/greeting.component";
import {MessageBoxComponent} from "../../components/message-box/message-box.component";
import {ArticlesListComponent} from "../../components/articles-list/articles-list.component";
import {FrontpageFacade} from "../../facades/frontpage.facade";
import {Frontpage} from "../../models/pages/frontpage";
import {TrainingSummaryComponent} from "../../components/training-summary/training-summary.component";
import {LogoComponent} from "../../components/logo/logo.component";
import {PersonalBirdComponent} from "../../components/personal-bird/personal-bird.component";
import {TrainingProgramFacade} from "../../facades/training-program.facade";
import {OnboardingComponent} from "../../components/onboarding/onboarding.component";
import {DataFetchState} from "../../models/fetch-state";
import {ActiveTrainingProgramStageProgression} from "../../models/training-program-progression";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {
  RecommendedTrainingProgramsComponent
} from "../../components/recommended-training-programs/recommended-training-programs.component";

@Component({
	selector: 'dc-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	standalone: true,
  imports: [RouterLink, AsyncPipe, MatButtonModule, NgIf, ChallengesComponent, QuestionnairesComponent, GreetingComponent, MessageBoxComponent, ArticlesListComponent, TrainingSummaryComponent, LogoComponent, PersonalBirdComponent, OnboardingComponent, SpinnerComponent, RecommendedTrainingProgramsComponent]
})
export class DashboardComponent implements OnInit {
	profile$: Observable<DataFetchState<Profile>>;
  activeTrainingProgramProgression$: Observable<DataFetchState<ActiveTrainingProgramStageProgression>>;
	isFinishingRegistrationRequired$: Observable<boolean>;
	frontpage: Observable<DataFetchState<Frontpage>>;
  onboardingCompleted: boolean;

	constructor(
		private router: Router,
		private _profileFacade: ProfileFacade,
		private frontpageFacade: FrontpageFacade,
    private _trainingProgramFacade: TrainingProgramFacade) {
		this.isFinishingRegistrationRequired$ = this._profileFacade.isFinishingRegistrationRequired$;
    this.activeTrainingProgramProgression$ = this._trainingProgramFacade.currentTrainingProgramProgression$;
	}

	ngOnInit(): void {
		this.frontpage = this.frontpageFacade.frontpage$;
		this.profile$ = this._profileFacade.profile$;
    const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding');
    if (hasCompletedOnboarding) {
      this.onboardingCompleted = JSON.parse(hasCompletedOnboarding);
    } else {
      this.onboardingCompleted = false;
    }
	}
	gotoProfile() {
		return this.router.navigate(['profile']);
	}

  onCompleteOnboarding(onboardingCompleted: boolean) {
    this.onboardingCompleted = onboardingCompleted;
  }
}
