<ng-container>
  @switch (challenge.content[0].__component) {
    @case ('blocks.audio') {
      <dc-audio-player
        [audioUrl]="challenge.content[0].audio.url"
        (audioClipCompleted)="onChallengeCompleted()"
        (playbackStarted)="onChallengeContentStarted()"
        (playerClosed)="onCloseChallenge()"
        [description]="challenge.description"
        [title]="challenge.title"
        [icon]="challenge.icon"
      ></dc-audio-player>
    }
    @case ('blocks.text-component') {
      <dc-text-challenge
        [challenge]="challenge"
        (challengeAccepted)="onChallengeCompleted()"
        (challengeClosed)="onCloseChallenge()"
      ></dc-text-challenge>
    }
  }
  @if(showFeedbackModal) {
    <dc-feedback
      [title]="'Takk for utfordringen!'"
      [description]="'Hvordan var utfordringen?'"
      (closeFeedback)="onCloseFeedback($event)"
    ></dc-feedback>
  }
  @if(showCancelModal) {
    <dc-simple-modal
      [title]="'Avbryt utfordring'">
      <div>
        <p class="text-hades">Er du sikker på at du vil avbryte utfordringen?</p>
        <button dcButton (click)="_closeChallenge()">Lukk</button>
      </div>
    </dc-simple-modal>
  }
</ng-container>
