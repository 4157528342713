import {ChallengesEffects} from './challenges.effects';
import {CompanyEffects} from './company.effects';
import {ProfileEffects} from './profile.effects';
import {QuestionnairesEffects} from './questionnaires.effects';
import {ArticlesEffects} from './articles.effects';
import {FrontpageEffects} from './frontpage.effects';
import {TrainingProgramEffects} from './training-program.effects';
import {RouterEffects} from './router.effects';
import {AuthEffects} from "./auth.effects";
import {StatisticsEffects} from "./statistics.effects";

export default [
  CompanyEffects,
  ProfileEffects,
  ChallengesEffects,
  QuestionnairesEffects,
  ArticlesEffects,
  FrontpageEffects,
  TrainingProgramEffects,
  RouterEffects,
  AuthEffects,
  StatisticsEffects
];
