<div
  class="rounded-2xl p-5 pb-3 m-2 flex gap-6 justify-start text-olympia bg-otrera"
  [ngClass]="{
    'bg-apollo': challenge.state === ChallengeProgressionState.Completed,
    'bg-otrera': challenge.state === ChallengeProgressionState.NotStarted,
    'bg-hercules': challenge.state === ChallengeProgressionState.InProgress,
  }">
  <!-- Checkmark for completed challenges, number inside circle for others -->

  <div
    class="size-11 min-w-11 bg-achilles rounded-full flex items-center justify-center"
    [ngClass]="{ 'bg-demeter': challenge.state === ChallengeProgressionState.Completed }"
  >
    <img
      *ngIf="challenge.state === ChallengeProgressionState.Completed"
      ngSrc="../../../../assets/icons/white_check.svg"
      alt="Challenge completed icon"
      width="24"
      height="28"
    />
    <p class="text-lg" *ngIf="challenge.state !== ChallengeProgressionState.Completed">
      {{ index + 1}}
    </p>
  </div>

  <!-- Challenge title and time since completion, CTA, or time until available -->
  <div [ngClass]="{ 'text-hades': challenge.state === ChallengeProgressionState.NotAvailable }">
    <h2 class="my-1 cursor-default">{{ challenge.title }}</h2>
    <div *ngIf="challenge.state === (ChallengeProgressionState.NotStarted || ChallengeProgressionState.NotAvailable)">
      <p class="text-sm uppercase underline underline-offset-2">
        GJENNOMFØR NÅ
      </p>
    </div>
  </div>
</div>
