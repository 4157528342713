import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  loadCurrentTrainingProgram,
  loadCurrentTrainingProgramFailure,
  loadCurrentTrainingProgramIsLoading,
  loadCurrentTrainingProgramSuccess,
  loadRecommendedTrainingPrograms,
  loadRecommendedTrainingProgramsFailure,
  loadRecommendedTrainingProgramsIsLoading,
  loadRecommendedTrainingProgramsSuccess,
  startTrainingProgram,
  startTrainingProgramFailure,
  startTrainingProgramIsLoading,
  startTrainingProgramSuccess
} from "../common/store/actions/training-programs.actions";
import {TrainingProgramService} from "../services/training-program/training-program.service";
import {logInSuccess} from '../common/store/actions/auth.actions';
import * as challengesActions from "../common/store/actions/challenges.actions";
import {AppState} from "../common/store";
import {Store} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class TrainingProgramEffects {
  constructor(
    private _store$: Store<AppState>,
    private _actions$: Actions,
    private _trainingProgramService: TrainingProgramService,
  ) {
  }

  tryLoadRecommendedTrainingPrograms$ = createEffect(() => this._actions$.pipe(
    ofType(loadRecommendedTrainingPrograms),
    withLatestFrom(this._store$.select(state => state.trainingPrograms.recommended)),
    filter(([_, {data, isLoading}]) => !data && !isLoading),
    tap(() => console.log("Loading recommended training programs...")),
    switchMap(() => of(loadRecommendedTrainingProgramsIsLoading()))
  ));
  loadRecommendedTrainingPrograms$ = createEffect(() => this._actions$.pipe(
    ofType(loadRecommendedTrainingProgramsIsLoading),
    switchMap(() => this._trainingProgramService.getRelevantTrainingPrograms().pipe(
      map(trainingPrograms => loadRecommendedTrainingProgramsSuccess({trainingPrograms})),
      catchError(error => {
        console.error("[TrainingProgramEffects]: loadRecommendedTrainingPrograms$ error", error);
        return of(loadRecommendedTrainingProgramsFailure({error: error}));
      })
    ))
  ));


  reloadCurrentTrainingProgram$ = createEffect(() => this._actions$.pipe(
    ofType(
      logInSuccess, //Triggered when user logs in
      challengesActions.completeChallengeSuccess
    ),
    switchMap(() => of(loadCurrentTrainingProgram({reload: true})))
  ));
  tryLoadCurrentTrainingProgram$ = createEffect(() => this._actions$.pipe(
    ofType(loadCurrentTrainingProgram),
    withLatestFrom(this._store$.select(state => state.trainingPrograms.currentTrainingProgram)),
    filter(([{reload}, {data, isLoading}]) =>
      reload || (!data && !isLoading)),
    tap(() => console.log("Loading current training program...")),
    switchMap(() => of(loadCurrentTrainingProgramIsLoading())
    )));
  loadCurrentTrainingProgram$ = createEffect(() => this._actions$.pipe(
    ofType(loadCurrentTrainingProgramIsLoading),
    mergeMap(() => this._trainingProgramService.getActiveTrainingProgram().pipe(
      map(program => loadCurrentTrainingProgramSuccess({program})),
      catchError((error: HttpErrorResponse) => {
        console.debug("[TrainingProgramEffects]: loadCurrentTrainingProgram$ error", error)
        return of(loadCurrentTrainingProgramFailure({error: error}));
      })
    ))
  ));

  tryStartTrainingProgram$ = createEffect(() => this._actions$.pipe(
    ofType(startTrainingProgram),
    withLatestFrom(this._store$.select(state => state.trainingPrograms.currentTrainingProgram)),
    filter(([_, {data, isLoading, error}]) => {
      return !data || !data.trainingProgramProgression;
    }),
    mergeMap(([{trainingProgramId}]) => of(startTrainingProgramIsLoading({trainingProgramId}))))
  );
  startTrainingProgram$ = createEffect(() => this._actions$.pipe(
    ofType(startTrainingProgramIsLoading),
    mergeMap(({trainingProgramId}) =>
      this._trainingProgramService.startTrainingProgram(trainingProgramId).pipe(
        switchMap(progression => of(startTrainingProgramSuccess({progression}))),
        catchError(error => {
          console.error(error);
          return of(startTrainingProgramFailure({error:error}));
        }))
    ))
  );
}
