import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as TrainingProgramActions from "../common/store/actions/training-programs.actions";
import { tap } from "rxjs/operators";

@Injectable()
export class RouterEffects {
    constructor(
        private action$: Actions,
        private router: Router
    ) { }

    redirectToFrontPage = createEffect(() => this.action$.pipe(
        ofType(TrainingProgramActions.startTrainingProgramSuccess),
        tap(() => this.router.navigate(['']))
    ), { dispatch: false })
}
