export const BrowserAgentUtils = {
    //iOS does not support PWA installation, so we tell the user to download the app manually
    isIos: () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    },
    //Is PWA already installed?
    isInStandaloneMode: () => {
        return (
            "standalone" in (window as any).navigator &&
            (window as any).navigator.standalone
        );
    }
}
