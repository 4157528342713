import { createAction, props } from '@ngrx/store';
import {
  ActiveTrainingProgramStageProgression,
  TrainingProgramProgression
} from "../../../models/training-program-progression";
import {StrapiTrainingProgram} from "../../../models/strapi-training-program";
import {Guid} from "../../../models/types";
import {HttpErrorResponse} from "@angular/common/http";


const LOAD_RECOMMENDED_TRAINING_PROGRAMS           = "[Training Programs] Load recommended"
const LOAD_RECOMMENDED_TRAINING_PROGRAMS_IS_LOADING = "[Training Programs] Load recommended is loading"
const LOAD_RECOMMENDED_TRAINING_PROGRAMS_SUCCESS   = "[Training Programs] Load recommended success"
const LOAD_RECOMMENDED_TRAINING_PROGRAMS_FAILURE   = "[Training Programs] Load recommended failure"

export const loadRecommendedTrainingPrograms = createAction(LOAD_RECOMMENDED_TRAINING_PROGRAMS);
export const loadRecommendedTrainingProgramsIsLoading = createAction(LOAD_RECOMMENDED_TRAINING_PROGRAMS_IS_LOADING);
export const loadRecommendedTrainingProgramsSuccess = createAction(LOAD_RECOMMENDED_TRAINING_PROGRAMS_SUCCESS, props<{ trainingPrograms: Array<StrapiTrainingProgram> }>() );
export const loadRecommendedTrainingProgramsFailure = createAction(LOAD_RECOMMENDED_TRAINING_PROGRAMS_FAILURE, props<{ error: any }>() );

const START_TRAINING_PROGRAM          = "[Training Program] Accept"
const START_TRAINING_PROGRAM_IS_LOADING = "[Training Program] Accept is loading"
const START_TRAINING_PROGRAM_SUCCESS  = "[Training Program] Accept success"
const START_TRAINING_PROGRAM_FAILURE  = "[Training Program] Accept failure"

export const startTrainingProgram = createAction(START_TRAINING_PROGRAM, props<{trainingProgramId?: Guid}>());
export const startTrainingProgramIsLoading = createAction(START_TRAINING_PROGRAM_IS_LOADING, props<{trainingProgramId?: Guid}>());
export const startTrainingProgramSuccess = createAction(START_TRAINING_PROGRAM_SUCCESS, props<{ progression: any }>() );
export const startTrainingProgramFailure = createAction(START_TRAINING_PROGRAM_FAILURE, props<{ error: HttpErrorResponse }>() );

const LOAD_CURRENT_TRAINING_PROGRAM          = "[Training Program] Load current training program"
const LOAD_CURRENT_TRAINING_PROGRAM_IS_LOADING = "[Training Program] Load current training program is loading"
const LOAD_CURRENT_TRAINING_PROGRAM_SUCCESS  = "[Training Program] Load current training program success"
const LOAD_CURRENT_TRAINING_PROGRAM_FAILURE  = "[Training Program] Load current training program failure"

export const loadCurrentTrainingProgram = createAction(LOAD_CURRENT_TRAINING_PROGRAM,
  props<{ reload?: boolean }>());
export const loadCurrentTrainingProgramIsLoading = createAction(LOAD_CURRENT_TRAINING_PROGRAM_IS_LOADING);
export const loadCurrentTrainingProgramSuccess = createAction(LOAD_CURRENT_TRAINING_PROGRAM_SUCCESS, props<{program: ActiveTrainingProgramStageProgression}>());
export const loadCurrentTrainingProgramFailure = createAction(LOAD_CURRENT_TRAINING_PROGRAM_FAILURE, props<{error: HttpErrorResponse}>());

const COMPLETE_TRAINING_PROGRAM = "[Training Program] Complete"
const COMPLETE_TRAINING_PROGRAM_SUCCESS = "[Training Program] Complete success"

export const completeTrainingProgram = createAction(COMPLETE_TRAINING_PROGRAM);
export const completeTrainingProgramSuccess = createAction(COMPLETE_TRAINING_PROGRAM_SUCCESS, props<{ progression: TrainingProgramProgression }>());

const CANCEL_TRAINING_PROGRAM = "[Training Program] Cancel"
const CANCEL_TRAINING_PROGRAM_SUCCESS = "[Training Program] Cancel success"

export const cancelTrainingProgram = createAction(CANCEL_TRAINING_PROGRAM);
export const cancelTrainingProgramSuccess = createAction(CANCEL_TRAINING_PROGRAM_SUCCESS, props<{ progression: TrainingProgramProgression }>());
