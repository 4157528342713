import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppInjector } from './app/services/injector/injector.service';
import { AppComponent } from './app/app.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app/common/store';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app/app-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NotLoggedInGuard } from './app/guards/not-logged-in/not-logged-in.guard';
import { interceptorProviders } from './app/interceptors/interceptors';
import effects from './app/effects';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      OAuthModule.forRoot(),
      AppRoutingModule,
      StoreModule.forRoot(appReducers),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        autoPause: true,
      }),
      EffectsModule.forRoot(effects),
      FontAwesomeModule,
      MatSliderModule,
      FormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatIconModule,
      HighchartsChartModule,
      MatRadioModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatSliderModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatCardModule,
      MatDialogModule,
      MatToolbarModule,
      MatMenuModule,
      MatSlideToggleModule,
      MatProgressBarModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled:
          environment.envName !== 'Local' &&
          environment.envName !== 'Development',
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      CommonModule,
      NgOptimizedImage,
      MatRippleModule
    ),
    interceptorProviders,
    NotLoggedInGuard,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
  .then((moduleRef) => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch((err) => console.error(err));
