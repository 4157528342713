import {createReducer, on} from '@ngrx/store';
import {AdminSettingsRepository} from "../../../utils/AdminSettingsRepository";
import {adminSettingsChanged} from "../actions/admin-settings.actions";
import {AdminSettingsState} from "../../../models/admin-settings";

const ResolveInitialSettingsState = ():AdminSettingsState => {
    return AdminSettingsRepository.GetAdminSettings();
}
export const adminSettingsReducer = createReducer<AdminSettingsState>(ResolveInitialSettingsState(),
    on(adminSettingsChanged, (state,{adminSettings}) => {
        AdminSettingsRepository.SetAdminSettings({...state,...adminSettings});
        return {...state,...adminSettings}
    }),
);
