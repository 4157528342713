<ng-container>
    <div class="pwa-prompt-container">
        <div class="pwa-prompt fadeInUp" *ngIf="(settings$ | async)?.pwaSettings | visiblesetting">
            <div>
                <h3>Last ned appen - få en bedre opplevelse</h3>
                <p>
                    Du kan installere denne appen på hjemskjermen din for en bedre opplevelse.
                    Den vil fungere offline og du vil få tilgang til den raskere.
                    Da vil vi også lettere kunne gi deg push-varslinger.
                </p>
                <div class="button-row">
                    <button dcButton   (click)="declinePwaInstall()">Ikke nå</button>
                    <button dcButton  (click)="installPwa()">Installer app</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>


