import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/common/store';
import {loadCompany} from 'src/app/common/store/actions/company.actions';
import {Company} from '../models/company';
import {Article, ArticlePreview} from "../models/article";
import {ArticleList} from "../common/store/reducers/articles.reducers";
import {loadFrontpage} from "../common/store/actions/frontpage.actions";
import {loadArticle} from "../common/store/actions/articles.actions";
import {map, take} from "rxjs/operators";
import {DataFetchState} from "../models/fetch-state";

@Injectable({
    providedIn: 'root'
})

export class ArticlesFacade {
    articles$: Observable<DataFetchState<ArticleList>>;

    constructor(private _store: Store<AppState>) {
        this.articles$ = this._store.select(x => x.articles.articles);
    }

    getArticle(articleId: string) {
        this.articles$.pipe(take(1)).subscribe(articleList => {
            const existingArticle = Object.entries(articleList)
                .filter(([id, article]) => {
                    return id == articleId
                });
            if (!existingArticle?.length) {
                this._store.dispatch(loadArticle({articleId: articleId}));
            }
        })
    }

}
