import {
  StrapiAudio,
  StrapiContentInformation,
  StrapiData,
  StrapiVideo,
} from './strapi/StrapiContent';
import {StrapiImage} from './strapi/StrapiImage';
import {Guid} from './types';
import {DialeadAudio} from "./content";
import {Icon} from "./icon";

export interface PaginationInfo {
  page: number;
  pageSize: number;
  pageCount: number;
}

//#region "Strapi models"
export interface StrapiChallengePreview extends StrapiContentInformation {
  icon?: string; // TODO CHALLENGE figure out if the icon is string type or something else
  title: string;
  description: string;
  challengeResponse: ChallengeResponse; // TODO must probably be unpacked
}

/**
 * Enum corresponding to how STRAPI represents different content blocks
 **/
export enum ChallengeContentType {
  Audio = 'blocks.audio',
  Image = 'blocks.image',
  Text = 'blocks.text-component',
  Video = 'blocks.video',
}

export type StrapiChallengeContent = {
  id: Guid;
  text: string;
}

export type StrapiAudioChallengeContent = StrapiChallengeContent & {
  __component: 'blocks.audio';
  audio: DialeadAudio;
}
export type StrapiTextChallengeContent = StrapiChallengeContent & {
  __component: 'blocks.text-component';
}
//#endregion "Strapi models"

//#region "Frontend Challenge Models"

/** The different types of challenges available */
export type ChallengeType =
  | 'yes-no'
  | 'range';

/** How the user is expected to respond to completing a challenge */
export type ChallengeResponseType =
  | 'yes-no'
  | 'short-text'
  | 'long-text'
  | 'range'
  | 'no-response';

/**
 * Represents an request for user response/feedback to some challenge.
 * E.g. "Have you completed it" or "Rate the challenge"
 */
export type ChallengeResponse = {
  /** The type of challenge response (rating, yes/no, ..) */
  type: ChallengeResponseType;
  /** The title of the requested response, e.g. "Rate the challenge" */
  title: string;
  /** Optional description of the requested response for when more details are necessary */
  description?: string;

  // TODO discuss: should we also add a "required" field?
  //   - E.g. if the user must answer/give feedback
};

/**
 *
 */
export type Challenge = {
  /**
   * The id of the challenge.
   * NOTE: ID's are numbered 1..n based on strapi-order, and can therefore be used to sort
   **/
  id: string;
  /** (optional) Icon representing the challenge */
  icon?: Icon;
  /** Title of the challenge */
  title: string;
  /** Description of the challenge */
  description: string;
  /** How the user should give feedback when completing a challenge */
  response?: ChallengeResponse;
  /** The type of challenge, which has implications for the content */
  type: ChallengeResponseType;
  tags: string[];
  state: ChallengeProgressionState; //A bit redundant to have it here, but it accounts for the case where the progression is null because we havent started the challenge yet
  progression?: ChallengeProgression;
  content: StrapiAudioChallengeContent[] | StrapiTextChallengeContent[]
};

/**
 * The different types of challenges available
 * TODO verify if the introductory questions are represented differently
 */
export type ChallengeDeprecated = Challenge & {
  content: StrapiAudioChallengeContent | StrapiTextChallengeContent
};

//#endregion "Frontend Challenge Models"

///////////
// #region "Challenge progression"
//
// This region may seem deprecated but isn't, it is just overly complicated and outdated
// Part of the issue is that challenges previously could be a sequence of challenges,
//  .. which meant that there was a need to keep track of the current progress within a challenge
//
// Correcting the model would be too time consuming,
//  .. since it entails changing both frontend and backend
// => Keep the code related to these models as-is unless necessary
///////////

/** Enum representing the state of progression a challenge can be in */
export enum ChallengeProgressionState {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  NotAvailable = 3
}

/** Type for keeping track of current challenge progressions */
export interface ChallengeProgression {
  id: Guid;
  progress: number;
  state: ChallengeProgressionState;
  started: Date;
  finished: Date | null;
  stringValue: string;
  boolValue: boolean;
  numberValue: number;
  durationTimeLeft: string;
}

/** The current state of challenge completion */
export interface ChallengeCompleteResult {
  challengeId: string;
  state?: ChallengeProgressionState;
  stringValue?: string;
  boolValue?: boolean;
  numberValue?: number;
}

//#endregion "Challenge progression"
