import {createReducer, on} from '@ngrx/store';
import {UserClaims} from 'src/app/models/userClaims';
import {authError, authTokenReceived, logIn, logInFailure, logInSuccess, logOut} from '../actions/auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  userClaims: UserClaims | null;
  errorMessage: string | null;
  error: any | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  userClaims: null,
  errorMessage: null,
  error: null
};

export const authReducer = createReducer<AuthState>(initialState,
  on(logIn, (state) => ({
    ...state,
    isLoading: true
  })),
  on(authTokenReceived, (state) => ({
    ...state
  })),
  on(logInSuccess, (state, {userClaims}) => ({
    ...state,
    isLoading: false,
    isAuthenticated: true,
    userClaims
  })),
  on(logInFailure, (state, {error}) => ({
    ...state,
    isLoading: false,
    isAuthenticated: false,
    error
  })),
  on(authError, (state, {error}) => ({
    ...state,
    error
  })),
  on(logOut, _ => initialState),
);
