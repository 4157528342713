import { Pipe, PipeTransform } from '@angular/core';
import {SettingsField} from "../models/settings";

@Pipe({
    name: "visiblesetting",
    standalone: true
})

export class SettingsVisibilityPipe implements PipeTransform {
    transform(settingsField: SettingsField | undefined): boolean {
        if (!settingsField) {
            return false;
        }
        const promptResetInterval = 1000 * 60 * 60 * 24 * 3; //1 week
        return settingsField.show || new Date(settingsField.timeOfLastPrompt).getTime() + promptResetInterval < new Date().getTime();
    }
}
