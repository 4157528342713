import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, catchError, switchMap, mergeMap, withLatestFrom, filter} from 'rxjs/operators';
import {of} from 'rxjs';
import * as actions from "../common/store/actions/questionnaires.actions"
import {QuestionnairesService} from '../services/questionnaires/questionnaires.service';
import {Router} from '@angular/router';
import {logInSuccess} from '../common/store/actions/auth.actions';
import * as challengesActions from "../common/store/actions/challenges.actions";
import * as trainingProgramActions from "../common/store/actions/training-programs.actions";
import {
  loadQuestionnaires,
  loadQuestionnairesFailure, loadQuestionnairesIsLoading,
  submitQuestionnaireSuccess
} from "../common/store/actions/questionnaires.actions";
import {AppState} from "../common/store";
import {Store} from "@ngrx/store";

@Injectable()
export class QuestionnairesEffects {

  constructor(
    private _store$: Store<AppState>,
    private _actions$: Actions,
    private _router: Router,
    private _questionnairesService: QuestionnairesService
  ) {
  }

  reloadQuestionnaires$ = createEffect(() => this._actions$.pipe(
    ofType(
      challengesActions.completeChallengeSuccess,
      trainingProgramActions.startTrainingProgramSuccess,
      submitQuestionnaireSuccess
    ),
    map(() => actions.loadQuestionnaires({reload: true})),
  ));

  tryLoadQuestionnaires$ = createEffect(() => this._actions$.pipe(
    ofType(loadQuestionnaires),
    withLatestFrom(this._store$.select(state => state.questionnaires.questionnaires)),
    filter(([{reload}, {data, isLoading}]) => {
      console.log("QUESTIONNAIRE_LOADING: ", data, isLoading)
      return reload || (!data && !isLoading);
    }),
    map(() => actions.loadQuestionnairesIsLoading()),
  ))

  loadQuestionnaires$ = createEffect(() => this._actions$.pipe(
    ofType(loadQuestionnairesIsLoading),
    mergeMap(() => {
      //if no questionnaires: load it
      return this._questionnairesService.getQuestionnaires().pipe(
        map(questionnaires => {
          console.log("QUESTIONNAIRE_COMPLETED: ", questionnaires);
          return actions.loadQuestionnairesSuccess({questionnaires});
        }),
        catchError(error => {
          return of(loadQuestionnairesFailure({error:error}));
        })
      )
    })
  ));

  submitQuestionnaire$ = createEffect(() => this._actions$.pipe(
    ofType(actions.submitQuestionnaire),
    switchMap(({questionnaireId, answers}) => {
      return this._questionnairesService.submitQuestionnaire(questionnaireId, answers).pipe(
        map(() => actions.submitQuestionnaireSuccess()),
        catchError(error => {
          console.error(error);
          return of(actions.dummyAction());
        })
      )
    })
  ));

  submitQuestionnaireSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(actions.submitQuestionnaireSuccess),
    map(() => this._router.navigate(['/']))
  ), {dispatch: false});
}
