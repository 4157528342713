<dc-full-screen-overlay class="challenge-overlay" (closeWindow)="onCloseChallenge()">
  <img
    [ngSrc]="getIcon(challenge.icon)"
    alt="audio icon"
    width="143"
    height="143"
    class="mb-10"
  />
  <h3 class="text-apollo mb-2">{{ challenge.title }}</h3>
  <p class="px-14 text-center font-sans font-normal">{{
      challenge.content.length > 0 ? challenge.content[0].text : ""
    }}</p>

  <button dcButton theme="primary" (click)="acceptChallenge()" class="mb-1 mt-auto">
    Jeg tar utfordringen
  </button>
  <button dcButton theme="secondary" (click)="postponeChallenge()">
    Utsett
  </button>
</dc-full-screen-overlay>
