import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { ProfileFacade } from 'src/app/facades/profile.facade';
import { Observable } from 'rxjs';
import { Profile } from 'src/app/models/profile';
import { LogoComponent } from '../logo/logo.component';
import {
  HomeIconComponent,
  ChallengesIconComponent,
  PulseIconComponent,
  UserIconComponent,
  ArticleIconComponent
} from '../icon/icon/icon.component';
import { NgIf, AsyncPipe } from '@angular/common';
import {DataFetchState} from "../../models/fetch-state";
@Component({
    selector: 'dc-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    standalone: true,
  imports: [NgIf, RouterLink, RouterLinkActive, HomeIconComponent, ChallengesIconComponent, PulseIconComponent, UserIconComponent, LogoComponent, AsyncPipe, ArticleIconComponent]
})
export class MainMenuComponent{
    profile$: Observable<DataFetchState<Profile>>;

    constructor(public router: Router, profileFacade: ProfileFacade) {
        this.profile$ = profileFacade.profile$;
    }

	isActiveRoute(routeName:string, exact:boolean = false):boolean {
		return this.router.isActive(routeName,exact);
	}

}
