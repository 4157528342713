import { Pipe, PipeTransform } from '@angular/core';

type SortType = 'asc' | 'desc' | 'asc-null';

const priorities = {
    asc: { lower: -1, higher: 1 },
    "asc-null": { lower: -1, higher: 1 },
    desc: { lower: 1, higher: -1 },
}

@Pipe({
    name: "sort",
    standalone: true
})

export class SortPipe implements PipeTransform {
    transform(array: any, rules: { field: string, type?: SortType }[]): any[] {
        if (!Array.isArray(array)) {
            return array;
        }

        var sorted= [...array].sort((a: any, b: any) => {
            let result: number = 0;
            rules.forEach(rule => {
                if(result !== 0) return;

                let valueA = a;
                let valueB = b;
                rule.field.split('.').forEach(field => {
                    valueA = valueA[field];
                    valueB = valueB[field];
                })
                
                const priority = rule.type ? priorities[rule.type] : priorities.asc;
                if (valueA < valueB) result = priority.lower;
                else if (valueA > valueB) result = priority.higher;
                else if (rule.type?.includes('null')) {
                    if(valueA === null && valueB !== null) result = priority.lower;
                    else if (valueA !== null && valueB === null) result = priority.higher;
                }
            })
            
            return result;
        });

        return sorted;
    }
}