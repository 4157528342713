import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, catchError, mergeMap, withLatestFrom, switchMap, tap, filter} from 'rxjs/operators';
import {of} from 'rxjs';
import * as profileActions from '../common/store/actions/profile.actions';
import {ProfileService} from 'src/app/services/profile/profile.service';
import {logInSuccess} from '../common/store/actions/auth.actions';
import {AppState} from '../common/store';
import {Store} from '@ngrx/store';
import {Profile} from '../models/profile';
import {loadProfileIsLoading} from "../common/store/actions/profile.actions";

@Injectable()
export class ProfileEffects {

  constructor(
    private _store$: Store<AppState>,
    private _actions$: Actions,
    private _profileService: ProfileService
  ) {
  }

  // Before any action, we have another action with the prefix try and the same name.
  // The try signifies we check for all required prerequisites before the action.
  // For instance for load profile we check that we're not already in a loading state for loading profile.
  tryLoadProfile$ = createEffect(() => this._actions$.pipe(
      ofType(logInSuccess),
      withLatestFrom(this._store$.select(state => state.profile.profile)),
      filter(([_, {data, isLoading}]) => !data && !isLoading),
      mergeMap(() => of(loadProfileIsLoading()))
    )
  );

  loadProfile$ = createEffect(() => this._actions$.pipe(
    ofType(loadProfileIsLoading),
    mergeMap(() => this._profileService.getProfile().pipe(
      map(profile => {
        return profileActions.loadProfileSuccess({profile});
      }),
      catchError(error => {
        const action = this._profileService.errors.isProfileNotFound(error) ? profileActions.profileNotRegistered()
          : profileActions.loadProfileFailure({error:error})
        return of(action);
      }))
    ))
  );

  createProfile$ = createEffect(() => this._actions$.pipe(
    ofType(profileActions.createProfile),
    withLatestFrom(this._store$.pipe(map(store => store.auth.userClaims?.email))),
    mergeMap(([{profile}, email]) => {
      return this._profileService.createProfile({...profile, email: email!}).pipe(
        map(profile => {
          return profileActions.createProfileSuccess({profile})
        }),
        catchError(error => of(profileActions.createProfileFailure({error:error})))
      )
    })
  ));

  updateProfile$ = createEffect(() => this._actions$.pipe(
    ofType(profileActions.updateProfile),
    withLatestFrom(this._store$.pipe(map(store => store.profile.profile.data))),
    filter(([_, profile]) => !profile),
    mergeMap(([{changes}, profile]) => {
      const updatedProfile: Profile = {...profile!, ...changes};
      return this._profileService.updateProfile(updatedProfile).pipe(
        map(() => profileActions.updateProfileSuccess({profile: updatedProfile})),
        catchError(error => of(profileActions.updateProfileFailure({error:error})))
      )
    })
  ));
}
