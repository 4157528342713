import { Component } from "@angular/core";

@Component({
    selector: 'dc-card-header',
    template: '<ng-content></ng-content>',
    styles: [`
        @import "variables";

        :host {
            grid-area: header;
            color: $color-hades;
            margin-bottom: 10px;
        }
    `],
    standalone: true
})
export class DcCardHeaderComponent { }
