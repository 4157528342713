<ng-container *ngIf="(companyInformation$ | async)?.isLoading; else content">
    <dc-progress-indicator></dc-progress-indicator>
</ng-container>
<ng-template #content>
    <ng-container *ngIf="(companyInformation$ | async)?.data as companyInformation; else noAccess">
        <div class="company-info">
            <div class="basic">
                <h3>{{companyInformation.name}}</h3>
                <p>&#64;{{companyInformation.emailDomains[0].domainName}}</p>
            </div>
            <div class="subscription-state">
                <div class="state">{{companyInformation.hasActiveSubscription ? 'Aktiv' : 'Inaktiv'}}</div>
            </div>
        </div>
        <dc-card-compact>
            <dc-card-compact-header><h3>Avdelinger</h3></dc-card-compact-header>
            <dc-card-compact-content>
                <ul>
                    <li *ngFor="let dep of companyInformation.departments">
                        {{dep.name}}
                    </li>
                </ul>
            </dc-card-compact-content>
        </dc-card-compact>
    </ng-container>
</ng-template>

<ng-template #noAccess>
    <div>Brukeren din har dessverre ikke tilgang til å se denne siden.</div>
</ng-template>
