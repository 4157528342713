<ng-container
	[@listAnimation]="articles.data.length"
	*ngIf="articles"
	class="articles">
	<dc-card-compact
		*ngFor="let art of articles.data; let i = index">
		<dc-card-compact-header>Artikkel</dc-card-compact-header>
		<dc-card-compact-title>{{art.attributes.title}}</dc-card-compact-title>
		<dc-card-compact-content>{{art.attributes.description}}</dc-card-compact-content>
		<dc-card-compact-actions>
			<button dcButton (click)="articleClicked(art.id)">
				Fortell mer
			</button>
		</dc-card-compact-actions>
	</dc-card-compact>
</ng-container>
