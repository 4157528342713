import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Question, QuestionAnswer, Questionnaire, QuestionnaireResult } from 'src/app/models/questionnaire';
import {StrapiContentInformation, StrapiDataArray, StrapiPlainItem} from 'src/app/models/strapi/StrapiContent';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { mapFromStrapiQuestionnaire, StrapiQuestionnaire } from './dataMappers';


@Injectable({
  providedIn: 'root'
})

export class QuestionnairesService {
    private endpoint = `${environment.coachApiUrl}`;

    constructor(private http: HttpService) {}

    getQuestionnaires(): Observable<Questionnaire[]> {
        return this.http.get<Array<StrapiPlainItem<StrapiQuestionnaire>>>(
            `${this.endpoint}/profiles/me/questionnaires/relevant?withQuestions=true`,
            {withCredentials: true}
        ).pipe(map(mapFromStrapiQuestionnaire))
    }

    submitQuestionnaire(questionnaireId: string, answers: QuestionAnswer[]) {
        return this.http.post(
            `${this.endpoint}/profiles/me/questionnaires/${questionnaireId}/result`,
            {answers},
            { withCredentials: true }
        )
    }
}
