import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'dc-icon-home',
    templateUrl: './../../../../assets/icons/home.svg',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HomeIconComponent {}

@Component({
    selector: 'dc-icon-pulse',
    templateUrl: './../../../../assets/icons/pulse.svg',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class PulseIconComponent {}

@Component({
  selector: 'dc-icon-article',
  templateUrl: './../../../../assets/icons/article.svg',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ArticleIconComponent {}

@Component({
    selector: 'dc-icon-user',
    templateUrl: './../../../../assets/icons/user.svg',
    styleUrls: ['./icon.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class UserIconComponent {}

@Component({
    selector: 'dc-icon-challenges',
    templateUrl: './../../../../assets/icons/challenges.svg',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ChallengesIconComponent {}
