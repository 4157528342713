import {authReducer, AuthState} from "./reducers/auth.reducers";
import {challengesReducer, ChallengesState} from "./reducers/challenges.reducers";
import {companyReducer, CompanyState} from "./reducers/company.reducers";
import {questionnairesReducer, QuestionnairesState} from "./reducers/questionnaires.reducers";
import {articlesReducer, ArticlesState} from "./reducers/articles.reducers";
import {frontpageReducer, FrontpageState} from "./reducers/frontpage.reducers";
import {trainingProgramsReducer, TrainingProgramsState} from "./reducers/training-programs.reducers";
import {statisticsReducer, StatisticsState} from "./reducers/statistics.reducers";
import {SettingsState} from "../../models/settings";
import {settingsReducer} from "./reducers/settings.reducers";
import {AdminSettingsState} from "../../models/admin-settings";
import {adminSettingsReducer} from "./reducers/admin-settings.reducers";
import {SystemSettingsState} from "../../models/system-settings";
import {systemSettingsReducer} from "./reducers/system-settings.reducers";
import {profileReducer, ProfileState} from "./reducers/profile.reducers";

export interface AppState {
    auth: AuthState,
    company: CompanyState,
    challenges: ChallengesState,
    questionnaires: QuestionnairesState,
    articles: ArticlesState,
    frontpage: FrontpageState,
    trainingPrograms: TrainingProgramsState,
    statistics: StatisticsState,
    settings: SettingsState,
    adminSettings: AdminSettingsState,
    systemSettings: SystemSettingsState,
    profile: ProfileState
}

export const appReducers = {
    auth: authReducer,
    company: companyReducer,
    challenges: challengesReducer,
    questionnaires: questionnairesReducer,
    articles: articlesReducer,
    frontpage: frontpageReducer,
    trainingPrograms: trainingProgramsReducer,
    statistics: statisticsReducer,
    settings: settingsReducer,
    adminSettings: adminSettingsReducer,
    systemSettings: systemSettingsReducer,
    profile: profileReducer
}
