<div class="pb-20 mb-20" *ngIf="adminSettings$ | async as adminSettings">
    <h1>Innstillinger</h1>
    <p>Her kan man endre på innstillingene for å feilsøke eller vise ny funksjonalitet. Denne siden er forbeholdt administratorer i Degogmeg</p>
<!--
    <div class="site-setting">
        <p>{{SettingsInfo.showWelcomeOnFrontPage.title}}</p>
        <p>{{SettingsInfo.showWelcomeOnFrontPage.description}}</p>
        <button dcButton  [ngClass]="settings.showWelcomeOnFrontPage ? 'active-setting' : ''" (click)="toggleProperty(settings,'showWelcomeOnFrontPage')">{{settings.showWelcomeOnFrontPage ? 'På' : 'Av'}}</button>
    </div>
-->
    <div class="site-setting">
		<h4>Admin mode</h4>
        <p>{{Info.adminMode.title}}</p>
        <p>{{Info.adminMode.description}}</p>
        <button dcButton  [ngClass]="adminSettings.adminMode ? 'active-setting' : ''" (click)="toggleProperty(adminSettings,'adminMode')">{{adminSettings.adminMode ? 'På' : 'Av'}}</button>
    </div>
	<div class="site-setting">
		<h4>Push messages</h4>
		<p class="push-status-item">Er push støttet: {{isPushSupported() ? "Ja" : "Nei"}}</p>
		<p class="push-status-item">Er push slått på for denne enheten: {{isPushEnabled() ? "Ja" : "Nei"}}</p>
		<button dcButton  class="settings-button" [disabled]="!isPushSupported()" (click)="sendDebugPushMessage()">Send en test-pushmelding</button>
		<button dcButton  class="settings-button" [disabled]="!isPushSupported()" (click)="sendClientSidePushNotification()">Send en dummy test-pushmelding</button>
		<button dcButton  class="settings-button" [disabled]="!isPushSupported()" (click)="togglePush()">{{isPushEnabled() ? "Slå av push-meldinger" : "Slå på push-meldinger"}}</button>
		<button dcButton  class="settings-button" [disabled]="!isPushSupported()" (click)="turnOnPush()">{{"Slå på push-meldinger"}}</button>
	</div>
	<div class="site-setting">
		<h4>Progressive Web App settings</h4>
		<button dcButton  class="settings-button" (click)="resetPwaPromptSetting()">Tilbakestill PWA settings</button>
	</div>
    <div class="site-setting">
        <button (click)="showBirdSelector()" dcButton>Velg fugl</button>
    </div>
    <div class="site-setting">
        <button (click)="throwAuthError()" dcButton>ThrowAuthError</button>
    </div>
    <dc-personal-bird-selector *ngIf="showBirds"></dc-personal-bird-selector>
</div>
