import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/common/store';
import { ProfileUpdate, BasicProfile, Profile, } from 'src/app/models/profile';
import { logInSuccess, logOut } from '../common/store/actions/auth.actions';
import { createProfile, updateProfile } from '../common/store/actions/profile.actions';
import { UserClaims } from '../models/userClaims';
import { AuthService } from '../services/auth/auth.service';
import {DataFetchState} from "../models/fetch-state";

@Injectable({
    providedIn: 'root'
})

export class ProfileFacade {
    error$: Observable<any>;
    profile$: Observable<DataFetchState<Profile>>;
    isFinishingRegistrationRequired$: Observable<boolean>

    constructor(private _store: Store<AppState>, private authService: AuthService) {
        this.profile$ = this._store.select(x => x.profile.profile);
        this.error$ = this._store.select(x => x.profile.profile.error);
        this.isFinishingRegistrationRequired$ = this._store.select(x => x.profile.isRegistrationNotFinished);
    }

    createProfile(profile: BasicProfile) {
        this._store.dispatch(createProfile({profile}));
    }

    updateProfileValues(changes: ProfileUpdate){
        this._store.dispatch(updateProfile({changes}));
    }
}
