import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Frontpage } from '../../models/pages/frontpage';
import { Observable } from 'rxjs';
import { Profile } from '../../models/profile';
import { ProfileFacade } from 'src/app/facades/profile.facade';
import translations from 'src/assets/translations';
import { NgIf, AsyncPipe } from '@angular/common';
import {DataFetchState} from "../../models/fetch-state";

@Component({
  selector: 'dc-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class GreetingComponent implements OnInit {
  translations = translations.header;
  profile$: Observable<DataFetchState<Profile>>;
  isLoggedIn: boolean;
  error$: Observable<any>;
  isFinishingRegistrationRequired$: Observable<boolean>;
  isFirstLogin: boolean;
  frontpage: Observable<Frontpage | null>;

  constructor(
    profileFacade: ProfileFacade,
    private auth: AuthService,
    private _profileFacade: ProfileFacade
  ) {
    this.profile$ = profileFacade.profile$;
    this.error$ = this._profileFacade.error$;
    this.isFinishingRegistrationRequired$ =
      this._profileFacade.isFinishingRegistrationRequired$;
  }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.isTokenValid();
  }
}
