<ng-template #loading> ...laster oppmeldingsskjema </ng-template>

<dc-container>
  <div class="header">
    <img class="logo" src="assets/logos/Fugl01_Gul.svg" alt="Degogmeg logo" />
    <h2 class="title"><b>Gro</b></h2>
  </div>
  <form [formGroup]="signupForm" class="signupForm">
    <mat-card *ngIf="(company$ | async)?.data as company; else loading" class="content">
      <mat-card-content>
        <dc-form-input
          [label]="translations.name"
          [formGroup]="signupForm"
          [controlName]="'firstName'"
          [getError]="getFirstNameError"
        ></dc-form-input>
        <dc-form-input
          [label]="translations.lastName"
          [formGroup]="signupForm"
          [controlName]="'lastName'"
          [getError]="getLastNameError"
        ></dc-form-input>
        <!--
                <dc-form-input [label]="translations.phone" [formGroup]="signupForm" [controlName]="'phone'" [getError]="getPhoneError"></dc-form-input>
-->
        <dc-signup-departments-selection
          [company]="company"
          [form]="signupForm"
        ></dc-signup-departments-selection>
        <!--
                <dc-signup-birth-date-picker [form]="signupForm"></app-signup-birth-date-picker>
-->
      </mat-card-content>
      <mat-card-footer>
        <button dcButton class="logout-button" (click)="logOut()">
          {{ translations.logout }}
        </button>
        <button
          dcButton
          class="signup-button"
          [disabled]="!isFormValid()"
          (click)="signUp()"
        >
          {{ translations.createUser }}
        </button>
      </mat-card-footer>
    </mat-card>
  </form>
</dc-container>
