type availableBirds = "default" | "red-bird-1" | "blue-bird-1" | "yellow-bird-1" | "parrot-bird" | "red-bird-2" | "blue-bird-2";

export const birdIcons: Record<availableBirds,string> = {
    "default": "assets/logos/Symbol.svg",
    "red-bird-1": "assets/logos/Fugl01_Rosa.svg",
    "blue-bird-1": "assets/logos/Fugl03_Blå.svg",
    "yellow-bird-1": "assets/logos/Fugl06_Gul.svg",
    "parrot-bird": "assets/logos/Fugl04_Gul.svg",
    "red-bird-2": "assets/logos/Fugl05_Rosa.svg",
    "blue-bird-2": "assets/logos/Fugl07_Blå.svg"
};
