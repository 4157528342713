import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ButtonDirective } from '../../directives/button.directive';
import { TextBoxCardComponent } from '../text-box-card/text-box-card.component';
import { SimpleCardComponent } from '../simple-card/simple-card.component';
import { LogoComponent } from '../logo/logo.component';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AuthFacade } from '../../facades/auth.facade';

@Component({
  selector: 'dc-visitor-greeting',
  templateUrl: '../visitor-greeting/visitor-greeting.component.html',
  styleUrls: ['./visitor-greeting.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    LogoComponent,
    NgIf,
    RouterLink,
    ButtonDirective,
    TextBoxCardComponent,
    SimpleCardComponent,
  ],
})
export class VisitorGreetingComponent {
  constructor(private _authFacade: AuthFacade) {}

  logIn() {
    this._authFacade.logIn();
  }
}
